import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/cc2.png";
import img2 from "../Assets/img/Loan/cc1.png";
import { IciciCC } from "../Components/ccDetailsData";
import CCDetails from "../Components/ccDetails";

function IDFCCC() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Overview", "Types", "Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "ICICI Bank Credit Card",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...IciciCC,
    img1: img1,
    img2: img2,
    desc: "ICICI Bank offers a diverse range of credit cards to suit various lifestyles and preferences. From travel enthusiasts to avid shoppers, there's a card for everyone. Their cards often come with attractive rewards programs, including cashback, reward points, and discounts on dining, shopping, and travel expenses. Many ICICI credit cards also offer complimentary airport lounge access, fuel surcharge waivers, and insurance benefits, enhancing the overall value proposition. With robust security features and convenient digital banking services, ICICI credit cards provide a seamless and rewarding experience for cardholders, making them a popular choice among consumers seeking flexibility and value.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <CCDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default IDFCCC;
