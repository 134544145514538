import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/bike1.jpg";
import img2 from "../Assets/img/Loan/bike2.jpg";
import InsuranceDetails from "../Components/insuranceDetails";
import { bikeInsuranceData } from "../Components/insuranceDetailsData";

function BikeInsurance() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Overview", "Types", "Features", "Eligibility"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Bike Insurance",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...bikeInsuranceData,
    img1: img1,
    img2: img2,
    desc: "Bike insurance provides financial protection in case of accidents, theft, or damage to your motorcycle. It covers expenses related to repairs or replacement, medical bills for injuries, and liabilities towards third parties. By having bike insurance, you comply with legal requirements and safeguard yourself from unexpected financial burdens. It offers peace of mind on the road, ensuring you can ride with confidence knowing you're covered in case of unforeseen events.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <InsuranceDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default BikeInsurance;
