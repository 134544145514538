import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";
import img1 from "../Assets/img/Loan/business1.png";
import img2 from "../Assets/img/Loan/business2.png";
import { businessLoanData } from "../Components/loanDetailsData";

function BusinessLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = [
    "Overview",
    "Features",
    "Eligibility & Documents",
    "Interest rates & Charges",
    "EMI Calculator",
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Business Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...businessLoanData,
    img1: img1,
    img2: img2,
    desc: "A business loan is a financial product designed to provide funding to businesses for various purposes, such as starting a new venture, expanding operations, purchasing equipment, or covering operating expenses. These loans can be obtained from banks, credit unions, online lenders, or government agencies. The terms and conditions of business loans vary depending on factors like the lender, the borrower's creditworthiness, and the purpose of the loan. Typically, business loans require collateral or a personal guarantee from the business owner, and they may have fixed or variable interest rates. Repayment terms can range from a few months to several years, with regular payments made on a predetermined schedule.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default BusinessLoan;
