import p1 from "../Assets/img/Partners/incred.png";
import p2 from "../Assets/img/Partners/axis.png";
import p3 from "../Assets/img/Partners/birla.png";
import p4 from "../Assets/img/Partners/bob.png";
// import p5 from "../Assets/img/Partners/deals.png"
import p6 from "../Assets/img/Partners/faircent.png";
import p7 from "../Assets/img/Partners/hdfc.png";
import p8 from "../Assets/img/Partners/hero.png";
import p9 from "../Assets/img/Partners/idfc.jpg";
import p10 from "../Assets/img/Partners/iifl.png";
import p11 from "../Assets/img/Partners/kotak.jpg";
import p12 from "../Assets/img/Partners/landt.png";
import p13 from "../Assets/img/Partners/lendingkart.png";
import p14 from "../Assets/img/Partners/moneyview.png";
import p15 from "../Assets/img/Partners/pnb.jpg";
import p16 from "../Assets/img/Partners/tata.png";
// import p17 from "../Assets/img/Partners/urban.jpg"
import p18 from "../Assets/img/Partners/yes.png";
const img = [
  p1,
  p2,
  p3,
  p4,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
  p12,
  p13,
  p14,
  p15,
  p16,
  p18,
];

function Partner() {
  return (
    <>
      <div className="section-60 bg-f8f8f8 container-fluid">
        <div class="section-title">Our Partners</div>
        <div className="rfm-marquee-container">
          <div className="rfm-marquee">
            <div className="rfm-initial-child-container">
              {img.map((i) => {
                return (
                  <div className="rfm-child" style={{ margin: "0 15px" }}>
                    <div className="partner-width">
                      <img
                        src={i}
                        alt=""
                        className=" w-100 h-100 partner-image"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="rfm-marquee">
            {img.map((i) => {
              return (
                <div className="rfm-child" style={{ margin: "0 15px" }}>
                  <div className="partner-width">
                    <img
                      src={i}
                      alt=""
                      className=" w-100 h-100 partner-image"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
export default Partner;
