import life from "../Assets/img/LoanIcon/life-insurance.png";
import interest from "../Assets/img/LoanIcon/chart-down.png";
import high from "../Assets/img/LoanIcon/budget.png";
import money from "../Assets/img/LoanIcon/money.png";
import insurance from "../Assets/img/LoanIcon/insurance.png";
import health from "../Assets/img/LoanIcon/healthcare.png";
import cashless from "../Assets/img/LoanIcon/operation.png";
import illness from "../Assets/img/LoanIcon/sick.png";

export const lifeInsuranceData = {
  type: "Life",
  types: [
    {
      img: life,
      title: "Term Life Insurance",
      desc: "Term life insurance offers coverage for a specified period, typically ranging from 10 to 30 years. It provides a death benefit to your beneficiaries if you die during the policy term, but it does not accumulate cash value like permanent life insurance. Premiums are generally lower for term policies compared to permanent policies, making it an affordable option for many people. Term life insurance is often chosen to protect financial responsibilities such as mortgages or children's education costs during the policy term. It's straightforward and offers pure protection without investment or savings components.",
    },
    {
      img: life,
      title: "Whole Life Insurance",
      desc: "Whole life insurance is a type of permanent life insurance that provides coverage for your entire life as long as premiums are paid. It includes a cash value component that grows over time, offering a tax-deferred investment opportunity. Premiums remain level throughout the policy, providing predictable costs. Whole life insurance also offers the option to borrow against the cash value or withdraw funds, offering flexibility in financial planning. Upon the insured's death, beneficiaries receive a tax-free death benefit.",
    },
    {
      img: life,
      title: "Money Back Policy",
      desc: "Money-back policies are a type of life insurance that offers periodic payouts during the policy term. Policyholders receive a percentage of the sum assured at specified intervals, providing liquidity and financial flexibility. In the event of the policyholder's death during the term, the full sum assured is paid to the nominee, minus any previous payouts. These policies offer a combination of insurance protection and savings, making them a popular choice for those seeking both security and periodic returns.",
    },
    {
      img: life,
      title: "Endowment Policy",
      desc: "An endowment policy combines life insurance coverage with a savings or investment component. Policyholders pay regular premiums, part of which goes towards providing a death benefit to beneficiaries, and part is invested to accumulate cash value over time. At the end of the policy term or upon the insured's death, the accumulated cash value is paid out as a lump sum. Endowment policies offer both protection and a savings vehicle, making them suitable for long-term financial goals such as education expenses or retirement planning",
    },
  ],
  features: [
    {
      img: interest,
      title: "Tax benefits",
      desc: "Enrolling in a life insurance policy offers tax benefits, with premiums qualifying for deductions up to ₹1.5 lakhs under Section 80C of the Income Tax Act. Additionally, death benefits are fully tax-exempt under Section 10(10)D, providing financial security and tax savings.",
    },
    {
      img: money,
      title: "Guarantee of fix returns",
      desc: "Life insurance policies offer a predetermined payout after a set period. Understanding the structure and terms of various products helps tailor coverage to your needs. With accurate information, beneficiaries receive assured death benefits, providing peace of mind.",
    },
    {
      img: insurance,
      title: "Risk mitigation and coverage",
      desc: "Life insurance offers essential financial protection, providing compensation to beneficiaries in the event of the policyholder's death. By securing coverage, families safeguard against the economic hardships that may arise from the loss of the primary provider's income.",
    },
    {
      img: high,
      title: "Provision for loan",
      desc: "Some life insurance policies offer a loan feature, allowing you to borrow against the policy's cash value for various purposes like education or wedding expenses, using the policy as collateral, ensuring financial flexibility and security simultaneously.",
    },
    {
      img: health,
      title: "Health expense coverage",
      desc: "Endowment policies not only provide a death benefit but also offer coverage for medical expenses during illness. Optional riders can further enhance the policy's coverage, ensuring financial security for policyholders both in life and death.",
    },
  ],
  eligibility: [
    {
      title: "Age",
      desc: "Typically ranges from 18 to 85 years old, but may vary depending on the insurer and policy type.",
    },
    {
      title: "Health Condition",
      desc: "Generally, individuals in good health are eligible. Pre-existing medical conditions may affect eligibility and premium rates.",
    },
    {
      title: "Occupation",
      desc: "Certain high-risk occupations may require specialized coverage or have limitations on eligibility.",
    },
    {
      title: "Lifestyle Choices",
      desc: "Factors such as smoking, alcohol consumption, and participation in high-risk activities can impact eligibility and premium rates.",
    },
    {
      title: "Income",
      desc: "Having a stable income is often a requirement for purchasing life insurance.",
    },
  ],
};

export const healthInsuranceData = {
  type: "Health",
  types: [
    {
      img: health,
      title: "Individual Health Insurance policy",
      desc: "Individual health insurance policies provide personal coverage for medical expenses, including hospitalization, surgeries, and treatments for illnesses or injuries. Tailored to individual needs, these policies offer financial protection against healthcare costs, ensuring access to quality medical care without worrying about exorbitant expenses.",
    },
    {
      img: health,
      title: "Family Health Insurance policy",
      desc: "A Family Health Insurance policy offers comprehensive coverage for medical expenses incurred by you and your family members. It typically includes hospitalization, outpatient care, maternity benefits, and sometimes dental and vision care. With a single policy, you can ensure the well-being of your entire family, providing financial security and access to quality healthcare when needed.",
    },
    {
      img: health,
      title: "Group Insurance Policies",
      desc: "Group insurance policies offer coverage to a group of people, typically employees of a company or members of an organization, under a single policy. These policies often provide health, life, or disability insurance benefits at lower costs due to the collective purchasing power of the group.",
    },
    {
      img: health,
      title: "Critical Illness Insurance",
      desc: "Critical illness insurance provides a lump-sum payout upon diagnosis of serious illnesses such as cancer, heart attack, or stroke. It offers financial support to cover medical expenses, loss of income during treatment, and other associated costs, providing a safety net during challenging times. Having critical illness insurance can alleviate financial strain and allow focus on recovery without worrying about the financial impact of a serious health condition.",
    },
    {
      img: health,
      title: "Senior Citizen Health Insurance",
      desc: "Senior citizen health insurance offers tailored coverage for the medical needs of elderly individuals, typically aged 60 and above. It provides financial protection against medical expenses, including hospitalization, surgeries, and medication, ensuring comprehensive healthcare support in their golden years. With specialized benefits and coverage options, it prioritizes the unique healthcare requirements of seniors, offering peace of mind and access to quality medical care.",
    },
  ],
  features: [
    {
      img: money,
      title: "Financial Protection",
      desc: "Health insurance provides a safety net against unexpected medical expenses, covering costs such as hospitalization, surgeries, medication, and diagnostic tests, thereby preventing financial strain on your savings.",
    },
    {
      img: health,
      title: "Access to Quality Healthcare",
      desc: "With health insurance, you gain access to a network of healthcare providers and facilities, ensuring prompt medical attention when needed, which can be crucial for timely treatment and recovery.",
    },
    {
      img: cashless,
      title: "Cashless Treatment",
      desc: "Many health insurance plans offer cashless treatment facilities, where you can avail medical services without paying cash upfront, easing the burden during emergencies.",
    },
    {
      img: illness,
      title: "Critical Illness Coverage",
      desc: "Some health insurance policies provide coverage for critical illnesses such as cancer, heart disease, and stroke, offering financial support for expensive treatments and care.",
    },
  ],
  eligibility: [
    {
      title: "Age",
      desc: "Typically individuals between 18 to 65 years are eligible, but some plans may cover children and seniors.",
    },
    {
      title: "Medical History",
      desc: "Generally, individuals with pre-existing conditions may still be eligible, but coverage may vary.",
    },
    {
      title: "Citizenship/Residency",
      desc: "Most plans require the insured to be a citizen or legal resident of the country where the insurance is purchased.",
    },
    {
      title: "Premium Payments",
      desc: "Eligibility often depends on the ability to pay premiums, which may vary based on income and financial status.",
    },
    {
      title: "Enrollment Periods",
      desc: "Some plans have specific enrollment periods during the year, while others may allow for enrollment at any time.",
    },
    {
      title: "Occupation",
      desc: "Certain high-risk occupations may affect eligibility and premium rates, particularly for disability and critical illness coverage.",
    },
    {
      title: "Dependents",
      desc: "Family plans may extend coverage to spouses and dependent children up to a certain age.",
    },
    {
      title: "Lifestyle Habits",
      desc: "Factors like smoking, alcohol consumption, and participation in high-risk activities may affect eligibility and premiums.",
    },
  ],
};

export const carInsuranceData = {
  type: "Car",
  types: [],
  features: [
    {
      img: money,
      title: "Financial Protection",
      desc: "Car insurance provides financial protection against unforeseen events such as accidents, theft, or natural disasters, covering the cost of repairs or replacement.",
    },
    {
      img: high,
      title: "No-Claim Bonus",
      desc: "If you don't make any claims during a policy period, you may be eligible for a no-claim bonus, which can lead to reduced premiums or other benefits during policy renewal",
    },
  ],
  eligibility: [
    {
      title: "Age of Vehicle",
      desc: "Most insurance companies have specific criteria regarding the age of the vehicle. Typically, newer vehicles are easier to insure, while older vehicles may face limitations.",
    },
    {
      title: "Vehicle Condition",
      desc: "The vehicle should be in a roadworthy condition, free from major damages or defects that could pose safety hazards.",
    },
    {
      title: "Vehicle Usage",
      desc: "Insurance premiums may vary based on the purpose of vehicle usage, such as personal use, commercial use, or ridesharing services.",
    },
    {
      title: "Driver's Age and Experience",
      desc: "Younger and inexperienced drivers may face higher premiums due to higher perceived risk, while older and experienced drivers may qualify for lower rates.",
    },
    {
      title: "Driver's Record",
      desc: "A clean driving record, with no history of accidents or traffic violations, can result in lower insurance premiums, reflecting a lower risk profile.",
    },
    {
      title: "Location",
      desc: "The location where the vehicle is primarily parked or driven can impact insurance rates, with higher rates often associated with areas with higher crime rates or traffic congestion.",
    },
    {
      title: "Insurance History",
      desc: "Previous insurance history, including lapses in coverage or claims history, can affect eligibility and premium rates for car insurance.",
    },
  ],
};

export const bikeInsuranceData = {
  type: "Bike",
  types: [],
  features: [
    {
      img: money,
      title: "Financial Protection",
      desc: "Bike insurance provides financial protection against unforeseen events such as accidents, theft, or natural disasters, covering the cost of repairs or replacement.",
    },
    {
      img: high,
      title: "No-Claim Bonus",
      desc: "If you don't make any claims during a policy period, you may be eligible for a no-claim bonus, which can lead to reduced premiums or other benefits during policy renewal",
    },
  ],
  eligibility: [
    {
      title: "Age of the Applicant",
      desc: "Typically 18 years and above",
    },
    {
      title: "Type of Vehicle",
      desc: "Two-wheeler (motorcycle, scooter)",
    },
    {
      title: "Vehicle Registration",
      desc: "Valid vehicle registration documents",
    },
    {
      title: "Vehicle Condition",
      desc: "Roadworthy condition, not salvaged",
    },
    {
      title: "Insurance History",
      desc: "No history of insurance fraud or claims",
    },
    {
      title: "Geographic Location",
      desc: "Coverage availability varies by region",
    },
    {
      title: "Legal Requirements",
      desc: "Compliance with local insurance regulations and lawsCompliance with local insurance regulations and laws",
    },
  ],
};
