import { Link } from "react-router-dom";

function Options(props) {
  return (
    <div class="row gx-2 justify-content-center">
      {props.data.map((l) => {
        return (
          <div class="col-lg-4 col-md-6 col-12 mt-30">
            <div className="card single-card p-3 h-100 ">
              <div className="d-flex justify-content-between flex-column h-100 loan-type">
                <div>
                  <div className="d-flex">
                    <div className="w-100">
                      <div>
                        <b style={{ fontSize: "16px" }}>{l.name}</b>
                        <p className="mt-2 mr-2">
                          {l.desc}
                          <Link to={l.knowmore}>
                            <b> Know More</b>
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div
                      className="bg-f8f8f8 p-2"
                      style={{
                        borderRadius: "15px",
                        height: "60px",
                        width: "60px",
                      }}
                    >
                      <img src={l.img} alt="icon" height="40" width="40" />
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <Link to={l.applyNow}>
                    <b>Apply Now</b>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default Options;
