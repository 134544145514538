import { Link } from "react-router-dom";
import errorImg from "../Assets/img/Banner/new/404 Error.gif";
import { useEffect } from "react";
function Error() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // <div className='error-area'>
    <div className="error-content">
      <div
        className=" d-flex justify-content-between flex-column"
        style={{ height: "100vh" }}
      >
        <div>
          <img
            src={errorImg}
            alt="error"
            style={{ width: "100%", maxWidth: "350px" }}
          />
        </div>
        <div>
          <h3>Page Not Found</h3>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
        </div>
        <div>
          <Link to="/home" class="default-btn">
            Go to Home <span></span>
          </Link>
        </div>
      </div>
    </div>
    // </div>
  );
}
export default Error;
