import axios from "axios";

//get customer
export const getCustomer = (obj) => {
  return axios
    .get("/customer/" + obj.customer_id, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

//get customer loans
export const getCustomerLoans = (obj) => {
  return axios
    .get("/customer/loan/" + obj.customer_id, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

//get customer incomplete loans
export const getCustomerIncompleteLoans = (obj) => {
  return axios
    .get("/customer/incomplete-loan/" + obj.customer_id, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
