import { Tab, Nav } from "react-bootstrap";
import { List, TableList } from "./loanDetails";
import { Link } from "react-router-dom";

function CCDetails(props) {
  const { tabs, data, activeTab, handleTabChange } = props;
  return (
    <div className="section-60 container-fluid">
      <Nav
        variant="tabs"
        defaultActiveKey={activeTab}
        className="loan-tab-header px-3 mb-30"
      >
        {tabs.map((t, index) => {
          if (t !== "Types" || (t === "Types" && data.types.length > 0)) {
            return (
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={`#tab${index + 1}`}
                  eventKey={`tab${index + 1}`}
                  onClick={() => handleTabChange(`tab${index + 1}`)}
                >
                  {t}
                </Nav.Link>
              </Nav.Item>
            );
          }
        })}
        <Nav.Item>
          <Nav.Link as={Link} to={`/apply-cc/${data.type}`}>
            Apply Now
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane
          eventKey="tab1"
          className={activeTab === "tab1" ? "active" : ""}
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-12">
                <div class="about-image">
                  <img src={data.img1} alt="image" />
                  <img src={data.img2} alt="image" />
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div className="py-3">
                  <div className="sub-title text-justify">{data.desc}</div>
                </div>
              </div>
            </div>
          </div>
          {data.types.length > 0 ? (
            <>
              <div class="section-title mt-45">Types of Credit Card</div>
              <List listdata={data.types} />
            </>
          ) : (
            <div className="mt-45"></div>
          )}
          <div class="section-title">Documents</div>
          <TableList listdata={data.documents} />
        </Tab.Pane>
        {data.types.length > 0 && (
          <Tab.Pane
            eventKey="tab2"
            className={activeTab === "tab2" ? "active" : ""}
          >
            <List listdata={data.types} />
          </Tab.Pane>
        )}
        <Tab.Pane
          eventKey="tab3"
          className={activeTab === "tab3" ? "active" : ""}
        >
          <TableList listdata={data.documents} />
        </Tab.Pane>
      </Tab.Content>
    </div>
  );
}
export default CCDetails;
