import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";
import img1 from "../Assets/img/Loan/home1.png";
import img2 from "../Assets/img/Loan/home2.png";
import { loanAgainstPropertyData } from "../Components/loanDetailsData";

function LoanAgainstProperty() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = [
    "Overview",
    "Features",
    "Eligibility & Documents",
    "Interest rates & Charges",
    "EMI Calculator",
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Loan Against Property",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...loanAgainstPropertyData,
    img1: img1,
    img2: img2,
    desc: "A loan against property (LAP) is a secured loan where a property owner pledges their property as collateral to obtain funds from a financial institution. The property can be residential, commercial, or industrial. These loans offer larger amounts compared to unsecured loans, with repayment terms typically ranging from several years to decades. Interest rates on LAPs are generally lower than unsecured loans due to the collateral involved. Borrowers can use the funds for various purposes such as business expansion, debt consolidation, or personal expenses. Failure to repay the loan could result in the lender seizing the property to recover the outstanding amount.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default LoanAgainstProperty;
