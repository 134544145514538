import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";

function SmfcPersonalLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Eligibility & Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "SMFC Personal Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    type: "SMFCPersonal",
    features: [],
    eligibility: [
      {
        title: "Age",
        desc: "Age between 21 to 60 years**",
      },
      {
        title: "Employment",
        desc: "Salaried or Self-employed",
      },
      {
        title: "Minimum Income",
        desc: "Minimum Salary – INR 20,000 - 25,000**",
      },
      {
        title: "Credit Score",
        desc: "750 or above, with good credit history",
      },
      {
        title: "Nationality",
        desc: "Resident Indian",
      },
      {
        title: "Work Experience",
        desc: "Overall work experience of 1+ years",
      },
    ],
    documents: [
      {
        title: "Identity Proof",
        desc: [
          "PAN Card",
          "Aadhar Card",
          "Driver's License",
          "Passport",
          "Voter ID",
        ],
      },
      {
        title: "Signature Proof",
        desc: ["PAN Card", "Passport"],
      },
      {
        title: "Address Proof",
        desc: [
          "Aadhar Card",
          "Driver's License",
          "Passport Copy",
          "Utility Bill - Gas or Electricity Bill",
          "Voter ID",
          "Ration Card",
          "Rental Agreement",
        ],
      },
      {
        title: "Income Proof",
        desc: [
          [
            "For Salaried",
            "Bank statement for the past 6 months",
            "Salary slips for the last 3 months",
            "Income tax return OR Form 16",
          ],
          [
            "For self employed",
            "Balance sheet and profit and loss account, income computation for the last 2 years",
            "Income Tax Returns for the last 2 years",
            "IT Assessment OR Clearance certificate",
            "Income Tax challans OR TDS certificate (Form 16A) OR Form 26AS for income declared in ITR",
          ],
        ],
      },
      {
        title: "Business Proof",
        desc: [
          [
            "For self employed",
            "License",
            "Registration Certificate",
            "GST Number",
          ],
        ],
      },
    ],
    interest: [],
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default SmfcPersonalLoan;
