import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";
import img1 from "../Assets/img/Loan/car1.png";
import img2 from "../Assets/img/Loan/car2.png";
import { carLoanData } from "../Components/loanDetailsData";

function CarLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = [
    "Overview",
    "Features",
    "Eligibility & Documents",
    "Interest rates & Charges",
    "EMI Calculator",
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Car Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...carLoanData,
    img1: img1,
    img2: img2,
    desc: "A car loan is a type of financing specifically intended for the purchase of a vehicle. Borrowers can obtain car loans from banks, credit unions, online lenders, or car dealerships. These loans typically have fixed or variable interest rates and repayment terms ranging from a few years to several years. The vehicle itself often serves as collateral for the loan, meaning it can be repossessed by the lender if the borrower fails to make payments. Car loans may require a down payment, and the interest rate can vary based on factors such as credit history, loan term, and the amount borrowed. Once approved, borrowers make regular payments over the loan term until the loan is fully repaid.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default CarLoan;
