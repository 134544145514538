import { bankDetails } from "../api/customerAuth";
import { getCustomer } from "../api/customer";
import { useEffect, useState } from "react";
import {
  validateACno,
  validateCAC,
  validateIFSC,
  validateName,
  validateText,
} from "../Common/validations";
import { getLocalStorage } from "../Common/cookie";
import { updateStatus } from "../api/loan";
import { useNavigate } from "react-router-dom";

function ProfileBank(props) {
  const { msg, onSubmit, logindata, setLogindata, profile, loanId } = props;
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate()

  function onChangeData(e) {
    if (
      (e.target.name === "ac_no" || e.target.name === "c_ac_no") &&
      isNaN(e.target.value)
    ) {
      return false;
    }
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomer({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response?.status === 200) {
          response.data.bank[0].c_ac_no = response.data.bank[0].ac_no;
          setData(response.data.bank[0]);
        } else {
          navigate("/")
        }
      } else {
        navigate("/")
      }
    };
    asyncFn();
  }, [logindata]);

  function validateAC(str) {
    setErrors((errors) => ({
      ...errors,
      ac_no: validateACno(str).error,
    }));
  }
  function validateC_AC(str, ac_no) {
    setErrors((errors) => ({
      ...errors,
      c_ac_no: validateCAC(str, ac_no).error,
    }));
  }

  function validateBankName(str) {
    setErrors((errors) => ({
      ...errors,
      bank_name: validateText(str).error,
    }));
  }
  function validateBranchName(str) {
    setErrors((errors) => ({
      ...errors,
      branch_name: validateText(str).error,
    }));
  }
  function validateIfsc(str) {
    setErrors((errors) => ({
      ...errors,
      ifsc_code: validateIFSC(str).error,
    }));
  }

  function validateForm() {
    validateAC(data.ac_no);
    {
      !profile && validateC_AC(data.c_ac_no, data.ac_no);
    }
    validateBankName(data.bank_name);
    validateBranchName(data.branch_name);
    validateIfsc(data.ifsc_code);
    let valid =
      validateACno(data.ac_no).valid && !profile
        ? validateCAC(data.c_ac_no, data.ac_no).valid
        : true &&
          validateText(data.bank_name).valid &&
          validateText(data.branch_name).valid &&
          validateIFSC(data.ifsc_code).valid;
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (validateForm()) {
      const response = await bankDetails({
        token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
        data: {
          id: getLocalStorage("rv-user")?.id,
          ac_no: data.ac_no,
          ifsc_code: data.ifsc_code,
          bank_name: data.bank_name,
          branch_name: data.branch_name,
        },
      });
      if (loanId) {
        const response = await updateStatus({
          token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
          data: {
            id: loanId,
            status: "CustomerBank",
          },
        });
      }
      if (response?.status === 200) {
        onSubmit("success");
      }
    }
  }

  return (
    <>
      <div class="form-group">
        <label className="label-login">Account No.: </label>
        <input
          type="text"
          name="ac_no"
          value={data.ac_no}
          class="form-control w-100"
          onBlur={() => validateAC(data.ac_no)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.ac_no && (
          <div class="help-block with-errors">{errors.ac_no}</div>
        )}
      </div>
      {!profile && (
        <div class="form-group">
          <label className="label-login">Confirm Account No.: </label>
          <input
            type="text"
            name="c_ac_no"
            value={data.c_ac_no}
            class="form-control w-100"
            onBlur={() => validateC_AC(data.c_ac_no, data.ac_no)}
            onChange={(e) => {
              onChangeData(e);
            }}
          />
          {errors.c_ac_no && (
            <div class="help-block with-errors">{errors.c_ac_no}</div>
          )}
        </div>
      )}
      <div class="form-group">
        <label className="label-login">IFSC Code: </label>
        <input
          type="text"
          name="ifsc_code"
          value={data.ifsc_code}
          class="form-control w-100"
          onBlur={() => validateIfsc(data.ifsc_code)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.ifsc_code && (
          <div class="help-block with-errors">{errors.ifsc_code}</div>
        )}
      </div>
      <div class="form-group">
        <label className="label-login">Bank Name: </label>
        <input
          type="text"
          name="bank_name"
          value={data.bank_name}
          class="form-control w-100"
          onBlur={() => validateBankName(data.bank_name)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.bank_name && (
          <div class="help-block with-errors">{errors.bank_name}</div>
        )}
      </div>
      <div class="form-group">
        <label className="label-login">Branch Name: </label>
        <input
          type="text"
          name="branch_name"
          value={data.branch_name}
          class="form-control w-100"
          onBlur={() => validateBranchName(data.branch_name)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.branch_name && (
          <div class="help-block with-errors">{errors.branch_name}</div>
        )}
      </div>
      <button
        type="submit"
        onClick={saveData}
        class="default-btn disabled"
        style={{ pointerEvents: "all", cursor: "pointer" }}
      >
        {msg} <span></span>
      </button>
    </>
  );
}
export default ProfileBank;
