import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";
import img1 from "../Assets/img/Loan/edu1.png";
import img2 from "../Assets/img/Loan/edu2.png";
import { loanData } from "../Components/loanDetailsData";

function StudentLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = [
    "Overview",
    "Features",
    "Eligibility & Documents",
    "Interest rates & Charges",
    "EMI Calculator",
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Student Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...loanData,
    img1: img1,
    img2: img2,
    desc: "A student loan is a type of financial aid specifically designed to help students cover the costs of higher education, including tuition, fees, books, and living expenses. These loans are typically offered by government agencies, such as the federal government in the United States, or by private lenders. They come with various terms and conditions, including interest rates and repayment plans, which may differ depending on the lender and the borrower's financial situation. Student loans often offer favorable terms compared to other types of loans, such as deferred payment options or income-driven repayment plans, to accommodate students' limited income during their studies. However, they must be repaid according to the agreed-upon terms once the borrower completes their education or drops below a certain enrollment status.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default StudentLoan;
