import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/cc2.png";
import img2 from "../Assets/img/Loan/cc1.png";
import { standardChartedCC } from "../Components/ccDetailsData";
import CCDetails from "../Components/ccDetails";

function SCCC() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Overview", "Types", "Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Standard Chartered Bank Credit Card",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...standardChartedCC,
    img1: img1,
    img2: img2,
    desc: "The Standard Chartered credit card is a versatile financial tool offering a range of benefits to suit various lifestyles. With its global acceptance and comprehensive rewards program, cardholders enjoy perks such as cashback, discounts on dining, shopping, and travel, as well as exclusive privileges like airport lounge access and concierge services. Whether you're a frequent traveler, a food enthusiast, or someone who loves to shop, Standard Chartered has a card tailored to your needs. With features like contactless payments and secure online transactions, it provides convenience and peace of mind in managing your finances. Choose Standard Chartered for a rewarding and seamless credit card experience.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <CCDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default SCCC;
