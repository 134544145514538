import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";
import img1 from "../Assets/img/Loan/machinary1.png";
import img2 from "../Assets/img/Loan/machinary2.png";
import { machinaryLoanData } from "../Components/loanDetailsData";

function MachinaryLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = [
    "Overview",
    "Features",
    "Eligibility & Documents",
    "Interest rates & Charges",
    "EMI Calculator",
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Machinary Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...machinaryLoanData,
    img1: img1,
    img2: img2,
    desc: "A machinery loan is a type of financing specifically tailored for businesses to purchase or upgrade equipment and machinery necessary for their operations. These loans are typically offered by banks, financial institutions, or specialized lenders. The borrowed funds can cover the cost of machinery, tools, vehicles, or other equipment required for production or business activities. Machinery loans often have fixed or variable interest rates and repayment terms based on the expected lifespan of the equipment. Collateral may be required to secure the loan, and borrowers may need to provide details on the intended use and expected productivity gains from the machinery to secure financing.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default MachinaryLoan;
