import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";

function FibePersonalLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Eligibility & Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Fibe Personal Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    type: "FibePersonal",
    features: [
      "No restrictions on usage",
      "Collateral-fee loans",
      "100% digital journey",
      "Affordable interest rates",
      "Borrow multiple times",
      "Flexible repayment",
      "Cash in bank"
    ],
    eligibility: [{
      title: "Age",
      desc: "Above 21 and below 55 years"
    },{
      title: "Minimum in-hand salary",
      desc: [
        "Metros: ₹ 18000",
        "Non-metros: ₹ 15000"
      ]
    },{
      title: "Resident",
      desc: "A resident of India"
    }],
    documents: [{
      title: "A selfie",
      desc: "A clear picture of yours with proper lighting"
    },{
      title: "Proof of Identity",
      desc: "Passport / Aadhar card / PAN card / Driver's license"
    },{
      title: "Proof of Address",
      desc: "Passport / rent agreement / utility bills / voter's ID"
    }, {
      title: "Proof of Income",
      desc: "Bank statements / salary slips for the last 3 months"
    }],
    interest: [],
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default FibePersonalLoan;
