import { useEffect } from "react";
import BreadCrumbs from "../Components/breadcrumbs";

function Policy() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Privacy Policy",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="container">
        <div className="section-60 text-justify">
          This Privacy Policy outlines how RV Capital (referred to as "We,"
          "Our," or "Us" as appropriate) manages the collection, use,
          processing, storage, retrieval, disclosure, or transfer of your
          information. This includes personal information and sensitive personal
          data or information that RV Capital may receive when you access,
          interact with, or use our website (https://rvcapitalfinance.com/),
          associated Android and iOS mobile applications, mobile site, or any
          other medium, including our call-center facility, through which we
          offer Our Services (as defined in the Terms and Conditions).
          <br />
          <br />
          To provide you (and co-applicants, members, nominees, if any) with our
          Services through the Portal, RV Capital and authorized third parties
          need to collect various data and information from you with your prior
          explicit consent.
          <br />
          <br />
          This Privacy Policy incorporates all terms and conditions regarding
          the usage of the Portal and availing Our Services as set out in the
          Terms and Conditions.
          <br />
          <br />
          Any capitalized terms not defined in this Privacy Policy will have the
          meanings set out in the Terms and Conditions.
          <br />
          <br />
          <b>User acceptance</b>
          <br />
          <br />
          By accessing or utilizing the Portal or by availing Our Services, you
          are indicating your agreement to the terms outlined in this Privacy
          Policy. The collection, use, processing, storage, retrieval,
          disclosure, or transfer of your information, including personal
          information and sensitive personal data or information (collectively
          referred to as "Information"), will be conducted in accordance with
          this Privacy Policy and as required or permitted under applicable
          laws, including but not limited to the Information Technology Act,
          2000 and its associated rules, as well as any regulations, directions,
          circulars, guidelines, or other mandates issued by the Reserve Bank of
          India ("RBI") that are applicable to the Services ("Applicable Laws").
          <br />
          <br />
          Any Information provided by you or otherwise collected by Us, which is
          the subject matter of this Privacy Policy, is collected and retained
          by RV Capital, with its registered office located at 201 and 202, 2nd
          floor, AP81, Koregaon Park Annex, Mundhwa, Pune – 411036.
          <br />
          <br />
          Please review this Privacy Policy carefully to ensure you understand
          how any Information you provide to Us will be managed. By using any of
          Our Services, you are considered to have read and accepted this
          Privacy Policy. By accessing or using the Portal, you explicitly
          consent to Our collection, processing, storage, transfer, use,
          disclosure, and handling of your Information in accordance with this
          Privacy Policy. If you do not agree with the practices outlined in
          this Privacy Policy, please refrain from providing Us with your
          Information or using, accessing, or interacting with the Portal and/or
          availing Our Services.
          <br />
          <br />
          By providing Information to RV Capital, you affirm that:
          <br />
          <br />
          <ul>
            <li>
              The Information you provide to RV Capital is and shall remain
              authentic, correct, current, and updated, and you possess all the
              necessary rights, permissions, and consents required to provide
              such Information to RV Capital.
            </li>
            <li>
              Your provision of Information, as well as RV Capital's subsequent
              storage, collection, usage, transfer, access, or processing of the
              same, shall not violate any agreements, laws, charter documents,
              judgments, orders, or decrees.
            </li>
          </ul>
          <br />
          If you disclose any Information relating to other individuals to Us,
          you affirm that you have the necessary authority to do so and to
          authorize Us to utilize such Information in accordance with this
          Privacy Policy.
          <br />
          <br />
          It is your responsibility to ensure that the Information you provide
          to Us remains accurate and up-to-date at all times.
          <br />
          <br />
          Your Information will primarily be stored in electronic form; however,
          certain data may also be stored in physical form. We may engage in
          agreements with third parties to store or process your Information or
          data. These third parties may have their own security standards in
          place to protect your Information or data. We will, to a reasonable
          extent, require such third parties to adhere to reasonable security
          standards to safeguard your Information.
          <br />
          <br />
          <b>Information We collect</b>
          <br />
          <br />
          RV Capital utilizes the Information collected from or about you to
          facilitate the provision of Services on the Portal. By expressly
          consenting to this Privacy Policy, you permit Us to collect the
          Information as outlined herein and for the purposes specified. Should
          you opt not to provide Us with certain Information, you may find
          yourself unable to access the Portal or avail yourself of some or all
          of Our Services. RV Capital may gather the following Information, and
          by using Our Services, you explicitly consent to such collection:
          <br />
          <br />
          <b>1. Account Information:</b>
          <br />
          <br />
          When you create an account on the Portal, we gather information such
          as your first and last name, any referral code used, address details,
          photo, email addresses, and telephone numbers.
          <br />
          <br />
          <b>2. Identity:</b>
          <br />
          <br />
          For certain Services, we may require identity verification and Know
          Your Customer ("KYC") related Information to comply with regulatory
          requirements. This may include details such as your Permanent Account
          Number (PAN), photo, Aadhaar-related Information (including Aadhaar
          number, demographic details, or Virtual ID) for e-KYC authentication
          with the Unique Identification Authority of India (“UIDAI”), and One
          Time Password / Pin (OTP). Please note that while e-KYC authentication
          and submission of Aadhaar information are not mandatory, you may need
          to provide alternative identity documents as stipulated under KYC
          Directions, 2016 issued by the RBI.
          <br />
          <br />
          <b>3. Information to assess creditworthiness:</b>
          <br />
          <br />
          (a) For students: Education-related Information like college name,
          year of graduation, college ID card, and fee receipt.
          <br />
          (b) For salaried individuals: Employment-related Information including
          employer name, salary, offer letter, employment ID, and
          employer-issued email address.
          <br />
          (c) For self-employed individuals: Information related to
          self-employment such as profession, social media links related to
          work, work location, and income.
          <br />
          <br />
          <b>4. Financial Information:</b>
          <br />
          <br />
          We may collect financial Information (e.g., bank account details,
          payment instrument details), rental/lease agreements, utility bills,
          or transaction-related communications to provide the Services.
          <br />
          <br />
          <b>5. Device Information:</b>
          <br />
          <br />
          We may gather Information about the electronic device you use to
          access the Portal, including IP address, browser type, operating
          system, hardware and software details, device type, device ID, unique
          device identifiers (e.g., IMEI), serial number, user profile
          information, and the date and time of your visits to the Portal or
          when availing the Services. This helps us uniquely identify devices
          and prevent unauthorized access to prevent fraud.
          <br />
          <br />
          <b>6. Portal Usage:</b>
          <br />
          <br />
          We may collect Information submitted electronically such as message
          board posts, search queries, reviews, ratings, or comments, as well as
          Information about your usage behavior on the Portal. This data may be
          used to administer the Portal, generate usage statistics, and share
          relevant updates and information via email and/or SMS. Additionally,
          it helps us tailor communications to your interests and preferences.
          <br />
          <br />
          <b>7. SMS:</b>
          <br />
          <br />
          We review the headers of all SMS messages to identify the sender type.
          We only analyze the indicative content from 6-digit alphanumeric
          numbers for verification purposes. Relevant information such as sender
          names, SMS content, and received timestamps are periodically collected
          to facilitate financial and allied services, and this data may be
          transmitted to third parties. It's important to note that we do not
          analyze personal or OTP messages, except OTP messages sent by us for
          our intended use during the app's lifetime. This data may be utilized
          to verify financial statistics such as income, spending patterns,
          aiding in credit evaluation, facilitating higher limits, faster
          approval rates, and for fraud detection and prevention.
          <br />
          <br />
          <b>8. Camera and Microphone:</b>
          <br />
          <br />
          Access to your device's camera and microphone may be required for a
          seamless experience, such as taking a selfie, uploading photos, or
          recording audio/video for verification, onboarding, KYC purposes, or
          for uploading necessary documents on the Portal.
          <br />
          <br />
          <b>9. Location:</b>
          <br />
          <br />
          We may collect and monitor your location details to verify your
          current address, offer customized promotions, ensure serviceability of
          Services, and identify unusual activity to prevent fraudulent
          activity. Location data is collected when you add your pin code on the
          Portal or enable access from your mobile device while using Our
          Portal. Location data is not collected when the app is in the
          background.
          <br />
          <br />
          <b>10. Storage:</b>
          <br />
          <br />
          Permission may be required to securely download and save your
          documents on your phone, allowing you to upload required documents
          seamlessly while using the Portal.
          <br />
          <br />
          <b>11. Feedback Data and Other Data:</b>
          <br />
          <br />
          We may access and save Information in various instances, including
          recording Information provided during communications for quality and
          training purposes, participation in referral programs or use of
          discount codes, and providing feedback or comments on the Portal or
          other platforms.
          <br />
          <br />
          <b>12. Third-Party Information:</b>
          <br />
          <br />
          We work closely with Affiliates, third-party business partners,
          technical subcontractors, analytics providers, and other sources from
          which we may lawfully receive Information about you. This Information
          may be combined with data collected on the Portal and provided in this
          Policy.
          <br />
          <br />
          <b>13. Other Information:</b>
          <br />
          <br />
          We may collect Information from various sources, including delivery
          addresses or contact Information from Affiliates and partners, payment
          service providers, public records, publicly available sources,
          internet sites, vendors, data suppliers, service providers, marketing
          lists, telephone directories, social networks, news outlets, and other
          related media.
          <br />
          <br />
          Here's a revised version of the provided section:
          <br />
          <br />
          <b>Use of Your Information:</b>
          <br />
          <br />
          We may utilize the Information collected from you in accordance with
          this Privacy Policy for the following purposes:
          <br />
          <br />
          <ol>
            <li>Enabling your access to the Portal.</li>
            <li>
              Availing the Services offered, including associated services.
            </li>
            <li>
              Facilitating payments for transactions made using the Services.
            </li>
            <li>
              Creating, maintaining, or updating your account and verifying your
              identity.
            </li>
            <li>
              Conducting due diligence, Know Your Customer (KYC) checks, and
              other verifications mandated by Applicable Laws.
            </li>
            <li>
              Validating, processing, and sharing your KYC Information, nominee
              details, and other legally mandated Information with regulated
              entities, financial institutions, or service providers engaged by
              Us.
            </li>
            <li>Facilitating or confirming payments made by you.</li>
            <li>
              Enhancing your user experience on the Portal or the Services,
              including customization.
            </li>
            <li>
              Conducting credit checks, assessing creditworthiness, and
              obtaining credit information.
            </li>
            <li>Performing audits, market research, and troubleshooting.</li>
            <li>
              Identifying and detecting security breaches, fraud, and other
              criminal activities.
            </li>
            <li>
              Providing relevant offers or rewards based on consumption
              patterns.
            </li>
            <li>Enforcing Our Terms and Conditions and resolving disputes.</li>
            <li>Improving the usability and effectiveness of the Portal.</li>
            <li>
              Delivering administrative notices, alerts, and communication
              relevant to your use of the Portal.
            </li>
            <li>
              Recommending products or promotional offers based on your
              preferences.
            </li>
            <li>
              Complying with Applicable Laws or requests from authorities.
            </li>
            <li>
              Disclosing to Affiliates on a need-to-know basis to facilitate
              service provision.
            </li>
            <li>Fulfilling contractual obligations and enforcing rights.</li>
            <li>Improving the quality and design of the Portal.</li>
            <li>Enhancing the Services.</li>
            <li>Fulfilling any other purpose with your consent.</li>
          </ol>
          <br />
          <b>Sharing of Your Information:</b>
          <br />
          <br />
          You explicitly consent to sharing your Information for the following
          purposes:
          <br />
          <br />
          <ol>
            <li>
              With financial institutions, regulatory agencies, and other
              entities to facilitate Services.
            </li>
            <li>
              With vendors, service providers, business partners, and Affiliates
              for service provision and communication.
            </li>
            <li>With Our Affiliates under common ownership or control.</li>
            <li>
              With a buyer or successor in the event of a merger or transfer of
              assets.
            </li>
            <li>
              With law enforcement agencies and regulators to ensure compliance.
            </li>
            <li>To enforce agreements or comply with legal requirements.</li>
            <li>
              In any other circumstances with your consent or as disclosed by
              Us.
            </li>
          </ol>
          <br />
          <b>Third-party policies:</b>
          <br />
          <br />
          When using the Portal or availing Our Services, we may collaborate
          with various third parties. It's important to note that if you provide
          information to any third party or if you are directed to third-party
          links while using the Portal, Our Privacy Policy will no longer apply,
          and we are not responsible for the privacy practices of these third
          parties.
          <br />
          <br />
          Typically, the third-party service providers we engage with will only
          collect, use, and disclose your information to the extent necessary to
          perform the services or activities they provide to Us. We ensure
          secure sharing of Information and make reasonable efforts to ensure
          that all recipients comply with confidentiality obligations.
          <br />
          <br />
          However, certain third-party service providers, such as banks,
          financial institutions, co-lending partners, payment aggregators, or
          credit information companies, may have their own privacy policies
          regarding the information We provide to them. We recommend reviewing
          their privacy policies to understand how your Information will be
          handled. Additionally, keep in mind that some third-party service
          providers may be located in or have facilities in different
          jurisdictions. Therefore, if you proceed with a transaction involving
          a third-party service provider, your information may be subject to the
          laws of those jurisdictions. Once you leave the Portal or are
          redirected to a third-party website or application, you are no longer
          governed by Our Privacy Policy or the Portal’s Terms and Conditions,
          and the privacy policy of the other website will apply. We are not
          liable for the actions or omissions of third-party service providers.
          <br />
          <br />
          <b>Our security practices and policies:</b>
          <br />
          <br />
          While RV Capital endeavors to protect the security of your
          Information, we cannot guarantee its absolute security. We utilize
          standard security practices and procedures, such as secure server
          software and firewalls, to protect your Information from unauthorized
          access, disclosure, alteration, or destruction.
          <br />
          <br />
          RV Capital has established policies and procedures to safeguard
          information, governed by technical and operational security
          operations. We adhere to industry best practices in Information and
          cyber security. However, RV Capital disclaims liability related to
          security breaches or loss of your Information to the extent
          permissible by law.
          <br />
          <br />
          We take significant measures to protect against the loss, misuse, and
          alteration of collected information, potentially including secure
          server technology. However, it's important to note that the internet
          is not entirely secure. Therefore, we cannot guarantee the security of
          any Information transmitted to us over the internet, and we do not
          accept liability for any unintentional disclosure of Information.
          <br />
          <br />
          Products and the Website are password-protected, and users must not
          share passwords with anyone. Users are prohibited from attempting to
          breach security measures or access password-protected areas without
          proper authorization.
          <br />
          <br />
          <b>Retention of data, Withdrawal of Consent & Rectification:</b>
          <br />
          <br />
          You have the right to access and review the Information shared by you,
          subject to fulfilling pending contractual obligations, by contacting
          Us. You can also withdraw or revoke consent for Us to process any
          Information you've provided by contacting us at
          customercare@rvcapitalfinance.com. Additionally, you can request
          deletion of your account on the Portal.
          <br />
          <br />
          You have the right to deny consent for the use of specific data on the
          Portal.
          <br />
          <br />
          We will not retain your Information if it is no longer necessary and
          there is no legal requirement to do so. However, multiple legal bases
          may require us to retain certain Information for audit purposes or in
          accordance with applicable law.
          <br />
          <br />
          If any Information provided by you is inaccurate, incomplete, or
          outdated, you should provide Us with accurate and up-to-date
          Information to ensure uninterrupted use of Our Services. We encourage
          you to always provide Us with accurate and correct Information.
          <br />
          <br />
          <b>Protection of Children's Information</b>
          <br />
          <br />
          We do not knowingly solicit or collect Information from individuals
          under the age of 18 years. The use of the Portal is limited to
          individuals who can enter into a legally binding contract under
          Applicable Laws.
          <br />
          <br />
          <b>Updates to the Privacy Policy</b>
          <br />
          <br />
          We retain the right to amend, modify, add, or remove any provision of
          this Privacy Policy at Our discretion, without prior notice to you.
          <br />
          <br />
          We advise you to periodically review this Privacy Policy for the
          latest information regarding Our privacy practices. Your continued use
          of the Portal or availing of Our Services following the implementation
          of an updated Privacy Policy will signify your acceptance of the
          changes.
          <br />
          <br />
          <b>Use of Cookies</b>
          <br />
          <br />
          Upon visiting the Portal, one or more cookies will be transmitted to
          the device you are using to access the Portal. These cookies are
          utilized to enhance the quality of the Portal or to store your
          preferences as a user, thereby improving search results and tracking
          user trends. You have the option to disable cookies through your
          browser preferences. However, please note that disabling cookies may
          impact the functionality of certain parts of the Portal.
          <br />
          <br />
        </div>
      </div>
    </>
  );
}
export default Policy;
