import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/car1.png";
import img2 from "../Assets/img/Loan/car2.png";
import InsuranceDetails from "../Components/insuranceDetails";
import { carInsuranceData } from "../Components/insuranceDetailsData";

function CarInsurance() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Overview", "Types", "Features", "Eligibility"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Car Insurance",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...carInsuranceData,
    img1: img1,
    img2: img2,
    desc: "Car insurance provides financial protection against losses incurred due to accidents, theft, or damage to your vehicle. It offers peace of mind by covering repair costs and medical expenses for you and others involved in an accident. Additionally, it ensures compliance with legal requirements, offering liability coverage in case you cause damage to someone else's property or injure others. With various coverage options available, car insurance allows you to tailor protection to your specific needs, providing a safety net for unexpected events on the road.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <InsuranceDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default CarInsurance;
