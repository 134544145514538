import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/life1.jpg";
import img2 from "../Assets/img/Loan/life2.jpg";
import InsuranceDetails from "../Components/insuranceDetails";
import { lifeInsuranceData } from "../Components/insuranceDetailsData";

function LifeInsurance() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Overview", "Types", "Features", "Eligibility"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Life Insurance",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...lifeInsuranceData,
    img1: img1,
    img2: img2,
    desc: "Life insurance provides financial protection for your loved ones in the event of your death. By paying regular premiums, you ensure that your beneficiaries receive a lump sum payment, known as a death benefit, upon your passing. This benefit can help cover funeral expenses, replace lost income, settle debts, and maintain your family's standard of living. Additionally, life insurance offers peace of mind, knowing that your loved ones will be taken care of financially, even after you're gone. It's a crucial component of a comprehensive financial plan, providing security and stability for the future.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <InsuranceDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default LifeInsurance;
