import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import { getCustomerIncompleteLoans, getCustomerLoans } from "../api/customer";
import { getLocalStorage } from "../Common/cookie";
import { Link, useNavigate } from "react-router-dom";
import instantIcon from "../Assets/img/Loan/instantIcon.png";
import cartIcon from "../Assets/img/Loan/carIcon.png";
import hometIcon from "../Assets/img/Loan/homeIcon.png";
import eduIcon from "../Assets/img/Loan/eduIcon.png";
import machineIcon from "../Assets/img/Loan/machineIcon.png";
import businessIcon from "../Assets/img/Loan/businessIcon.png";
import morgageIcon from "../Assets/img/Loan/morgage.png";
import proffessionalIcon from "../Assets/img/Loan/professional.png";
import bajaj from "../Assets/img/Partners/bajaj.png";
import iifl from "../Assets/img/Partners/iifl.png";
import birla from "../Assets/img/Partners/birla.png";
import smfc from "../Assets/img/Partners/smfg.png";
import creditvidya from "../Assets/img/Partners/creditvidya.png";
import indus from "../Assets/img/CC/indus.png";
import fibe from "../Assets/img/Partners/fibe.jpg";

function Loans() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "My Loans",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomerLoans({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response?.status === 200) {
          setData(response.data);
          await getImg(response.data);
          await checkIncompleteLoans();
        } else {
          navigate("/")
        }
      } else {
        navigate("/");
      }
    };
    asyncFn();
  }, []);

  async function checkIncompleteLoans() {
    const checkCookie = getLocalStorage("rv-user");
    const response = await getCustomerIncompleteLoans({
      customer_id: checkCookie.id,
      token: checkCookie.accessToken,
    });
    if (response?.status === 200) {
      if (response.data?.id) {
        const nextLink = await checkNext(
          response.data.id,
          response.data.status
        );
        setData((prevData) => {
          return prevData.map((item) => {
            if (item.id === response.data.id) {
              return { ...item, nextLink: nextLink };
            }
            return item;
          });
        });
      }
    }
  }

  const checkNext = (id, status) => {
    if (status === "CounterOffer") {
      return `/apply-now/Instant/offered?id=${id}`;
    } else if (status === "ResponseByClient") {
      return `/apply-now/Instant/kyc?id=${id}`;
    } else if (status === "CustomerKYC") {
      return `/apply-now/Instant/address?id=${id}`;
    } else if (status === "CustomerAddress") {
      return `/apply-now/Instant/bank?id=${id}`;
    } else if (status === "CustomerBank") {
      return `/apply-now/Instant/review?id=${id}`;
    } else if (status === "LoanReference") {
      return `/apply-now/Instant/docs?id=${id}`;
    }
  };

  const getStatusMessage = (id, status) => {};

  function getImg(res_data) {
    let data = res_data;
    if (data.length > 0) {
      data.map((d) => {
        if (d.loan_type === "Instant") {
          d.type = "Instant Personal Loan"
          d.img = instantIcon;
        } else if (d.loan_type === "InstantFromBank") {
          let bankData = getBankIcon(d.bank_name);
          d.instant_bank_name = bankData.name;
          d.img = bankData.img;
        } else if (d.loan_type === "Business") {
          d.type = "Business Loan"
          d.img = businessIcon;
        } else if (d.loan_type === "Car") {
          d.type = "Car Loan"
          d.img = cartIcon;
        } else if (d.loan_type === "Education") {
          d.type = "Education Loan"
          d.img = eduIcon;
        } else if (d.loan_type === "Home") {
          d.type = "Home Loan"
          d.img = hometIcon;
        } else if (d.loan_type === "AgainstProperty") {
          d.type = "Loan Against Property"
          d.img = morgageIcon;
        } else if (d.loan_type === "Machinary") {
          d.type = "Machinary Loan"
          d.img = machineIcon;
        } else if (d.loan_type === "Professional") {
          d.type = "Professional Loan"
          d.img = proffessionalIcon;
        }
      });
      setData(data);
    }
  }

  function getBankIcon(bank_name) {
    if (bank_name === "IndusindPersonal") {
      return {
        img: indus,
        name: "Instant Personal loan from IndusInd",
      };
    } else if (bank_name === "AdityaBirlaPersonal") {
      return {
        img: birla,
        name: "Instant Personal loan from Aditya Birla",
      };
    } else if (bank_name === "SMFCPersonal") {
      return {
        img: smfc,
        name: "Instant Personal loan from SMFC",
      };
    } else if (bank_name === "CreditVidyaPersonal") {
      return {
        img: creditvidya,
        name: "Instant Personal loan from Credit Vidya",
      };
    } else if (bank_name === "FibePersonal") {
      return {
        img: fibe,
        name: "Instant Personal loan from Fibe",
      };
    } else if (bank_name === "IIFLBusiness") {
      return {
        img: iifl,
        name: "Instant Business loan from IIFL",
      };
    } else if (bank_name === "BajajPersonal") {
      return {
        img: bajaj,
        name: "Instant Personal loan from Bajaj",
      };
    } else if (bank_name === "BirlaUdyogBusiness") {
      return {
        img: birla,
        name: "Instant Business loan from Aditya Birla Udyog",
      };
    }
  }
  // Granted
  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="container section-60">
        <div class="row gx-2 justify-content-center">
          {data.length > 0 ? (
            data.map((l) => {
              const height = l.loan_type === "InstantFromBank" ? "auto" : "70";
              const cardContent = (
                <div className="card single-card p-3 h-100">
                  <div className="d-flex justify-content-between flex-column h-100 loan-type">
                    <div>
                      <div className="d-flex">
                        <div
                          className="bg-f8f8f8 p-2 d-flex align-items-center"
                          style={{
                            borderRadius: "15px",
                            height: "100px",
                            width: "100px",
                          }}
                        >
                          <img
                            src={l.img}
                            alt="icon"
                            height={height}
                            width="80"
                          />
                        </div>
                        <div className="w-100 d-flex align-items-center">
                          {l.loan_type === "InstantFromBank" && (
                            <div>
                              <b
                                className="ml-3"
                                style={{ fontSize: "16px", color: "#002d5b" }}
                              >
                                {l.instant_bank_name}
                              </b>
                            </div>
                          )}
                          {l.loan_type === "Instant" && (
                            <div>
                              <b
                                className="ml-3"
                                style={{ fontSize: "16px", color: "#002d5b" }}
                              >
                                {l.loan_type} Loan
                              </b>
                              <p className="mt-2 ml-3">
                                Loan for <b>₹{l.offered_amount}</b> with{" "}
                                <b>{l.offered_interest}%</b> of interest
                                <br />
                                {l.emi_amount && Number(l.emi_amount) > 0 && (
                                  <>
                                    <b>EMI:</b> ₹{l.emi_amount}
                                    <br />
                                  </>
                                )}
                                <div>
                                  <b>Status:</b> status
                                </div>
                                Applied on{" "}
                                {new Date(l.created_at).toLocaleDateString(
                                  "en-GB"
                                )}
                                {l.nextLink && (
                                  <div className="mt-3 ">
                                    <Link
                                      to={l.nextLink}
                                      style={{ color: "#002d5b" }}
                                    >
                                      Exciting Offers are waiting for you. Let's
                                      continue from where you left.
                                      <b> Continue</b>
                                    </Link>
                                  </div>
                                )}
                              </p>
                            </div>
                          )}
                          {l.loan_type !== "Instant" &&
                            l.loan_type !== "InstantFromBank" && (
                              <div>
                                <b
                                  className="ml-3"
                                  style={{ fontSize: "16px", color: "#002d5b" }}
                                >
                                  {l.type} 
                                </b>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );

              return (
                <div className="col-12 mt-30">
                  {l.status === "Granted" ? (
                    <Link to={`/loan-details/${l.id}`}>{cardContent}</Link>
                  ) : (
                    <div>{cardContent}</div>
                  )}
                </div>
              );
            })
          ) : (
            <>
              <h5>You have not applied for loan yet.</h5>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default Loans;
// /uploads/PANCard/1715191903759-cust-PROP PAN_compressed.pdf
