import cc from "../Assets/img/LoanIcon/cc.png";

export const AxisCC = {
  type: "Axis",
  types: [
    {
      img: cc,
      title: "REATIL CARD ETB",
      desc: "The ETB Axis Bank Retail Credit Card is tailored for everyday shopping needs, offering rewards and cashback on retail purchases across a wide range of categories such as groceries, dining, and fuel. With features like accelerated rewards points and discounts at partner merchants, it's an ideal choice for those seeking value and convenience in their day-to-day spending.",
    },
    {
      img: cc,
      title: "RETAIL CARD NTB",
      desc: "The Retail Card from Axis Bank is designed to cater to everyday shopping needs, offering rewards and benefits tailored for retail spends. With features such as cashback on groceries, dining, and fuel, it provides convenient and rewarding experiences for cardholders.",
    },
    {
      img: cc,
      title: "EMERGING AFFLUENT CARDS ETB",
      desc: "The Emerging Affluent Cards from Axis Bank cater to individuals on the rise, offering a blend of exclusive privileges and rewards. With benefits like accelerated reward points on lifestyle spends, travel perks, and dining discounts, these cards elevate the experience for aspiring affluent customers.",
    },
    {
      img: cc,
      title: "EMERGING EMERGING AFFLUENT CARD NTB",
      desc: "The Axis Bank Emerging Affluent Card is tailored for individuals aspiring towards financial success, offering a blend of exclusive benefits and privileges. With features like accelerated rewards on lifestyle spends, complimentary lounge access, and personalized assistance, it caters to the evolving needs of emerging affluent customers, ensuring a seamless and rewarding banking experience.",
    },
    {
      img: cc,
      title: "TRUE AFFLUENT CARD ETB",
      desc: "The TRUE Affluent Card by Axis Bank is a premium credit card tailored for high-net-worth individuals. It offers exclusive privileges such as personalized concierge services, airport lounge access, luxury travel benefits, and premium lifestyle rewards, making it ideal for those seeking elite experiences and personalized service.",
    },
    {
      img: cc,
      title: "TRUE AFFLUENT CARDS NTB",
      desc: "The TRUE Affluent Cards offered by NTB Axis Bank are premium credit cards tailored for high-net-worth individuals. They provide exclusive benefits such as access to luxury airport lounges, personalized concierge services, and rewards on luxury spends, catering to the affluent lifestyle with unmatched privileges and convenience.",
    },
  ],
  documents: [
    {
      title: "General Docs",
      desc: [
        "PAN Card",
        "Aadhar Card",
        "Aadhar link mobile number",
        "Mail ID",
        ["Salary per month for listed company -", "30K"],
        ["Salary per month for non-listed company -", "40K"],
        "6 month bank statement",
        "730 CIBIL - NO DPD",
        "Salary slip of 3 months",
      ],
    },
    {
      title: "Business person ITR req",
      desc: "ITR - 6 lakhs gross total income",
    },
    {
      title: "Self employes and salaried card to card",
      desc: [
        "Other CC limit should be 30K",
        "Credit card statement of 6 months",
      ],
    },
  ],
};

export const HdfcCC = {
  type: "HDFC",
  types: [
    {
      img: cc,
      title: "Classic",
      desc: "The HDFC Bank Classic Credit Card offers essential benefits for everyday spending, including reward points on purchases and fuel surcharge waivers. With its wide acceptance and ease of use, it's a reliable choice for those seeking a basic credit card option from HDFC Bank.",
    },
    {
      img: cc,
      title: "Premium",
      desc: "HDFC Bank premium credit cards offer exclusive privileges such as airport lounge access, concierge services, travel benefits like complimentary air tickets and hotel stays, rewards on high-value spends, and lifestyle perks tailored for discerning customers seeking luxury and convenience in their financial transactions.",
    },
    {
      img: cc,
      title: "Super Premium",
      desc: "The HDFC Bank Super Premium Credit Card is a top-tier offering tailored for high-net-worth individuals, providing an array of exclusive benefits such as airport lounge access, concierge services, luxury hotel privileges, premium travel rewards, and personalized lifestyle perks. With enhanced security features and unparalleled customer service, this card is designed to cater to the sophisticated needs of discerning customers.",
    },
  ],
  documents: [
    {
      title: "General Docs",
      desc: [
        "PAN Card",
        "Aadhar Card",
        "Aadhar link mobile number",
        "Mail ID",
        ["Salary per month for listed company -", "15K"],
        ["Salary per month for non-listed company -", "20K"],
        "6 month bank statement",
        "700 CIBIL - NO DPD",
        "Salary slip of 3 months",
      ],
    },
    {
      title: "Business person ITR req",
      desc: "ITR - 6 lakhs gross total income",
    },
    {
      title: "Self employes and salaried card to card",
      desc: [
        "Other CC limit should be 30K",
        "Credit card statement of 6 months",
      ],
    },
  ],
};

export const IdfcCC = {
  type: "IDFC",
  types: [
    {
      img: cc,
      title: "Regular",
      desc: "A regular credit card is one of the types of cards a bank offers to a customer with several features. For instance, IDFC FIRST Bank has the FIRST Classic Credit Card and FIRST Millenia Credit Card in this category. The cards offer numerous benefits such as discounts, complimentary railway lounge access, personal accident coverage, fuel surcharge waiver, and free roadside assistance.",
    },
    {
      img: cc,
      title: "Premium",
      desc: "If you are looking for better lifestyle benefits and privileges, premium credit cards can offer you. IDFC FIRST Bank offers you the FIRST Select Credit Card with amazing reward points on buying online and offline and a higher credit limit. It also attracts lower forex mark-up fee on shopping internationally. The premium types of credit cards also offer airport and railway lounge access, discounts on dining at restaurants and health and wellness outlets, comprehensive travel insurance coverage, personal accident insurance, and more.",
    },
    {
      img: cc,
      title: "Super Premium",
      desc: "With these types of credit cards, banks serve special customers who are highly worthy and have used several of their services. For exclusive lifestyle experience, IDFC FIRST Bank offers the FIRST Wealth Credit Card with unique benefits. They include travel privileges of complimentary lounge and spa access at both domestic and international airports, low forex mark-up fee, air accident and personal accident coverage, complimentary lost card liability and travel insurance.",
    },
    {
      img: cc,
      title: "Co-branded",
      desc: "As for the types of credit cards, you can also find cobranded cards offered for specific purpose and usage. For instance, IDFC FIRST Power and FIRST Power + Credit Cards, co-branded with HPCL, offers rewards on fuel payments. By using this fuel credit card at HPCL outlets, customers can Save up to ₹7,000 annually, enough to buy 65+ litres of fuel.",
    },
    {
      img: cc,
      title: "Reward and Cashback",
      desc: "Reward credit cards are a financial tool that allows you to earn incentives for your spending. These cards typically offer points, cashback, or miles based on your purchases. Points can be redeemed for various rewards such as travel, gift cards, or merchandise. Cashback cards provide a percentage of your spending back as a cash reward, while miles cards accrue airline miles for future flights.",
    },
    {
      img: cc,
      title: "Travel",
      desc: "Travel credit cards are designed to meet your travel expenses such as trip and hotel bookings, offer rail and airport lounge access as well as travel insurance coverage to provide you with a hassle-free journey experience. They often come with rewards in the form of airline miles, hotel points, or flexible travel credits. These rewards accumulate with each purchase and can be redeemed for flights, hotel stays, rental cars, and other travel-related expenses.",
    },
    {
      img: cc,
      title: "Business",
      desc: "Business credit cards are tailored cards for entrepreneurs, small business owners, and professionals for short-term borrowings. These cards are distinct from personal credit cards, offering benefits and features specifically designed to meet the needs of businesses.",
    },
    {
      img: cc,
      title: "Secured",
      desc: "Unlike traditional unsecured credit cards, secured cards require a security deposit, which serves as collateral. The amount of the deposit often determines the credit limit, typically equal to or slightly higher than the deposit itself. For instance, IDFC FIRST Bank WOW! Credit Card is an FD-secured card that not only offers exciting shopping, lifestyle, entertainment, and travel benefits but also comes with no income verification or credit check requirements. Simply said, WOW! Credit Card is a credit card for all, allowing you to use at least 100% of your deposits as credit limits while earning a high interest rate on your fixed deposit.",
    },
    {
      img: cc,
      title: "Store",
      desc: "Store credit cards, often referred to as retail credit cards, are a distinct category of credit cards issued by specific retailers or store chains. These cards are designed to foster customer loyalty and increase sales within a particular brand or store. They achieve this by offering a range of unique incentives and benefits tailored to shoppers of that specific retailer.",
    },
    {
      img: cc,
      title: "Student",
      desc: "Student credit cards are designed to meet the unique needs of college or university students. These cards offer young adults a convenient and responsible way to begin building a credit history while managing their finances during their academic years. Typically, student credit cards have lower credit limits, making them suitable for students with limited or no credit history. These lower limits help prevent students from accumulating excessive debt while they learn how to manage credit responsibly.",
    },
  ],
  documents: [
    {
      title: "General Docs",
      desc: [
        "PAN Card",
        "Aadhar Card",
        "Aadhar link mobile number",
        "Mail ID",
        "25K per month salary",
        "6 month bank statement",
        "700 CIBIL - NO DPD",
        "Salary slip of 3 months",
      ],
    },
    {
      title: "Business person ITR req",
      desc: "ITR - 5 lakhs gross total income",
    },
    {
      title: "Self employes and salaried card to card",
      desc: [
        "Other CC limit should be 30K",
        "Credit card statement of 6 months",
      ],
    },
  ],
};

export const IciciCC = {
  type: "ICICI",
  types: [
    {
      img: cc,
      title: "Platinum Chip",
      desc: "The ICICI Bank Platinum Chip Credit Card offers enhanced security with an embedded EMV chip, providing protection against fraudulent transactions. It also provides access to a range of lifestyle privileges, including dining discounts, travel benefits, and reward points on purchases, making it an ideal choice for those seeking security and exclusive perks.",
    },
    {
      img: cc,
      title: "HPCL Coral",
      desc: "The HPCL Coral ICICI Bank Credit Card is designed for fuel enthusiasts, offering attractive rewards and savings on fuel purchases at HPCL petrol pumps. With benefits like accelerated rewards on dining, groceries, and utility bill payments, this card provides a seamless blend of fuel savings and everyday convenience.",
    },
    {
      img: cc,
      title: "MMT Platinum",
      desc: "The MMT Platinum ICICI Bank Credit Card is a co-branded offering with MakeMyTrip, providing travel enthusiasts with exclusive benefits such as accelerated reward points on travel bookings, complimentary lounge access, discounts on flight and hotel bookings through MakeMyTrip, and fuel surcharge waivers, making it an ideal choice for frequent travelers seeking value and convenience.",
    },
    {
      img: cc,
      title: "Coral",
      desc: "This card offers rewards on dining, groceries, and movie ticket purchases, along with benefits like airport lounge access and fuel surcharge waivers.",
    },
    {
      img: cc,
      title: "HPCL Super Saver",
      desc: "Co-branded with HPCL, this card provides fuel benefits such as cashback and discounts on fuel purchases, along with rewards on other spends and dining privileges.",
    },
    {
      img: cc,
      title: "Manchester United",
      desc: "Co-branded with Manchester United, this card offers exclusive privileges for football enthusiasts, including merchandise discounts, matchday experiences, and rewards on everyday purchases.",
    },
    {
      img: cc,
      title: "CSK",
      desc: "Co-branded with Chennai Super Kings, this card provides special benefits for cricket fans, including merchandise discounts, match tickets, and rewards on entertainment and dining expenses.",
    },
    {
      img: cc,
      title: "MMT Signature",
      desc: "Co-branded with MakeMyTrip, this card offers exclusive travel benefits such as complimentary flight tickets, hotel stays, and discounts on bookings, along with accelerated rewards on travel and lifestyle spends.",
    },
    {
      img: cc,
      title: "Emirates Rubyx",
      desc: "Co-branded with Emirates, this card provides travel privileges including complimentary lounge access, air miles on Emirates and other airlines, discounts on ticket bookings, and lifestyle benefits such as dining and entertainment offers.",
    },
    {
      img: cc,
      title: "RUBY X",
      desc: "The RUBY X ICICI Bank Credit Card is designed for frequent travelers, offering premium benefits such as complimentary airport lounge access, travel insurance coverage, and accelerated rewards on travel and dining spends. With its sleek design and exclusive privileges, it's an ideal choice for those seeking luxury and convenience in their credit card experience.",
    },
    {
      img: cc,
      title: "Sapphiro",
      desc: "The ICICI Bank Sapphiro Credit Card is a premium offering that provides exclusive benefits such as airport lounge access, complimentary golf games, and travel privileges. With its focus on luxury and convenience, it caters to the needs of frequent travelers and lifestyle enthusiasts seeking elevated experiences.",
    },
    {
      img: cc,
      title: "Emeralde",
      desc: "The ICICI Bank Emeralde/Emirates Emeralde Credit Card offers premium privileges tailored for frequent travelers, including complimentary lounge access, travel insurance coverage, exclusive dining and lifestyle rewards, and accelerated reward points on Emirates flights and other spend categories. This card is designed to enhance the travel experience and provide luxury benefits for discerning customers.",
    },
  ],
  documents: [
    {
      title: "General Docs",
      desc: [
        "PAN Card",
        "Aadhar Card",
        "Aadhar link mobile number",
        "Mail ID",
        ["Salary per month for listed company -", "40K"],
        ["Salary per month for non-listed company -", "40K+"],
        "6 month bank statement",
        "750 CIBIL - NO DPD",
        "Salary slip of 3 months",
      ],
    },
    {
      title: "Business person ITR req",
      desc: "ITR - 6 lakhs gross total income",
    },
    {
      title: "Self employes and salaried card to card",
      desc: [
        "Other CC limit should be 30K",
        "Credit card statement of 6 months",
      ],
    },
  ],
};

export const IndusindCC = {
  type: "Indusind",
  types: [
    {
      img: cc,
      title: "AURA EDGE",
      desc: "The AURA EDGE Platinum credit card from IndusInd Bank offers exclusive privileges and rewards tailored to discerning customers. With benefits like accelerated rewards points, complimentary lounge access, concierge services, and attractive discounts on lifestyle spends, it's designed to elevate your shopping and travel experiences.",
    },
    {
      img: cc,
      title: "Signature Legend",
      desc: "The Signature Legend IndusInd Bank credit card offers a host of premium benefits tailored for luxury travelers and high spenders. With features like complimentary airport lounge access, exclusive concierge services, accelerated rewards on travel and lifestyle spends, and premium lifestyle privileges, it's designed to elevate your experiences and cater to your discerning lifestyle needs.",
    },
    {
      img: cc,
      title: "Government",
      desc: "The IndusInd Bank Government Credit Card is specifically tailored to meet the financial needs of government employees, offering benefits such as competitive interest rates, convenient payment options, and exclusive rewards tailored for government spending. With features like easy expense tracking and dedicated customer support, it provides a seamless financial solution for government officials.",
    },
    {
      img: cc,
      title: "Nexxt/Eazy Dinner",
      desc: "The IndusInd Bank Nexxt/Eazy Dinner credit card offers versatile payment options with its innovative Nexxt Chip technology, allowing users to switch between credit, EMI, and reward points redemption modes seamlessly. Additionally, it provides exclusive dining benefits such as discounts and offers at partner restaurants, making it an ideal choice for food enthusiasts seeking convenience and savings.",
    },
  ],
  documents: [
    {
      title: "General Docs",
      desc: [
        "PAN Card",
        "Aadhar Card",
        "Aadhar link mobile number",
        "Mail ID",
        ["Salary per month for listed company -", "20K"],
        ["Salary per month for non-listed company -", "30K"],
        ["Salary per month for Govt. employee -", "24K"],
        "6 month bank statement",
        "700 CIBIL - NO DPD",
        "Salary slip of 3 months",
      ],
    },
    {
      title: "Business person ITR req",
      desc: "ITR - 6 lakhs gross total income",
    },
    {
      title: "Self employes and salaried card to card",
      desc: [
        "Other CC limit should be 30K",
        "Credit card statement of 6 months",
      ],
    },
  ],
};

export const standardChartedCC = {
  type: "StandardCharted",
  types: [
    {
      img: cc,
      title: "SMART CARD / EMT (FULL FREE) CAT-A TO H",
      desc: "The SMART CARD/EMT (FULL FREE) CAT-A TO H Standard Chartered Bank credit card offers a convenient and secure way to make payments with enhanced security features like EMV chip technology. With no annual fee and acceptance at millions of merchants worldwide, it provides hassle-free transactions and peace of mind. Enjoy the convenience of contactless payments and exclusive benefits tailored to your lifestyle.",
    },
    {
      img: cc,
      title: "SMART CARD / EMT (FULL FREE) CAT-MTO Z",
      desc: "The SMART CARD/EMT (FULL FREE) CAT-MTO Z Standard Chartered Bank credit card is a contactless payment solution that offers convenience and security for transactions. With no annual fees, it provides users with easy access to a range of benefits including cashback rewards, discounts, and exclusive privileges tailored to their lifestyle needs.",
    },
    {
      img: cc,
      title: "SMART / REWARDS (FYF) CAT-A TO H",
      desc: "The Standard Chartered SMART/REWARDS Credit Card offers a range of benefits including cashback, rewards points, and exclusive privileges for everyday spending. With no annual fee for the first year and catering to individuals with good to excellent credit profiles, it's an attractive option for those seeking value and convenience in their credit card experience.",
    },
    {
      img: cc,
      title: "SMART / REWARDS(FYF) CAT-M TO Z",
      desc: "The Standard Chartered Bank SMART/REWARDS(FYF) CAT-M TO Z credit card is a versatile option offering a combination of rewards and smart benefits. With no annual fee for the first year, cardholders can enjoy perks like cashback, rewards points, and exclusive discounts tailored to their spending habits, making it an attractive choice for those seeking value and flexibility in their credit card experience.",
    },
    {
      img: cc,
      title: "PLATINUM REWADS (LTF) CAT – A TO H",
      desc: "The Standard Chartered Bank Platinum Rewards Credit Card offers lifetime free membership and is available across categories A to H. With this card, cardholders can earn rewards on their everyday spends, enjoy discounts, and access exclusive privileges, making it a valuable choice for those seeking rewards and benefits without annual fees.",
    },
    {
      img: cc,
      title: "PLATINUM REWADS (LTF) CAT - M TO Z",
      desc: "The Standard Chartered Platinum Rewards credit card offers a rewarding experience with lifetime free membership. With its Cat-M to Z designation, it provides perks tailored to your spending habits, including rewards on categories like dining, shopping, and more, making it an excellent choice for those seeking value and convenience in their credit card experience.",
    },
  ],
  documents: [
    {
      title: "General Docs",
      desc: [
        "PAN Card",
        "Aadhar Card",
        "Aadhar link mobile number",
        "Mail ID",
        "25K per month salary",
        "6 month bank statement",
        "700 CIBIL - NO DPD",
        "Salary slip of 3 months",
      ],
    },
    {
      title: "Business person ITR req",
      desc: "ITR - 5 lakhs gross total income",
    },
    {
      title: "Self employes and salaried card to card",
      desc: [
        "Other CC limit should be 30K",
        "Credit card statement of 6 months",
      ],
    },
  ],
};
