import { getCustomer } from "../api/customer";
import { useEffect, useState } from "react";
import { applyInstantLoan, updateStatus } from "../api/loan";
import { validateSalary, validateSelect } from "../Common/validations";
import { getLocalStorage } from "../Common/cookie";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import waiting from "../Assets/img/Banner/new/hourGlass.gif";

function RequestLoan(props) {
  const navigate = useNavigate();
  const { msg, onSubmit, socket, setId } = props;
  const [data, setData] = useState({});
  const [amount, setAmount] = useState(null);
  const [emi, setEmi] = useState(null);
  const [tenureOption, setTenureOption] = useState([]);
  const [selectedTenure, setSelectedTenure] = useState(null);
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const validateMaxAmount = (e) => {
    if (e.target.name === "amount") {
      let value = e.target.value;
      const max = 50000;
      if (Number(value) > Number(max)) {
        setAmount(max);
      }
    }
  };

  // useEffect(() => {
  //     let t = []
  //     if (amount < 5000) {
  //         t = ['7 days', '15 days']
  //     } else if (amount < 10000) {
  //         t = ['15 days', '3 months']
  //     } else if (amount < 25000) {
  //         t = ['3 months', '6 months', '1 year']
  //     } else {
  //         t = ['6 months', '1 year', '2 years']
  //     }
  //     setTenureOption(t)
  //     if (!selectedTenure || !(t.includes(selectedTenure))) {
  //         setSelectedTenure(t[0])
  //     }
  // }, [amount])

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomer({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response?.status === 200) {
          setData(response.data);
        }
      }
    };
    asyncFn();
  }, []);

  function validateAmount(str) {
    setErrors((errors) => ({
      ...errors,
      amount: validateSalary(str).error,
    }));
  }
  function validateTenure(str) {
    setErrors((errors) => ({
      ...errors,
      tenure: validateSelect(str).error,
    }));
  }

  function validateForm() {
    validateAmount(amount);
    // validateTenure(selectedTenure)
    let valid = validateSalary(amount).valid;
    // && validateSelect(selectedTenure).valid;
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (validateForm()) {
      // let tenure, tenure_in;
      // if (selectedTenure === '7 days') {
      //     tenure = '7'
      //     tenure_in = 'days'
      // }
      // else if (selectedTenure === '15 days') {
      //     tenure = '15'
      //     tenure_in = 'days'
      // }
      // else if (selectedTenure === '3 months') {
      //     tenure = '3'
      //     tenure_in = 'months'
      // }
      // else if (selectedTenure === '6 months') {
      //     tenure = '6'
      //     tenure_in = 'months'
      // }
      // else if (selectedTenure === '1 year') {
      //     tenure = '1'
      //     tenure_in = 'years'
      // }
      // else if (selectedTenure === '2 years') {
      //     tenure = '2'
      //     tenure_in = 'years'
      // }
      const response = await applyInstantLoan({
        token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
        data: {
          loan_type: "Instant",
          customer_id: data.id || getLocalStorage("rv-user")?.id,
          req_amount: amount,
          // req_tenure: tenure,
          // req_tenure_in: tenure_in,
          // emi: emi ? emi : 0
        },
      });
      if (response?.status === 200) {
        setLoading(true);
        // onSubmit('success');
        // toast.success("Your loan requested successfully.")
        // navigate('/')
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your previous loan was applied within 30 days! You can't not apply new instant loan now.",
        });
      }
    }
  }

  useEffect(() => {
    socket.on("loanRes", (newObject) => {
      if (newObject.offered_amount) {
        setLoading(false);
        navigate(`/apply-now/Instant/offered?id=${newObject.id}`);
      }
    });
  }, [socket]);

  return (
    <>
      {loading ? (
        <>
          <h3
            style={{
              marginTop: "-30px",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            We're Processing On Your Request...
          </h3>
          <div className="d-flex justify-content-center">
            <img
              src={waiting}
              alt="waiting"
              style={{ width: "100%", maxWidth: "150px", height: "auto" }}
            />
          </div>
          <div className="d-flex justify-content-center mt-3">
            <div style={{ maxWidth: "400px", textAlign: "center" }}>
              It may takes upto 4-5 minutes to process your request. Please wait
              for few minutes.
            </div>
          </div>
          {/* <div className='card eligibilityTable p-3 text-center'>Please wait for few minutes. Your request is <b>Processing...</b></div> */}
        </>
      ) : (
        <>
          <h3 style={{ marginTop: "-30px", marginBottom: "30px" }}>
            Request for your Personal Loan
          </h3>
          <div class="form-group">
            <label className="label-login">Amount</label>
            <input
              type="number"
              name="amount"
              class="form-control w-100"
              onBlur={(e) => validateMaxAmount(e)}
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            />
            {errors.amount && (
              <div class="help-block with-errors">{errors.amount}</div>
            )}
          </div>
          {/* {amount && <div class="form-group">
                        <label className='label-login'>Tenure</label>
                        <select name="tenure" class="form-control w-100" value={selectedTenure} onChange={(e) => setSelectedTenure(e.target.value)} >
                            {tenureOption.length > 0 &&
                                tenureOption.map((c) => {
                                    return <option key={c} value={c}>{c}</option>
                                })
                            }
                        </select>
                        {errors.tenure && <div class="help-block with-errors">{errors.tenure}</div>}
                    </div>}
                    {amount && amount > 10000 &&
                        <div class="form-group">
                            <label className='label-login'>Need EMI: </label><br />
                            <input type="radio" name="emi" value="1" onChange={(e) => { setEmi(e.target.value) }} /> Yes
                            <input style={{ marginLeft: '30px' }} type="radio" name="emi" value="0" onChange={(e) => { setEmi(e.target.value) }} /> No
                            {errors.emi && <div class="help-block with-errors">{errors.emi}</div>}
                        </div>
                    } */}
          <button
            type="submit"
            onClick={saveData}
            class="default-btn disabled"
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            {msg} <span></span>
          </button>
        </>
      )}
    </>
  );
}
export default RequestLoan;
