import { addressDetails } from "../api/customerAuth";
import { getCustomer } from "../api/customer";
import { useEffect, useState } from "react";
import { validateText, validatePin } from "../Common/validations";
import { getLocalStorage } from "../Common/cookie";
import { getLocation } from "../api/location";
import { updateStatus } from "../api/loan";
import { useNavigate } from "react-router-dom";

function ProfileAddress(props) {
  const { msg, onSubmit, logindata, setLogindata, profile, loanId } = props;
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate()

  function onChangeData(e) {
    if (e.target.name === "pincode") {
      if (isNaN(e.target.value)) {
        return false;
      } else {
        setLocation(e.target.value);
      }
    }
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomer({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response?.status === 200) {
          setData(response.data);
          if (response.data.pincode) {
            await setLocation(response.data.pincode);
          }
        } else {
          navigate("/")
        }
      } else {
        navigate("/")
      }
    };
    asyncFn();
  }, [logindata]);

  const setLocation = async (pincode) => {
    if (pincode && pincode.length === 6) {
      const location = await getLocation({
        pincode: pincode,
      });
      if (location[0]?.Status === "Success") {
        const lData = location[0].PostOffice[0];
        setData((data) => ({
          ...data,
          city: lData.District,
          state: lData.State,
          country: lData.Country,
        }));
        setErrors((errors) => ({
          ...errors,
          pincode: "",
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          pincode: "Please enter valid Pin Code",
        }));
      }
    }
  };

  function validateAddr1(str) {
    setErrors((errors) => ({
      ...errors,
      addressline1: validateText(str).error,
    }));
  }
  function validateArea(str) {
    setErrors((errors) => ({
      ...errors,
      area: validateText(str).error,
    }));
  }
  function validatePincode(str) {
    setErrors((errors) => ({
      ...errors,
      pincode: validatePin(str).error,
    }));
  }

  function validateForm() {
    validateAddr1(data.addressline1);
    validateArea(data.area);
    validatePincode(data.pincode);
    let valid =
      validateText(data.addressline1).valid &&
      validateText(data.area).valid &&
      validatePin(data.pincode).valid;
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (!errors.pincode) {
      if (validateForm()) {
        const response = await addressDetails({
          token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
          data: {
            id: data.id || getLocalStorage("rv-user")?.id,
            addressline1: data.addressline1,
            addressline2: data.addressline2,
            area: data.area,
            pincode: data.pincode,
            city: data.city,
            state: data.state,
            country: data.country,
          },
        });
        if (loanId) {
          const response = await updateStatus({
            token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
            data: {
              id: loanId,
              status: "CustomerAddress",
            },
          });
        }
        if (response?.status === 200) {
          onSubmit("success");
        }
      }
    }
  }
  return (
    <>
      <div class="form-group">
        <label className="label-login">Address Line 1</label>
        <input
          type="text"
          name="addressline1"
          class="form-control w-100"
          value={data.addressline1}
          onBlur={() => validateAddr1(data.addressline1)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.addressline1 && (
          <div class="help-block with-errors">{errors.addressline1}</div>
        )}
      </div>
      <div class="form-group">
        <label className="label-login">Address Line 2</label>
        <input
          type="text"
          name="addressline2"
          class="form-control w-100"
          value={data.addressline2}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.addressline2 && (
          <div class="help-block with-errors">{errors.addressline2}</div>
        )}
      </div>
      <div class="form-group">
        <label className="label-login">Area</label>
        <input
          type="text"
          name="area"
          class="form-control w-100"
          value={data.area}
          onBlur={() => validateArea(data.area)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.area && <div class="help-block with-errors">{errors.area}</div>}
      </div>
      <div class="form-group">
        <label className="label-login">Pin Code</label>
        <input
          type="text"
          name="pincode"
          class="form-control w-100"
          value={data.pincode}
          onBlur={() => validatePincode(data.pincode)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
        {errors.pincode && (
          <div class="help-block with-errors">{errors.pincode}</div>
        )}
      </div>
      <div class="form-group">
        <label className="label-login">City</label>
        <input
          type="text"
          name="city"
          class="form-control w-100"
          value={data.city}
          disabled
        />
      </div>
      <div class="form-group">
        <label className="label-login">State</label>
        <input
          type="text"
          name="state"
          class="form-control w-100"
          value={data.state}
          disabled
        />
      </div>
      <button
        type="submit"
        onClick={saveData}
        class="default-btn disabled"
        style={{ pointerEvents: "all", cursor: "pointer" }}
      >
        {msg} <span></span>
      </button>
    </>
  );
}

export default ProfileAddress;
