import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/cc2.png";
import img2 from "../Assets/img/Loan/cc1.png";
import { IndusindCC } from "../Components/ccDetailsData";
import CCDetails from "../Components/ccDetails";

function IDFCCC() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Overview", "Types", "Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Indusind Bank Credit Card",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...IndusindCC,
    img1: img1,
    img2: img2,
    desc: "IndusInd Bank offers a diverse range of credit cards tailored to suit various lifestyles and preferences. These cards come with an array of benefits, including rewards programs, cashback offers, travel perks, dining privileges, and lifestyle benefits. With options like the Indulge Credit Card for luxury experiences, the Platinum Credit Card for everyday convenience, and the Pinnacle Credit Card for high spenders, there's a card to match every need. IndusInd credit cards often feature innovative technologies like contactless payments and reward redemption flexibility, enhancing the overall user experience. Whether you're a frequent traveler, a shopping enthusiast, or someone who enjoys fine dining, IndusInd Bank has a credit card designed to complement your lifestyle and provide value-added benefits.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <CCDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default IDFCCC;
