import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";
import img1 from "../Assets/img/Loan/personal1.png";
import img2 from "../Assets/img/Loan/personal2.png";
import { professionalLoanData } from "../Components/loanDetailsData";

function ProfessionalLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = [
    "Overview",
    "Features",
    "Eligibility & Documents",
    "Interest rates & Charges",
    "EMI Calculator",
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Professional Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...professionalLoanData,
    img1: img1,
    img2: img2,
    desc: "Our professional loans are tailored to meet the financial needs of individuals in various fields, including doctors, lawyers, engineers, and more. With competitive interest rates and flexible repayment options, we provide support for professional growth and development. Whether you're looking to expand your practice, upgrade equipment, or invest in professional development, our loans offer the funding you need. Benefit from quick and hassle-free application processes, with minimal documentation requirements. Enjoy personalized assistance from our expert advisors, who understand the unique challenges and opportunities professionals face. Empower your career aspirations with our professional loans, designed to fuel your success and achieve your goals.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default ProfessionalLoan;
