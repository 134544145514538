import { Link } from "react-router-dom";

function BreadCrumbs(props) {
  return (
    <div class="page-title-area page-title-bg3">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <div class="page-title-content">
              <h2>{props.paths[props.paths.length - 1].name}</h2>
              <ul>
                {props.paths.map((p) => {
                  return (
                    <li>
                      <Link to={p.path ? p.path : "#"}>{p.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BreadCrumbs;
