import { Link } from "react-router-dom";
import cibil from "../Assets/img/Banner/scoreGIF.gif";
function TopHeader() {
  const information = [
    // {
    //     desc: "whatsapp",
    //     icon: "fab fa-whatsapp",
    //     name: "WhatsApp",
    //     href: `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_LINK}&text=RV Capitals`,
    //     target: "_blank",
    //     value: process.env.REACT_APP_CONTACT
    // },
    {
      desc: "email",
      icon: "far fa-envelope",
      name: "Drop us an email",
      href: `mailto:${process.env.REACT_APP_EMAIL}`,
      target: "_self",
      value: process.env.REACT_APP_EMAIL,
    },
  ];
  return (
    <div class="top-header">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-7 col-12">
            <ul>
              {information.length > 0 &&
                information.map((info) => {
                  return (
                    <li
                      style={{
                        width: info.desc === "email" ? "280px" : "170px",
                      }}
                    >
                      <div class="icon">
                        <i class={info.icon}></i>
                      </div>
                      <span> {info.name} :</span>
                      <Link
                        rel="noopener noreferrer"
                        to={info.href}
                        target={info.target}
                      >
                        {info.value}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="col-lg-5 col-12">
            {/* <div className="d-flex justify-content-end">
              <Link to="" className="default-btn">
                <div className="d-flex">
                  <img className="mr-2" src={cibil} alt="" width="35" />
                  <div className="d-flex align-items-center">
                    Get Free CIBIL Score
                  </div>
                </div>
                <span></span>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default TopHeader;
