import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/cc2.png";
import img2 from "../Assets/img/Loan/cc1.png";
import { IdfcCC } from "../Components/ccDetailsData";
import CCDetails from "../Components/ccDetails";

function IDFCCC() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Overview", "Types", "Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "IDFC Bank Credit Card",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...IdfcCC,
    img1: img1,
    img2: img2,
    desc: "The IDFC FIRST Bank Credit Card is a versatile financial tool offering a range of benefits tailored to suit modern lifestyles. With customizable rewards programs, cardholders can earn points on everyday purchases like dining, groceries, and fuel, which can be redeemed for a variety of options including cashback, travel vouchers, and merchandise. Enhanced security features ensure peace of mind while shopping online or in-store, while complimentary insurance coverage offers added protection. Access to exclusive privileges such as airport lounge access, concierge services, and lifestyle perks make this card a valuable companion for both everyday expenses and luxury indulgences.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <CCDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default IDFCCC;
