import { useEffect } from "react";
import BreadCrumbs from "../Components/breadcrumbs";

function Insurances() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "My Insurances",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="container section-60">
        <div className="profile-input bg-f8f8f8">
          Hello (Display insurance details)
        </div>
      </div>
    </>
  );
}
export default Insurances;
