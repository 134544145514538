import { Link } from "react-router-dom";
import axis from "../Assets/img/Partners/axis.png";
import axisCC from "../Assets/img/CC/axis.jpg";
import hdfc from "../Assets/img/Partners/hdfc.png";
import hdfcCC from "../Assets/img/CC/hdfc.png";
import idfc from "../Assets/img/Partners/idfc.jpg";
import idfcCC from "../Assets/img/CC/idfc.png";
import icici from "../Assets/img/CC/icici.png";
import iciciCC from "../Assets/img/CC/iciciCC.png";
import indus from "../Assets/img/CC/indus.png";
import indusCC from "../Assets/img/CC/indusindCC.png";
import sc from "../Assets/img/CC/sc.png";
import scCC from "../Assets/img/CC/scCC.png";

function CCOptions(props) {
  const ccTypes = [
    {
      name: "AXIS Bank",
      bank: axis,
      desc: "Axis Bank Credit Card offers a wide range of benefits, including rewards, cashback, and exclusive privileges for diverse financial needs.",
      img: axisCC,
      applyNow: "/apply-cc/Axis",
    },
    {
      name: "HDFC Bank",
      bank: hdfc,
      desc: "Axis Bank Credit Card offers a wide range of benefits, including rewards, cashback, and exclusive privileges for diverse financial needs.",
      img: hdfcCC,
      applyNow: "/apply-cc/HDFC",
    },
    {
      name: "IDFC Bank",
      bank: idfc,
      desc: "Axis Bank Credit Card offers a wide range of benefits, including rewards, cashback, and exclusive privileges for diverse financial needs.",
      img: idfcCC,
      applyNow: "/apply-cc/IDFC",
    },
    {
      name: "ICICI Bank",
      bank: icici,
      desc: "Axis Bank Credit Card offers a wide range of benefits, including rewards, cashback, and exclusive privileges for diverse financial needs.",
      img: iciciCC,
      applyNow: "/apply-cc/ICICI",
    },
    {
      name: "IndusInd Bank",
      bank: indus,
      desc: "Axis Bank Credit Card offers a wide range of benefits, including rewards, cashback, and exclusive privileges for diverse financial needs.",
      img: indusCC,
      applyNow: "/apply-cc/Indusind",
    },
    {
      name: "Standard Chartered Bank",
      bank: sc,
      desc: "Axis Bank Credit Card offers a wide range of benefits, including rewards, cashback, and exclusive privileges for diverse financial needs.",
      img: scCC,
      applyNow: "/apply-cc/StandardCharted",
    },
  ];
  return (
    <div className="section-60">
      <div className="container">
        <div class="section-title">
          {props.applyInsurance
            ? "Select the type of Credit Card"
            : "Explore Our Range of Credit Card Options"}
        </div>
        <div className="sub-title text-center pb-3">
          A credit card is essential for building credit history, offering
          convenience, and providing financial flexibility in emergencies.
        </div>
        <div class="row gx-2 justify-content-center">
          {ccTypes.map((l) => {
            return (
              <div class="col-lg-4 col-md-6 col-12 mt-30">
                <div className="card single-card p-3 h-100">
                  <div className="d-flex justify-content-between flex-column h-100 loan-type">
                    <div>
                      <div className="d-flex">
                        <div className="w-100">
                          <div>
                            <img src={l.bank} height="60" />
                            <br />
                            <b style={{ fontSize: "16px" }}>
                              {l.name}
                              <br />
                              Credit Card!
                            </b>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <img
                            src={l.img}
                            alt="icon"
                            height="90"
                            width="210"
                            style={{ borderRadius: "4px" }}
                          />
                        </div>
                      </div>
                      {/* <p className="mt-2 mr-2">{l.desc}</p> */}
                    </div>
                    <div>
                      <Link to={l.applyNow}>
                        <button className="btn-default mt-3">
                          <b>Apply Now</b>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default CCOptions;
