import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/health1.jpg";
import img2 from "../Assets/img/Loan/health2.png";
import InsuranceDetails from "../Components/insuranceDetails";
import { healthInsuranceData } from "../Components/insuranceDetailsData";

function HealthInsurance() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Overview", "Types", "Features", "Eligibility"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Health Insurance",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...healthInsuranceData,
    img1: img1,
    img2: img2,
    desc: "Health insurance provides financial coverage for medical expenses incurred due to illnesses, injuries, or preventive care. It offers peace of mind by mitigating the financial burden of healthcare costs. Health insurance plans vary in coverage and can include doctor visits, hospital stays, prescription medications, and preventive services. Having health insurance ensures access to quality healthcare without worrying about the full cost. It helps individuals and families manage healthcare expenses and protects against unexpected medical emergencies, promoting overall well-being and financial stability.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <InsuranceDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default HealthInsurance;
