import { identityDetails } from "../api/customerAuth";
import { getCustomer } from "../api/customer";
import { useEffect, useState } from "react";
import pdf from "../Assets/img/pdf.png";
import {
  validateAadhar,
  validatePan,
  validateText,
} from "../Common/validations";
import { getLocalStorage } from "../Common/cookie";
import { updateStatus } from "../api/loan";
import { useNavigate } from "react-router-dom";

function ProfileIdentity(props) {
  const navigate = useNavigate()
  const { msg, onSubmit, logindata, setLogindata, profile, loanId } = props;
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [profileDB, setProfileDB] = useState("");
  const [selectedProfileFile, setSelectedProfileFile] = useState({
    preview: "",
    raw: "",
  });
  const [aadharDB, setAadharDB] = useState("");
  const [selectedAadharFile, setSelectedAadharFile] = useState({
    preview: "",
    raw: "",
  });
  const [panDB, setPanDB] = useState("");
  const [selectedPANFile, setSelectedPANFile] = useState({
    preview: "",
    raw: "",
  });

  const handleProfileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedProfileFile({
      preview: URL.createObjectURL(file),
      raw: file,
    });
  };

  // Function to handle file upload
  const handleAadharUpload = (event) => {
    const file = event.target.files[0];
    setSelectedAadharFile({
      preview: URL.createObjectURL(file),
      raw: file,
    });
  };

  // Function to handle file upload
  const handlePANUpload = (event) => {
    const file = event.target.files[0];
    setSelectedPANFile({
      preview: URL.createObjectURL(file),
      raw: file,
    });
  };

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomer({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response?.status === 200) {
          setData(response.data);
          setProfileDB(response.data?.profile);
          setAadharDB(
            response.data?.doc
              .filter((obj) => obj.doc_id.toLowerCase() === "aadhar")
              .pop()
          );
          setPanDB(
            response.data?.doc
              .filter((obj) => obj.doc_id.toLowerCase() === "pan")
              .pop()
          );
        } else {
          navigate("/")
        }
      } else {
        navigate("/")
      }
    };
    asyncFn();
  }, [logindata]);

  function validatePanNo(str) {
    setErrors((errors) => ({
      ...errors,
      pan_no: validatePan(str).error,
    }));
  }
  function validateAadharNo(str) {
    setErrors((errors) => ({
      ...errors,
      aadhar_no: validateAadhar(str).error,
    }));
  }
  function validateProfileFile() {
    setErrors((errors) => ({
      ...errors,
      profile: validateText(
        selectedProfileFile?.preview ? selectedProfileFile?.preview : profileDB
      ).error,
    }));
  }
  function validateAadharFile() {
    setErrors((errors) => ({
      ...errors,
      aadhar: validateText(
        selectedAadharFile?.preview ? selectedAadharFile?.preview : aadharDB
      ).error,
    }));
  }
  function validatePanFile() {
    setErrors((errors) => ({
      ...errors,
      pan: validateText(
        selectedPANFile?.preview ? selectedPANFile?.preview : panDB
      ).error,
    }));
  }

  function validateForm() {
    validatePanNo(data.pan_no);
    validateAadharNo(data.aadhar_no);
    validateAadharFile();
    validatePanFile();
    if (!profile) {
      validateProfileFile();
    }
    let valid =
      validatePan(data.pan_no).valid &&
      validateAadhar(data.aadhar_no).valid &&
      validateText(
        selectedAadharFile?.preview ? selectedAadharFile?.preview : aadharDB
      ).valid &&
      validateText(selectedPANFile?.preview ? selectedPANFile?.preview : panDB)
        .valid &&
      (!profile
        ? validateText(
            selectedProfileFile?.preview
              ? selectedProfileFile?.preview
              : profileDB
          ).valid
        : true);
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();

    if (validateForm()) {
        const formData = new FormData();
        formData.append("id", data.id || getLocalStorage("rv-user")?.id);
        formData.append("pan_no", data.pan_no);
        formData.append("aadhar_no", data.aadhar_no);
        formData.append("pan", selectedPANFile.raw ? selectedPANFile.raw : null);
        formData.append(
          "aadhar",
          selectedAadharFile.raw ? selectedAadharFile.raw : null
        );
        formData.append(
          "photo",
          selectedProfileFile.raw ? selectedProfileFile.raw : null
        );
        const response = await identityDetails({
          token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
          data: formData,
        });
        if (loanId) {
          const response = await updateStatus({
            token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
            data: {
              id: loanId,
              status: "CustomerKYC",
            },
          });
        }
        if (response?.status === 200) {
          onSubmit("success");
        }
    }
  }
  return (
    <>
      {profile ? (
        <>
          <div class="form-group">
            <label className="label-login">Aadhar Card No.: </label>
            <input
              type="text"
              name="aadhar_no"
              value={data.aadhar_no}
              class="form-control w-100"
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.aadhar_no && (
              <div class="help-block with-errors">{errors.aadhar_no}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">PAN No.: </label>
            <input
              type="text"
              name="pan_no"
              value={data.pan_no}
              class="form-control w-100"
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.pan_no && (
              <div class="help-block with-errors">{errors.pan_no}</div>
            )}
          </div>
        </>
      ) : (
        <>
          <div class="form-group">
            <label className="label-login">Profile: </label>
            <div
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                overflow: "hidden",
                borderRadius: "8px",
                border: "1px solid #ccc",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  background: "#f9f9f9",
                  textAlign: "center",
                  lineHeight: "150px",
                }}
              >
                {(() => {
                  if (selectedProfileFile?.preview) {
                    return (
                      <img
                        src={selectedProfileFile.preview}
                        style={{ width: "150px", height: "150px" }}
                      />
                    );
                  } else if (profileDB) {
                    const src = process.env.REACT_APP_API + profileDB;
                    return (
                      <img
                        src={src}
                        style={{ width: "150px", height: "150px" }}
                      />
                    );
                  } else {
                    return "Upload Profile";
                  }
                })()}
              </div>
              <input
                type="file"
                onChange={handleProfileUpload}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  opacity: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                accept="image/*"
              />
            </div>
            {errors.profile && (
              <div class="help-block with-errors">{errors.profile}</div>
            )}
          </div>
        </>
      )}
      <div class="form-group">
        <label className="label-login">Aadhar Card Document (in PDF): </label>
        <div
          style={{
            width: "200px",
            height: "50px",
            position: "relative",
            background: "#f9f9f9",
            overflow: "hidden",
            borderRadius: "8px",
            border: "1px solid #ccc",
          }}
        >
          {(() => {
            if (selectedAadharFile?.raw?.name) {
              const f_name =
                selectedAadharFile.raw.name.length > 20
                  ? selectedAadharFile.raw.name.substring(0, 20) + ".."
                  : selectedAadharFile.raw.name;
              return (
                <div className="d-flex p-1">
                  <div>
                    <img src={pdf} alt="pdf" height="40" width="40" />
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ fontSize: "12px" }}
                  >
                    {f_name}
                  </div>
                </div>
              );
              // return <embed src={selectedAadharFile.preview} style={{ width: "150px", height: "150px" }} type="application/pdf" />
            } else if (aadharDB) {
              const parts = aadharDB.path.split("/");
              const filename = parts[parts.length - 1];
              const parts1 = filename.split("-");
              const filename1 = parts1[parts1.length - 1];
              const f_name =
                filename1.length > 20
                  ? filename1.substring(0, 20) + ".."
                  : filename1;
              return (
                <div className="d-flex p-1">
                  <div>
                    <img src={pdf} alt="pdf" height="40" width="40" />
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ fontSize: "12px" }}
                  >
                    {f_name}
                  </div>
                </div>
              );
              // return <embed src={`${process.env.REACT_APP_API}${aadharDB.path}`} style={{ width: "150px", height: "150px" }} type="application/pdf" />
            } else {
              <button>Upload Aadhar Card</button>;
            }
          })()}
          <input
            type="file"
            onChange={handleAadharUpload}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              opacity: 0,
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            accept="application/pdf, image/*"
          />
        </div>
        {errors.aadhar && (
          <div class="help-block with-errors">{errors.aadhar}</div>
        )}
      </div>
      <div class="form-group">
        <label className="label-login">PAN Document (in PDF): </label>
        <div
          style={{
            width: "200px",
            height: "50px",
            position: "relative",
            background: "#f9f9f9",
            overflow: "hidden",
            borderRadius: "8px",
            border: "1px solid #ccc",
          }}
        >
          {(() => {
            if (selectedPANFile?.raw?.name) {
              const f_name =
                selectedPANFile.raw.name.length > 20
                  ? selectedPANFile.raw.name.substring(0, 20) + ".."
                  : selectedPANFile.raw.name;
              return (
                <div className="d-flex p-1">
                  <div>
                    <img src={pdf} alt="pdf" height="40" width="40" />
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ fontSize: "12px" }}
                  >
                    {f_name}
                  </div>
                </div>
              );
              // return <embed src={selectedPANFile.preview} style={{ width: "150px", height: "150px" }} type="application/pdf" />
            } else if (panDB) {
              const parts = panDB.path.split("/");
              const filename = parts[parts.length - 1];
              const parts1 = filename.split("-");
              const filename1 = parts1[parts1.length - 1];
              const f_name =
                filename1.length > 20
                  ? filename1.substring(0, 20) + ".."
                  : filename1;
              return (
                <div className="d-flex p-1">
                  <div>
                    <img src={pdf} alt="pdf" height="40" width="40" />
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ fontSize: "12px" }}
                  >
                    {f_name}
                  </div>
                </div>
              );
              // return <embed src={`${process.env.REACT_APP_API}${panDB.path}`} style={{ width: "150px", height: "150px" }} type="application/pdf" />
            } else {
              <button>Upload PAN Card</button>;
            }
          })()}
          <input
            type="file"
            onChange={handlePANUpload}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              opacity: 0,
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            accept="application/pdf, image/*"
          />
        </div>
        {errors.pan && <div class="help-block with-errors">{errors.pan}</div>}
      </div>
      <button
        type="submit"
        onClick={saveData}
        class="default-btn disabled"
        style={{ pointerEvents: "all", cursor: "pointer" }}
      >
        {msg} <span></span>
      </button>
    </>
  );
}
export default ProfileIdentity;
