import axios from "axios";

export const applyCC = (obj) => {
  return axios
    .post("/creditcard", obj.data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

export const updateCC = (obj) => {
  return axios
    .patch("/creditcard", obj.data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
