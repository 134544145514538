import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";
import img1 from "../Assets/img/Loan/home1.png";
import img2 from "../Assets/img/Loan/home2.png";
import { homeLoanData } from "../Components/loanDetailsData";

function HomeLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = [
    "Overview",
    "Features",
    "Eligibility & Documents",
    "Interest rates & Charges",
    "EMI Calculator",
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Home Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...homeLoanData,
    img1: img1,
    img2: img2,
    desc: "A home loan, also known as a mortgage, is a type of loan provided by financial institutions to individuals or families to purchase residential property. These loans typically have long repayment terms, often spanning several decades, and may have fixed or variable interest rates. Borrowers are required to make a down payment, usually a percentage of the home's purchase price, and the home serves as collateral for the loan. Home loans come with various options, such as fixed-rate mortgages, adjustable-rate mortgages, or government-backed loans like FHA or VA loans. Repayment is made through regular installments over the loan term until the loan is fully paid off.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default HomeLoan;
