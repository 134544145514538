import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";

function CreditVidyaPersonalLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Eligibility & Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Credit Vidya Personal Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    type: "CreditVidyaPersonal",
    features: [],
    eligibility: [{
      title: "CIBIL Score",
      desc: "650+"
    },{
      title: "Age",
      desc: "21+ years"
    },{
      title: "Income",
      desc: "₹ 15000+"
    }],
    documents: [{
      title: "Identity",
      desc: "PAN Card"
    },{
      title: "KYC",
      desc: "Aadhar Card"
    },{
      title: "Income",
      desc: "Bank statement"
    }],
    interest: [],
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default CreditVidyaPersonalLoan;
