import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/cc2.png";
import img2 from "../Assets/img/Loan/cc1.png";
import ApplyNowForm from "../Components/applyNowForm";
import { useEffect } from "react";
import { RWebShare } from "react-web-share";

function ApplyCCHdfc(props) {
  const { logindata, setLogindata } = props;
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Credit Card",
    },
    {
      name: "HDFC Bank Credit Card",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div class="container">
        <div class="section-60">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 d-none d-lg-block">
              <div class="about-image">
                <img src={img1} alt="image" />
                <img src={img2} alt="image" />
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div className="py-3">
                <div className="d-flex justify-content-between">
                  <div class="section-title text-left">
                    Get HDFC Bank Credit Card
                  </div>
                  <div>
                    <RWebShare
                      data={{
                        //   text: "Like humans, flamingos make friends for life",
                        url: window.location.href,
                        title: "RV Capital Finance",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <button
                        style={{
                          border: "2px solid #002d5b",
                          borderRadius: "8px",
                        }}
                      >
                        🔗
                      </button>
                    </RWebShare>
                  </div>
                </div>
                <ApplyNowForm
                  logindata={logindata}  
                  setLogindata={setLogindata}               
                  type="HDFC"
                  applyFor="CC"
                  link="https://applyonline.hdfcbank.com/cards/credit-cards.html?utm_content=DGPI&Channel=DSA&DSACode=XSDT&SMCode=K13395&LGCode=DGPI&LCCode=XSDT&LC2=SDH0003#nbb"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <ApplyInsuranceProducts type="Bike" /> */}
      </div>
    </>
  );
}
export default ApplyCCHdfc;
