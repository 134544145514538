import BreadCrumbs from "../Components/breadcrumbs";
import { useEffect } from "react";
import iifl from "../Assets/img/Partners/iifl.png"
import birla from "../Assets/img/Partners/birla.png"
import InstantLoanOptions from "../Components/instantLoanOptions";

function BusinessLoanFromBank() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Business Loan",
    },
  ];

  const loanType = [
    {
      name: "IIFL",
      icon: "fas fa-hand-holding-usd",
      path: "iifl",
      img: iifl,
      desc: "",
      knowmore: "/instant/iifl",
      applyNow: "/apply-now/IIFLBusiness",
    },
    {
      name: "Aditya Birla Udyog",
      icon: "fas fa-hand-holding-usd",
      path: "birla-udyog",
      img: birla,
      desc: "",
      knowmore: "/instant/birla-udyog",
      applyNow: "/apply-now/BirlaUdyogBusiness",
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <InstantLoanOptions data={loanType} type="Business" />
    </>
  );
}
export default BusinessLoanFromBank;
