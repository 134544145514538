import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import testimonials1 from "../Assets/img/Testimonials/1.jpg";
function Testimonials(props) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 3,
      slidesToSlide: 1,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 992, min: 678 },
      items: 2,
      slidesToSlide: 1,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 678, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
  };
  const review = [
    {
      review:
        "The customer support team was extremely helpful, guiding me through the loan application process step by step. I highly recommend their personalized service.",
      name: "Nency Kapadia",
      position: "Manager",
      image: testimonials1,
    },
    {
      review:
        "The financial advisors on the platform were extremely knowledgeable. They took the time to understand my financial situation and recommended the best insurance plan for my family. Their advice gave me peace of mind, knowing I had the right coverage.",
      name: "Devendra Bhaisha",
      position: "Manager",
      image: testimonials1,
    },
    {
      review:
        "It allowed me to evaluate multiple loan and insurance products side by side based on interest rates, premiums, and features. This made it easy to pick the most suitable plan for my budget.",
      name: "Dev Desai",
      position: "Manager",
      image: testimonials1,
    },
    {
      review:
        "The credit card advisor was incredibly knowledgeable and helped me find a card that matched my spending habits and rewards preferences. They explained the fees and benefits clearly, making it easy for me to make an informed decision. Highly recommend their service for anyone new to credit cards!",
      name: "Abhishek Soni",
      position: "Manager",
      image: testimonials1,
    },
  ];
  const CustomRight = ({ onClick }) => <></>;
  const CustomLeft = ({ onClick }) => <></>;
  const CustomDot = ({ onMove, index, onClick, active }) => <></>;
  return (
    <div className={`section-60 ${props.home && "bg-f8f8f8 pb-0"}`}>
      <div className={`container ${props.home && "bg-f8f8f8"}`}>
        <div class="section-title">WHAT CLIENT SAYS</div>
        <Carousel
          responsive={responsive}
          itemClass="carousel-item-padding"
          ssr={true}
          infinite={true}
          showDots={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          // slidesToSlide={1}
          customRightArrow={<CustomRight />}
          customLeftArrow={<CustomLeft />}
          customDot={<CustomDot />}
        >
          {review.map((r) => {
            return (
              <div class="owl-item p-2">
                <div class="single-feedback-item">
                  <div class="feedback-desc">
                    <p className="text-justify">{r.review}</p>
                  </div>

                  <div class="client-info pl-0">
                    {/* <img src={r.image} alt="image" /> */}
                    <h3>{r.name}</h3>
                    {/* <span>{r.position}</span> */}
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
export default Testimonials;
