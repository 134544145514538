import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";
import img1 from "../Assets/img/Loan/edu1.png";
import img2 from "../Assets/img/Loan/edu2.png";
import { educationLoanData } from "../Components/loanDetailsData";

function EducationLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = [
    "Overview",
    "Features",
    "Eligibility & Documents",
    "Interest rates & Charges",
    "EMI Calculator",
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Education Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...educationLoanData,
    img1: img1,
    img2: img2,
    desc: "An education loan, also known as a student loan, is a financial product designed to help individuals fund their education expenses, including tuition fees, books, and living costs. These loans are typically offered by government agencies or private lenders. Education loans often come with favorable terms, such as deferred repayment options or income-based repayment plans, to accommodate students' financial circumstances. Interest rates on education loans may be fixed or variable, and repayment typically begins after the borrower completes their education or drops below a certain enrollment status. The availability and terms of education loans vary depending on the lender and the borrower's financial situation.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default EducationLoan;
