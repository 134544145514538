import { useEffect } from "react";
import BreadCrumbs from "../Components/breadcrumbs";

function Terms() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Terms & Conditions",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="container">
        <div className="section-60 text-justify">
          <b>
            <center>GENERAL TERMS OF USE</center>
          </b>
          <br />
          <br />
          <b>1. Agreement and Acceptance:</b>
          <br />
          <br />
          1.1. These General Terms of Use, inclusive of the Terms of Service,
          along with any agreements accepted or executed by you regarding the
          Services ("Terms"), are applicable to your (including your nominee or
          any authorized person acting on your behalf or co-applicant, if any)
          access or usage of any financial products or services provided by RV
          Capital ("we", "us", or "our") and/or any other financial institution
          partner of RV Capital or co-lender(s) of RV Capital ("Financial
          Partner") through the Portal, including its associated Android and iOS
          mobile applications, mobile site, or any other medium, including the
          call-center facility, for availing the Services.
          <br />
          <br />
          1.2. These Terms must be read in conjunction with RV Capital's Privacy
          Policy, provided separately and available at
          https://rvcapitalfinance.com/policy ("Privacy Policy"), including any
          updates and amendments thereto.
          <br />
          <br />
          1.3. Moshpit Technologies Private Limited ("FinBox") is the technology
          partner to RV Capital, operating the Portal on which Services are
          provided and made available by RV Capital and/or its Financial
          Partners. FinBox also acts as the lending service provider for RV
          Capital and/or its Financial Partners.
          <br />
          <br />
          1.4. Before accessing the Portal or availing the Services, carefully
          read these Terms. By using the Portal or availing the Services, you
          acknowledge that you have read and understood these Terms and agree to
          be bound by them without limitation or qualification. If you disagree
          with these Terms, please refrain from accessing the Portal or availing
          the Services.
          <br />
          <br />
          1.5. These Terms constitute an electronic record under the Information
          Technology Act 2000, generated by a computer system and do not require
          physical or digital signatures.
          <br />
          <br />
          1.6. Information and data available on the Portal or communicated to
          you regarding the Services do not constitute an offer to buy or sell
          any services in any jurisdiction other than India.
          <br />
          <br />
          1.7. The headings in these Terms are for reference only. Capitalized
          terms not defined herein shall have the meanings ascribed to them in
          the Privacy Policy.
          <br />
          <br />
          <b>2. Representations, Warranties, and Undertakings by You:</b>
          <br />
          <br />
          2.1. You confirm that you are above 18 years of age, an Indian citizen
          resident in India, and competent to agree to these Terms.
          <br />
          <br />
          2.2. These Terms create valid and legally binding obligations on you
          and are enforceable against you.
          <br />
          <br />
          2.3. You declare that there have been no insolvency proceedings
          against you, nor have you ever been adjudicated insolvent by any court
          or authority.
          <br />
          <br />
          2.4. You are not a Politically Exposed Person as defined by the KYC
          Directions issued by the RBI.
          <br />
          <br />
          2.5. You are not prohibited by any legal, regulatory, judicial, or
          other authority from accessing the Portal or availing the Services,
          including engaging in transactions contemplated using the Services,
          and agree to use the Services only for lawful purposes.
          <br />
          <br />
          <b>3. Your Information:</b>
          <br />
          <br />
          3.1. By using the Portal or Services, you authorize RV Capital,
          directly or indirectly through third parties, to collect and process
          information about you in accordance with our Privacy Policy. Further,
          you explicitly authorize RV Capital to share your Information with
          Financing Partners for enabling the requested Services.
          <br />
          <br />
          3.2. We may request Information or documents to reasonably identify
          you, and reserve the right to terminate or limit access to the
          Services if such Information cannot be obtained or verified.
          <br />
          <br />
          3.3. You agree to provide only true, accurate, current, and complete
          Information to RV Capital and its Financial Partners and understand
          that you are solely responsible for the accuracy of the Information
          provided.
          <br />
          <br />
          3.4. You agree to keep your Information up-to-date and accurate and
          inform RV Capital and its Financial Partners of any changes thereto.
          <br />
          <br />
          3.5. In case of updates to submitted documents, you shall provide the
          updated documents to RV Capital within 30 days.
          <br />
          <br />
          3.6. By availing the Services, you consent to RV Capital validating
          your identity and processing your Information in accordance with our
          Privacy Policy.
          <br />
          <br />
          3.7. Failure to provide requested Information or revoke necessary
          permissions may result in limited access to the Portal or Services.
          <br />
          <br />
          3.8. RV Capital reserves the right to close, suspend, or limit access
          to your Account if unable to obtain or verify any Information.
          <br />
          <br />
          3.9. With your consent, RV Capital may request third parties to
          provide Information to personalize your experience or provide
          customized services on the Portal.
          <br />
          <br />
          <b>4. Fees and Charges.</b>
          <br />
          <br />
          4.1. By accessing or using the Portal, you may incur non-refundable
          service charges or fees, the specifics of which will be communicated
          to you within the Portal.
          <br />
          <br />
          4.2. You are solely responsible for any fees or charges levied by your
          phone/telecom service provider, such as fees for SMS or data services.
          <br />
          <br />
          <b>5. Communication and Notification.</b>
          <br />
          <br />
          5.1. You consent to RV Capital, its affiliates, or third parties
          communicating with you regarding the Portal or the Services, whether
          electronically (e.g., push notifications), via phone calls, WhatsApp
          messenger application, email, or in writing through other means.
          <br />
          <br />
          5.2. RV Capital reserves the right to close your Account and demand
          immediate payment of all outstanding amounts if you withdraw your
          communication consent or revoke access to any third-party site relied
          upon by RV Capital or the Service, or if any such service revokes or
          cancels Your Account on that site.
          <br />
          <br />
          5.3. Electronic communications will be deemed received by you within
          24 hours of being sent (e.g., via email, SMS, online notification,
          WhatsApp messenger application). Postal mail communications will be
          considered received 3 business days after being sent.
          <br />
          <br />
          5.4. By using the Services, you expressly consent to us and our
          authorized assigns and agents communicating with you via:
          <br />
          <br />
          (a) any mobile or landline number you provide;
          <br />
          (b) any email address associated with your Account;
          <br />
          (c) automated dialer systems and automatic telephone dialing systems;
          <br />
          (d) pre-recorded or artificial voice messages and other communication
          forms.
          <br />
          <br />
          5.5. The accuracy, readability, and promptness of communications
          depend on various factors. RV Capital is not responsible for
          non-delivery, delayed delivery, or distortion of communications.
          <br />
          <br />
          5.6. You agree to share your name, mobile number, email ID, PAN,
          and/or other information with RV Capital, Financial Partner, their
          group companies, and/or agents for providing information on products,
          offers, and services via various modes.
          <br />
          <br />
          <b>6. Do Not Disturb (DND).</b>
          <br />
          <br />
          6.1. You express your consent to receive telephonic calls, WhatsApp
          messages, SMS, or other communications regarding offers, loan
          promotions, or services from RV Capital, its employees, associates,
          third-party agents, and authorized service providers.
          <br />
          <br />
          6.2. You acknowledge that laws regarding unsolicited communications
          under the National Do Not Call Registry (NDNC Registry) will not apply
          to communications from RV Capital and its associates. You waive any
          preferences set under the NDNC Registry.
          <br />
          <br />
          6.3. If you wish to opt out of promotional messages or communications,
          contact us at customercare@rvcapitalfinance.com.
          <br />
          <br />
          <b>7. Access to Your Account.</b>
          <br />
          <br />
          7.1. Registration of an account on the Portal is necessary to utilize
          its features and access the Services ("Your Account"). You must
          maintain the accuracy of Your Account information.
          <br />
          <br />
          7.2. Safeguard the confidentiality and security of your login
          credentials for Your Account and control activities within it to
          prevent unauthorized access.
          <br />
          <br />
          7.3. You are responsible for all activities within Your Account and
          must implement security measures to protect your credentials.
          <br />
          <br />
          7.4. Inform us immediately if you suspect unauthorized use of your
          login credentials.
          <br />
          <br />
          7.5. RV Capital is not liable for security breaches or unauthorized
          Account usage.
          <br />
          <br />
          <b>8. Accessing the Portal:</b>
          <br />
          <br />
          8.1. The content provided on the Portal is solely for informational
          purposes. RV Capital does not guarantee its completeness, timeliness,
          or accuracy. The Information and materials on the Portal and in these
          Terms are subject to change at RV Capital's sole discretion, without
          prior notice or liability to you.
          <br />
          <br />
          8.2. RV Capital will strive to ensure that the Services are available
          24/7, except for planned downtime (of which RV Capital will attempt to
          provide at least 4 hours' notice) or any unavailability due to
          circumstances beyond RV Capital’s reasonable control.
          <br />
          <br />
          8.3. While RV Capital endeavors to maintain uninterrupted access to
          the Portal and Services, it assumes no liability for any monetary or
          other damage or loss resulting from delays, failures, interruptions,
          or errors in Portal operation.
          <br />
          <br />
          8.4. Customer support for the Portal or Services will be available
          from 09:00 a.m. to 07:00 p.m. (Monday to Saturday, excluding Sundays &
          public holidays).
          <br />
          <br />
          8.5. If you access the Portal from locations outside India, you do so
          at your own risk and are solely responsible for complying with local
          laws and regulations. The availability of the Portal through the
          internet in countries other than India does not imply that the laws of
          those countries govern these Terms or Portal usage. RV Capital bears
          no liability for non-compliance with laws of any country other than
          India.
          <br />
          <br />
          8.6. Access to the Portal or Services may be unavailable, delayed,
          limited, or slowed due to internet-related factors and other reasons
          beyond RV Capital's control, including but not limited to:
          <br />
          <br />
          (a) server, network, hardware failure (including your own computer or
          mobile), telecommunications issues, and other electronic or mechanical
          equipment failures;
          <br />
          (b) software issues such as bugs, errors, viruses, or incompatibility;
          <br />
          (c) system overload;
          <br />
          (d) natural disasters or other unforeseen events;
          <br />
          (e) utility service interruptions;
          <br />
          (f) governmental restrictions or interventions;
          <br />
          (g) any other causes beyond RV Capital's control.
          <br />
          <br />
          <b>9. Links to External Websites:</b>
          <br />
          <br />
          9.1. Links to websites, applications, services, or interfaces other
          than the Portal are provided for informational purposes related to the
          Services. RV Capital does not control the content of such third-party
          websites.
          <br />
          <br />
          9.2. RV Capital makes no warranties regarding the content of these
          external websites or applications, including their accuracy,
          completeness, or reliability, nor does it guarantee freedom from
          copyright, trademark, or other infringements or viruses.
          <br />
          <br />
          9.3. You visit third-party websites through the Portal at your own
          risk and are subject to applicable laws and regulations. Once
          redirected, the terms of use and privacy policies of those websites
          apply to you.
          <br />
          <br />
          9.4. Links to external websites do not imply endorsement or
          responsibility for the content, opinions, products, or services
          offered on those sites.
          <br />
          <br />
          <b>10. Conduct on the Portal:</b>
          <br />
          <br />
          10.1. By using the Portal or Services, you agree to:
          <br />
          <br />
          (a) Use the Portal for lawful purposes without infringing on
          third-party rights;
          <br />
          (b) Refrain from engaging in unlawful or immoral activities or acts
          contrary to applicable laws or the Terms;
          <br />
          (c) Not solicit others to engage in unlawful acts;
          <br />
          (d) Provide accurate Information;
          <br />
          (e) Not use others' Information without authorization;
          <br />
          (f) Not attempt to collect personal information of others;
          <br />
          (g) Not access others' accounts;
          <br />
          (h) Not create multiple fake accounts;
          <br />
          (i) Not use malicious software or techniques to interfere with Portal
          operation or others' use;
          <br />
          (j) Not violate these Terms or any other agreements;
          <br />
          (k) Not engage in unauthorized use of the Portal;
          <br />
          (l) Not overload the Portal infrastructure;
          <br />
          (m) Not modify or reverse engineer any part of the Portal.
          <br />
          <br />
          10.2. You agree not to upload, display, or transmit any Information
          that:
          <br />
          <br />
          (a) Does not belong to you;
          <br />
          (b) Is harmful, offensive, defamatory, obscene, or unlawful;
          <br />
          (c) Infringes on intellectual property rights;
          <br />
          (d) Harms minors;
          <br />
          (e) Contains commercial content;
          <br />
          (f) Misleads or deceives;
          <br />
          (g) Impersonates others;
          <br />
          (h) Contains viruses or harmful code;
          <br />
          (i) Threatens national security or public order;
          <br />
          (j) Violates Indian laws.
          <br />
          <br />
          10.3. RV Capital may remove any Information violating these Terms or
          applicable laws.
          <br />
          <br />
          <b>11. Ownership of Intellectual Property:</b>
          <br />
          <br />
          11.1. Portal: All rights, title, and interest in the Portal, including
          any materials provided on it, along with all modifications and
          enhancements, and any feedback provided by you regarding the Portal,
          including Intellectual Property Rights, belong solely to RV Capital
          and its licensors and providers.
          <br />
          <br />
          11.2. Feedback: Any evaluations, ideas, feedback, or suggestions made
          by you regarding the Portal ("Feedback") and the associated
          Intellectual Property Rights are irrevocably assigned to RV Capital.
          In cases where assignment is prohibited by law, you grant RV Capital
          an exclusive license to use the Feedback.
          <br />
          <br />
          11.3. Customer Data: The rights, title, and interest in Customer
          Information, including Information and Other Information inputted into
          the Portal, belong solely to you. You grant RV Capital a limited
          license to use Customer Information as necessary for the provision of
          Services.
          <br />
          <br />
          11.4. Aggregated Statistics: RV Capital may monitor and use data
          related to Customer's use of the Portal and Services in an aggregated
          and anonymous manner to compile statistical information. All rights to
          Aggregated Statistics belong to RV Capital.
          <br />
          <br />
          <b>12. Indemnity:</b>
          <br />
          <br />
          12.1. You agree to defend, indemnify, and hold harmless RV Capital and
          its affiliates, officers, directors, agents, and employees from any
          claims, damages, losses, or expenses arising from your use of the
          Portal or Services, including claims from third parties, improper
          information provided by you, or breaches of the Terms.
          <br />
          <br />
          12.2. RV Capital reserves the right to assume control of the defence
          of any matter for which you are required to indemnify, and you agree
          to cooperate with RV Capital in such cases. RV Capital will make
          reasonable efforts to notify you of any claims brought by third
          parties.
          <br />
          <br />
          <b>13. RV Capital’s Disclaimers and Limitation of Liabilities:</b>
          <br />
          <br />
          13.1. You agree to carefully read and strictly comply with RV
          Capital's disclaimers.
          <br />
          <br />
          13.2. Your usage of the Portal is solely at your own risk. The Portal
          is provided on an "as is" and "as available" basis.
          <br />
          <br />
          13.3. You acknowledge that RV Capital, its affiliates, and their
          respective directors, officers, employees, agents, and representatives
          will not be liable for any direct, indirect, incidental, special,
          consequential, or exemplary damages, including damages for loss of
          profits, goodwill, use, data, or other intangible losses, regardless
          of whether such damages were foreseeable, arising under, relating to,
          or resulting from:
          <br />
          <br />
          (a) Your access to the Portal and use of the Services;
          <br />
          (b) Your violation of the Terms (including the Privacy Policy);
          <br />
          (c) Your violation of any third-party rights, including Intellectual
          Property Rights;
          <br />
          (d) Your use or inability to use the Portal or Service;
          <br />
          (e) Claims from third parties resulting from or connected with your
          use of the Portal or the Services; or
          <br />
          (f) Any data, information, or Services purchased or obtained, or
          messages received or transactions entered into through the Portal.
          <br />
          <br />
          13.4. RV Capital does not warrant that:
          <br />
          <br />
          (a) The Portal or Services will meet your requirements;
          <br />
          (b) The Portal will be uninterrupted, timely, secure, or error-free;
          <br />
          (c) Results obtained from the use of the Portal will be accurate or
          reliable; or
          <br />
          (d) The quality of Services or Information obtained through the Portal
          will meet your expectations.
          <br />
          <br />
          13.5. While RV Capital employs security measures for its Services, it
          does not guarantee that no unauthorized person will gain access to
          Your Account. RV Capital will not be liable for any unauthorized
          access to Your Account.
          <br />
          <br />
          13.6. RV Capital will not be liable for any loss or damage arising
          directly or indirectly from interruption or stoppage of your access to
          or use of the Portal or Services, hacking, or unauthorized access to
          the Portal or Services.
          <br />
          <br />
          13.7. Any material or Information obtained through the Portal or
          Services is at your own risk. RV Capital will not be liable for any
          damage to your computer system or loss of data resulting from the
          download of such material.
          <br />
          <br />
          13.8. RV Capital's sole obligation in the event of interruption in
          Services or loss of access to Services will be to restore the
          Services.
          <br />
          <br />
          13.9. RV Capital’s obligations under these Terms are subject to
          certain limitations:
          <br />
          <br />
          (a) Messages originating from you are deemed authorized, and RV
          Capital will not be liable for processing such messages;
          <br />
          (b) RV Capital is not responsible for the security of data on
          third-party servers or on the servers of a Customer;
          <br />
          (c) RV Capital will have no liability for failure or delay in
          performing its obligations under these Terms due to circumstances
          beyond its control.
          <br />
          <br />
          13.10. RV Capital disclaims all express, statutory, and implied
          warranties, including warranties of merchantability, fitness for a
          particular purpose, non-infringement, title, quality, and accuracy.
          <br />
          <br />
          <b>14. Miscellaneous.</b>
          <br />
          <br />
          14.1. Governing Law and Dispute Resolution:
          <br />
          <br />
          (a) These Terms will be governed by and interpreted in accordance with
          Indian laws.
          <br />
          (b) In the event of a dispute between you and RV Capital, our aim is
          to provide a neutral and cost-effective means of resolving the dispute
          swiftly. If a dispute arises, we encourage you to first contact RV
          Capital at customercare@rvcapitalfinance.com to attempt to resolve the
          issue directly with us.
          <br />
          (c) If parties cannot resolve a dispute amicably, the dispute will be
          subject to the exclusive jurisdiction of the courts in Pune,
          Maharashtra, India.
          <br />
          <br />
          14.2. Changes to these Terms: RV Capital reserves the right to modify
          these Terms periodically. In the event of material changes, RV Capital
          will make reasonable efforts to notify you, but it is your
          responsibility to review the Terms on the Portal regularly for any
          updates. The revised Terms will replace the current version and will
          be effective immediately upon posting on the Portal. Your continued
          use of the Portal after being notified of modifications to the Terms
          or after they are updated on the Portal will constitute acceptance of
          the modified Terms.
          <br />
          <br />
          14.3. Transferability: These Terms, or any rights or interests herein,
          may not be assigned or transferred by you without prior written
          consent from RV Capital. RV Capital reserves the right to freely
          assign its rights and obligations under these Terms to any affiliates,
          or in connection with a merger, acquisition, sale of assets, or by
          operation of law or otherwise.
          <br />
          <br />
          14.4. Entire Agreement: These Terms constitute the entire agreement
          between RV Capital and you regarding the subject matter herein and
          supersede any prior agreements.
          <br />
          <br />
          14.5. Severability: If any portion of these Terms is deemed
          unenforceable, the remaining portions will remain in full force and
          effect.
          <br />
          <br />
          14.6. No Waiver: Failure by RV Capital to enforce any part of these
          Terms does not constitute a waiver.
          <br />
          <br />
          14.7. Conflict Resolution: In the event of any conflict between the
          General Terms of Use and the Terms of Service, the Terms of Service
          will prevail. Similarly, in case of any conflict between these Terms
          and the Loan Terms, the Loan Terms will prevail.
          <br />
          <br />
          <br />
          <b>
            <center>
              TERMS OF SERVICE: LOANS AND FINANCING FACILITY BY LENDER(S)
            </center>
          </b>
          <br />
          <br />
          <b>1. Terms</b>
          <br />
          <br />
          1.1. Alongside the General Terms of Use, the Terms of Service, and any
          other agreements or documents accepted by you, the Terms apply to you,
          including your nominee or any person acting on your behalf, or your
          co-applicant (if any), for availing the Services from RV Capital.
          <br />
          <br />
          <b>2. Services</b>
          <br />
          <br />
          2.1. RV Capital and/or its Financial Partner may offer the following
          facilities and services to you via the Portal ("Services"):
          <br />
          <br />
          (a) Gathering necessary information and documents for your KYC and
          underwriting, assessing your creditworthiness for availing a credit
          facility or loan from us and/or Financial Partners;
          <br />
          (b) Approving a credit facility or loan ("Loan") to you, either
          through us and/or our Financial Partners as per the terms of the Loan
          documents ("Loan Terms");
          <br />
          (c) Transferring the sanctioned Loan amount to your designated bank
          account, in accordance with these Terms and the Loan Terms;
          <br />
          (d) Providing details of the availed Loan along with repayments
          (including principal and interest) on the Portal;
          <br />
          (e) Offering methods (including electronic payment methods) for Loan
          repayment;
          <br />
          (f) Engaging in Loan repayment collection as per applicable laws or
          the Loan Terms;
          <br />
          (g) Providing customer support related to the Services, including
          facilitating grievances, complaints, or feedback;
          <br />
          (h) Offering ancillary or support services to enable you to avail the
          Services or related to the Loan.
          <br />
          <br />
          2.2. Some Services may be facilitated or executed by us through our
          lending service provider/s.
          <br />
          <br />
          2.3. RV Capital and its Financial Partners reserve the right to
          modify, add new, or remove existing functionalities, processes, or
          mechanisms related to the Services at their sole discretion.
          <br />
          <br />
          2.4. RV Capital and its Financial Partners will provide the Services,
          including Loans through the Portal, in compliance with applicable
          laws, including the RBI's Guidelines on Digital Lending.
          <br />
          <br />
          2.5. Loan details and other relevant information will be accessible on
          the Portal, and you are advised to regularly check for updates.
          <br />
          <br />
          <b>3. Fees</b>
          <br />
          <br />
          3.1. Utilization of the Services may incur non-refundable service
          charges or fees, details of which will be communicated through the
          Loan Terms on the Portal.
          <br />
          <br />
          3.2. Additional charges may apply based on your chosen repayment
          method, with prior notification before repayment.
          <br />
          <br />
          3.3. You are liable for any fees charged by your phone service
          provider, including fees for SMS, call services, data services, and
          any other applicable charges.
          <br />
          <br />
          3.4. All fees and charges associated with the Loan will be disclosed
          under the KFS (defined below).
          <br />
          <br />
          <b>4. Submission of Information and Documents</b>
          <br />
          <br />
          4.1. As a prerequisite for availing our Services, including obtaining
          a Loan, you agree to undergo mandatory KYC verification in accordance
          with applicable laws, specifically the KYC Directions issued by the
          RBI.
          <br />
          <br />
          4.2. During registration or loan application, it's imperative for RV
          Capital and its Financial Partners to verify the identity of the
          applicant Customer to prevent fraudulent activities. You'll be
          required to submit information, details, and documents matching your
          profile in Your Account.
          <br />
          <br />
          4.3. You'll select the applicable Profile (e.g., salaried, student,
          self-employed) unless already available in Your Account. Your Profile
          details will be updated accordingly.
          <br />
          <br />
          4.4. We'll initiate identity and KYC verification processes, which may
          differ from the information provided under Your Profile.
          <br />
          <br />
          4.5. If you opt for offline KYC verification (OKYC), you voluntarily
          consent to share/obtain your XML file containing information with RV
          Capital and its Financial Partners for identity verification purposes
          as required by applicable law.
          <br />
          <br />
          (a) Your XML file will be used solely for KYC identification,
          verification, and due diligence purposes.
          <br />
          (b) You agree to share the share code or confirm the auto-populated
          share code for successful XML file download/upload as per applicable
          law.
          <br />
          <br />
          You acknowledge that the KYC verification mode records may be used by
          RV Capital for evidentiary purposes.
          <br />
          <br />
          4.6. You're required to click a selfie for your loan application,
          which will be uploaded via the Portal, granting access to your
          device's camera.
          <br />
          <br />
          4.7. For KYC purposes, you expressly consent to RV Capital for (a)
          collecting, storing, and using your Aadhaar number; (b)
          verifying/authenticating your Aadhaar number; and (c) updating your
          Aadhaar number(s) in compliance with the Aadhaar Act, 2016. Your
          Aadhaar number won't be disclosed to third parties without your
          express consent unless necessary under applicable law.
          <br />
          <br />
          4.8. You must digitally accept the Terms and Loan Terms by clicking "I
          agree" on the Portal.
          <br />
          <br />
          4.9. You agree to submit necessary documents/information and
          accept/agree to any required documents/terms (including Loan Terms) as
          requested by RV Capital and Financial Partners.
          <br />
          <br />
          4.10. RV Capital and Financial Partners reserve the right to determine
          the completeness of your loan application and accept/reject it without
          notice or liability.
          <br />
          <br />
          4.11. RV Capital and Financial Partners may request additional
          information/documents at their discretion.
          <br />
          <br />
          <b>5. Verification</b>
          <br />
          <br />
          5.1. RV Capital and Financial Partners will verify, assess, and
          examine the information and documents as per applicable laws,
          including KYC Directions.
          <br />
          <br />
          5.2. You authorize RV Capital and/or Financial Partners to verify your
          KYC details from the Central Know Your Customer Records Registry
          (CKYC-R) and declare that your CKYC data is updated and unchanged.
          <br />
          <br />
          5.3. Upon completion of verification, RV Capital and/or its Financial
          Partner will estimate your potential borrowing amount ("Purchase
          Power"), which is subject to real-time underwriting and isn't a
          guaranteed credit limit.
          <br />
          <br />
          5.4. RV Capital and its Financial Partners may modify verification
          processes without notice or liability.
          <br />
          <br />
          5.5. You authorize RV Capital to share your details with Financing
          Partners for assessment/verification.
          <br />
          <br />
          <b>6. Disbursal of Loan</b>
          <br />
          <br />
          6.1. Upon approval, you can avail/drawdown the Loan according to
          notified terms.
          <br />
          <br />
          6.2. Post-approval, the loan amount will be disbursed to your Indian
          bank account as provided.
          <br />
          <br />
          6.3. Any erroneous funds received must be reported immediately to
          customercare@rvcapitalfinance.com, and you must return the funds as
          directed by RV Capital/Lenders.
          <br />
          <br />
          <b>7. Key Fact Statement and disclosures.</b>
          <br />
          <br />
          7.1. We ensure that all essential information about the loan,
          including its terms, details, and the all-inclusive cost (Annual
          Percentage Rate), is available to you through the Key Fact Statement
          ("KFS"), if applicable, on the Portal. This includes details about the
          recovery mechanism, cooling-off / look-up period, and other necessary
          information.
          <br />
          <br />
          7.2. Should you identify any discrepancies in the KFS, please promptly
          report them to us along with relevant details and supporting documents
          for our review and necessary action.
          <br />
          <br />
          <b>8. Credit investigation and reporting.</b>
          <br />
          <br />
          8.1. By explicitly consenting, you authorize RV Capital and/or
          Financial Partner as your representative to obtain your credit
          information report, including Credit Score, from authorized agencies
          such as CRIF High Mark, TransUnion CIBIL Limited, Experian Credit
          Information Company of India Private Limited, Equifax Credit
          Information Services Private Limited, and other relevant credit
          information companies, in accordance with applicable laws.
          <br />
          <br />
          8.2. We may obtain and share your Credit Information with Financial
          Partners for purposes including evaluating your creditworthiness,
          determining loan amounts, opening accounts, or other related
          assessments.
          <br />
          <br />
          8.3. The Credit Information obtained will be used solely for the
          purposes mentioned above and in compliance with relevant regulations.
          It will not be shared, retained, or used for any other purposes beyond
          those agreed upon.
          <br />
          <br />
          8.4. You acknowledge and consent to RV Capital making soft inquiries
          from Credit Information Companies as needed.
          <br />
          <br />
          8.5. You agree not to hold credit information companies or RV Capital
          liable for any issues related to your Credit Information.
          <br />
          <br />
          8.6. You have the option to opt-out of RV Capital and/or Financial
          Partner seeking your Credit Information. However, opting out may
          affect the loan sanctioning or service provision.
          <br />
          <br />
          <b>9. Repayment and foreclosure.</b>
          <br />
          <br />
          9.1. We provide various electronic payment methods on the Portal for
          loan repayment, facilitated through third-party arrangements. RV
          Capital is not liable for any issues arising from these payment
          instruments.
          <br />
          <br />
          9.2. RV Capital is not responsible for payment failures, fraud, or
          telecommunication network issues during payment processing.
          <br />
          <br />
          9.3. In case of repayment failures, RV Capital may engage in
          collection or recovery efforts, directly or through authorized agents,
          without prejudice to other legal remedies available under applicable
          laws.
          <br />
          <br />
          9.4. You authorize RV Capital to send you payment reminders.
          <br />
          <br />
          9.5. Foreclosure terms will be available on the Portal or in the Loan
          Terms.
          <br />
          <br />
          <b>10. Additional indemnity.</b>
          <br />
          <br />
          10.1. Alongside your indemnity under the General Terms of Use, you
          agree to defend, indemnify, and hold harmless RV Capital, its
          affiliates, licensees, officers, directors, agents, and employees from
          claims arising from loan term violations, repayment delays, or
          incorrect/fraudulent information provided by you.
          <br />
          <br />
          10.2. All other indemnity terms under the General Terms of Use apply
          accordingly.
          <br />
          <br />
          <b>11. Additional commitments:</b>
          <br />
          <br />
          11.1. By agreeing to these terms, you confirm that your annual
          household income, which includes the combined annual income of your
          family unit (i.e., spouse and unmarried children), exceeds Rs.
          3,00,000/- (Rupees Three Lakhs Only). Therefore, the loan you are
          applying for under the current facility does not qualify as a
          Microfinance Loan under the Reserve Bank of India's (RBI) Regulatory
          Framework for Microfinance Loans Directions, 2022. You further affirm
          that the information provided is true, accurate, and complete, and you
          have not withheld any relevant information.
          <br />
          <br />
          11.2. You acknowledge and agree that the loan amount will be used
          solely for legitimate purposes, and any repayments towards the loan
          will be made from legitimate sources only. You also confirm that your
          transactions with RV Capital are not intended to contravene or violate
          any applicable laws or evade any legal requirements, including
          anti-money laundering laws.
          <br />
          <br />
          11.3. You release RV Capital, its affiliates, licensees, officers,
          directors, agents, and employees from any responsibility or liability
          for any loss, damage, claim, demand, or liability arising from: (i)
          any unauthorized use of the loan provided by RV Capital; (ii) RV
          Capital's good faith actions based on instructions received from you
          or believed to be from you; or (iii) errors, delays, defaults, or
          inability of RV Capital to act on your instructions, including loan
          disbursement.
          <br />
          <br />
          11.4. RV Capital may obtain additional information about you, such as
          data to aid in fraud detection and safety measures, from third-party
          service providers or partners.
          <br />
          <br />
          <b>12. Grievance resolution:</b>
          <br />
          <br />
          12.1. Any complaints, disputes, or grievances related to the Services
          or the loan should be directed to RV Capital as per the company's
          Grievance Redressal Policy.
          <br />
          <br />
          We will address and resolve such complaints, disputes, or grievances
          as promptly as possible, in accordance with our Grievance Redressal
          Policy and applicable laws.
          <br />
          <br />
        </div>
      </div>
    </>
  );
}
export default Terms;
