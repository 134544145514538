import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import banner1 from "../Assets/img/Banner/new/Bitcoin.gif";
import banner2 from "../Assets/img/Banner/new/Business growth.gif";
import banner3 from "../Assets/img/Banner/new/Building permit.gif";
import banner4 from "../Assets/img/Banner/new/Car finance (1).gif";
import LoanOptions from "../Components/loanOptions";
import About from "../Components/about";
import Process from "../Components/process";
import WhyChooseUs from "../Components/whyChooseUs";
import EMICalc from "../Components/emiCalc";
import Testimonials from "../Components/testimonials";
import Partner from "../Components/partner";
import InsuranceOptions from "../Components/insuranceOptions";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import CCOptions from "../Components/ccOptions";
import InstantLoanOptions from "../Components/instantLoanOptions";
import LoanFromBankComponent from "../Components/loanFromBank";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const responsiveBanner = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 992, min: 678 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 678, min: 0 },
      items: 1,
    },
  };
  const CustomRight = ({ onClick }) => <></>;
  const CustomLeft = ({ onClick }) => <></>;

  const slide = [
    // {
    //   image: banner1,
    //   text: "INSTANT LOAN",
    //   desc: "Instant Cash, Instant Relief: Your Quick Solution for Financial Ease!",
    //   link: "/apply-now/Instant",
    // },
    {
      image: banner1,
      text: "LOAN FROM BANK",
      desc: "Fast, Flexible and Reliable: Empowering Your Dreams with Every Step!",
      link: "/loan-from-bank",
    },
    {
      image: banner2,
      text: "BUSINESS LOAN",
      desc: "Empowering Growth: Fueling Your Business Dreams with Every Loan.",
      link: "/apply-now/Business",
    },
    {
      image: banner3,
      text: "HOME LOAN",
      desc: "Unlocking Doors to Your Dream Home: Where Every Loan Finds its Foundation.",
      link: "/apply-now/Home",
    },
    {
      image: banner4,
      text: "CAR LOAN",
      desc: "Drive Your Dreams: Accelerate into Ownership with Our Car Loans!",
      link: "/apply-now/Car",
    },
  ];

  return (
    <>
      <Carousel
        responsive={responsiveBanner}
        itemClass="carousel-item-padding"
        ssr={true}
        infinite={true}
        showDots={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        slidesToSlide={1}
        customRightArrow={<CustomRight />}
        customLeftArrow={<CustomLeft />}
      >
        {slide.map((s) => {
          return (
            <>
              <div className="container">
                {" "}
                {/* style={{ backgroundImage: "linear-gradient(to right, #002d5b , #fff)" }} */}
                <div className="row">
                  <div className="col-md-6 col-12 order-md-1 order-2">
                    <div
                      className="d-flex align-items-center justify-content-banner h-100"
                      style={{ padding: "30px 0" }}
                    >
                      <div>
                        <p className="d-none d-sm-block">{s.text}</p>
                        <h2 className="mb-30 d-none d-sm-block">{s.desc}</h2>
                        <Link to={s.link}>
                          <button className="default-btn">Apply Now</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 order-md-2 order-1 p-2">
                    <div className="d-flex justify-content-center align-items-center">
                      <img class="banner-img" src={s.image} alt="Your Image" />
                      {/* <video width="320" height="240" autoplay loop>
                                            <source src={s.image} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        {/* {banner.map((b) => {
                    return <div class="banner-container position-relative">
                        <img src={b.image} alt="Your Image" />
                        <div class="banner-gradient-overlay"></div>
                        <div className="banner-text">
                            <h3 className="text-white">Empower Your Finances!</h3>
                            <p className="text-white d-none d-lg-block">Unlock Your Financial Potential: Explore Flexible Loans Today!</p>
                            <button className="default-btn">Apply Now</button>
                        </div>
                    </div>
                })} */}
      </Carousel>

      <div className="section-60 pb-0 bg-f8f8f8">
        <div className="container">
          <div class="section-title">Explore Instant Personal Loan Options</div>
          <div
            className="sub-title text-center"
            style={{ marginBottom: "-30px" }}
          >
            An instant loan is a type of personal loan offered by various banks
            that provides quick access to funds without extensive paperwork or
            long approval processes. Typically, instant loans are designed for
            urgent financial needs and can be availed within minutes or hours,
            depending on the bank’s processing time.
          </div>
          <LoanFromBankComponent />
        </div>
      </div>
      <WhyChooseUs />
      <LoanOptions />
      <CCOptions />
      <InsuranceOptions />
      <Process home />
      <EMICalc />
      <About /> {/** home */}
      <Testimonials home />
      <Partner />
    </>
  );
}

export default Home;
