import { professionalDetails } from "../api/customerAuth";
import { getCustomer } from "../api/customer";
import { useEffect, useState } from "react";
import {
  validateEmail,
  validatePin,
  validateSalary,
  validateSelect,
  validateText,
} from "../Common/validations";
import { getLocalStorage } from "../Common/cookie";
import { getLocation } from "../api/location";
import { useNavigate } from "react-router-dom";

function ProfileProfessional(props) {
  const { msg, onSubmit, logindata, profile } = props;
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate()

  function onChangeData(e) {
    if (e.target.name === "company_pincode") {
      if (isNaN(e.target.value)) {
        return false;
      } else {
        setLocation(e.target.value);
      }
    }
    if (e.target.name === "income_per_month" && isNaN(e.target.value)) {
      return false;
    }
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomer({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response?.status === 200) {
          setData(response.data);
          if (response.data.company_pincode) {
            await setLocation(response.data.company_pincode);
          }
        } else {
          navigate("/")
        }
      } else {
        navigate("/")
      }
    };
    asyncFn();
  }, [logindata]);

  const setLocation = async (company_pincode) => {
    if (company_pincode && company_pincode.length === 6) {
      const location = await getLocation({
        pincode: company_pincode,
      });
      if (location[0]?.Status === "Success") {
        const lData = location[0].PostOffice[0];
        setData((data) => ({
          ...data,
          company_city: lData.District,
          company_state: lData.State,
          company_country: lData.Country,
        }));
        setErrors((errors) => ({
          ...errors,
          company_pincode: "",
        }));
      } else {
        setErrors((errors) => ({
          ...errors,
          company_pincode: "Please enter valid Pin Code",
        }));
      }
    }
  };

  function validateType(str) {
    setErrors((errors) => ({
      ...errors,
      occupation_type: validateSelect(str).error,
    }));
  }
  function validateCompanyName(str) {
    setErrors((errors) => ({
      ...errors,
      company_name: validateText(str).error,
    }));
  }
  function validateCompanyEmail(str) {
    setErrors((errors) => ({
      ...errors,
      company_email: validateText(str).error,
    }));
  }
  function validateCompanyAddress(str) {
    setErrors((errors) => ({
      ...errors,
      company_address: validateText(str).error,
    }));
  }
  function validateCompanyPincode(str) {
    setErrors((errors) => ({
      ...errors,
      company_pincode: validatePin(str).error,
    }));
  }
  function validateIncome(str) {
    setErrors((errors) => ({
      ...errors,
      income_per_month: validateSalary(str).error,
    }));
  }

  function validateForm() {
    validateType(data.occupation_type);
    validateCompanyName(data.company_name);
    validateIncome(data.income_per_month);
    if (data.occupation_type === "Salaried") {
      validateCompanyEmail(data.company_email);
      validateCompanyAddress(data.company_address);
      validateCompanyPincode(data.company_pincode);
    }
    let valid =
      validateSelect(data.occupation_type).valid &&
      validateText(data.company_name).valid &&
      validateSalary(data.income_per_month).valid &&
      (data.occupation_type === "Salaried"
        ? validateEmail(data.company_email).valid &&
          validateText(data.company_address).valid &&
          validatePin(data.company_pincode).valid
        : true);
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (!errors.company_pincode) {
      if (validateForm()) {
        const response = await professionalDetails({
          token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
          data: {
            id: data.id || getLocalStorage("rv-user")?.id,
            occupation_type: data.occupation_type,
            income_per_month: data.income_per_month,
            company_name: data.company_name,
            company_email: data.company_email,
            company_address: data.company_address,
            company_pincode: data.company_pincode,
            company_city: data.company_city,
            company_state: data.company_state,
            company_country: data.company_country,
          },
        });
        if (response?.status === 200) {
          onSubmit("success");
        }
      }
    }
  }

  return (
    <>
      <div class="form-group">
        <label className="label-login">Occupation Type: </label>
        <br />
        <input
          type="radio"
          name="occupation_type"
          value="Salaried"
          checked={data.occupation_type === "Salaried"}
          onBlur={() => validateType(data.occupation_type)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />{" "}
        Salaried
        <input
          style={{ marginLeft: "30px" }}
          type="radio"
          name="occupation_type"
          value="Non Salaried"
          checked={data.occupation_type === "Non Salaried"}
          onBlur={() => validateType(data.occupation_type)}
          onChange={(e) => {
            onChangeData(e);
          }}
        />{" "}
        Self Employed
        {errors.occupation_type && (
          <div class="help-block with-errors">{errors.occupation_type}</div>
        )}
      </div>
      {data.occupation_type === "Salaried" ? (
        <>
          <div class="form-group">
            <label className="label-login">Monthly Salary: </label>
            <input
              type="text"
              name="income_per_month"
              value={data.income_per_month}
              class="form-control w-100"
              onBlur={() => validateIncome(data.income_per_month)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.income_per_month && (
              <div class="help-block with-errors">
                {errors.income_per_month}
              </div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Organization Name: </label>
            <input
              type="text"
              name="company_name"
              value={data.company_name}
              class="form-control w-100"
              onBlur={() => validateCompanyName(data.company_name)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.company_name && (
              <div class="help-block with-errors">{errors.company_name}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Organization Email: </label>
            <input
              type="text"
              name="company_email"
              value={data.company_email}
              class="form-control w-100"
              onBlur={() => validateCompanyEmail(data.company_email)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.company_email && (
              <div class="help-block with-errors">{errors.company_email}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Organization Address: </label>
            <input
              type="text"
              name="company_address"
              value={data.company_address}
              class="form-control w-100"
              onBlur={() => validateCompanyAddress(data.company_address)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.company_address && (
              <div class="help-block with-errors">{errors.company_address}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Organization Pin Code: </label>
            <input
              type="text"
              name="company_pincode"
              value={data.company_pincode}
              class="form-control w-100"
              onBlur={() => validateCompanyPincode(data.company_pincode)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.company_pincode && (
              <div class="help-block with-errors">{errors.company_pincode}</div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Organization City: </label>
            <input
              type="text"
              name="company_city"
              class="form-control w-100"
              value={data.company_city}
              disabled
            />
          </div>
          <div class="form-group">
            <label className="label-login">Organization State: </label>
            <input
              type="text"
              name="company_state"
              class="form-control w-100"
              value={data.company_state}
              disabled
            />
          </div>
        </>
      ) : (
        <>
          <div class="form-group">
            <label className="label-login">Yearly Revenue: </label>
            <input
              type="text"
              name="income_per_month"
              value={data.income_per_month}
              class="form-control w-100"
              onBlur={() => validateIncome(data.income_per_month)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.income_per_month && (
              <div class="help-block with-errors">
                {errors.income_per_month}
              </div>
            )}
          </div>
          <div class="form-group">
            <label className="label-login">Company Name: </label>
            <input
              type="text"
              name="company_name"
              value={data.company_name}
              class="form-control w-100"
              onBlur={() => validateCompanyName(data.company_name)}
              onChange={(e) => {
                onChangeData(e);
              }}
            />
            {errors.company_name && (
              <div class="help-block with-errors">{errors.company_name}</div>
            )}
          </div>
        </>
      )}
      <button
        type="submit"
        onClick={saveData}
        class="default-btn disabled"
        style={{ pointerEvents: "all", cursor: "pointer" }}
      >
        {msg} <span></span>
      </button>
    </>
  );
}
export default ProfileProfessional;
