import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";

function IiflBusinessLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Features & Eligibility"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "IIFL Business Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    type: "IIFLBusiness",
    features: [
      "No End-Use Restrictions",
      "No Collateral Required",
      "Hassle-Free Documentation Process",
      "Easy Repayment",
      "Quick Personal Loan Disbursal",
      "Attractive Rates of Interest",
    ],
    eligibility: [
      {
        title: "Salaried Employee",
        desc: [
          "Minimum age of 23 years.",
          "Maximum age of 60 years or retirement (whichever is early) at the time of loan maturity.",
        ],
      },
      {
        title: "Self Employed",
        desc: [
          "Business existence of three years minimum",
          "Minimum age of 25 years",
          "Maximum age of 65 years at the time of loan maturity",
        ],
      },
    ],
    documents: [],
    interest: [],
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default IiflBusinessLoan;
