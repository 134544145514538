import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/cc2.png";
import img2 from "../Assets/img/Loan/cc1.png";
import { AxisCC } from "../Components/ccDetailsData";
import CCDetails from "../Components/ccDetails";

function AXISCC() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Overview", "Types", "Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "AXIS Bank Credit Card",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...AxisCC,
    img1: img1,
    img2: img2,
    desc: "Axis Bank credit cards offer a range of benefits tailored to diverse lifestyles and spending habits. With options like cashback, rewards points, and travel perks, they cater to both frequent shoppers and avid travelers. These cards often feature seamless integration with digital platforms for convenient bill payments and account management. Axis Bank's commitment to security ensures peace of mind for cardholders, with robust fraud protection measures in place. Additionally, exclusive privileges such as discounts on dining, entertainment, and shopping enhance the overall cardholder experience, making Axis Bank credit cards a popular choice among discerning consumers.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <CCDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default AXISCC;
