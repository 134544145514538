import { Link } from "react-router-dom";
import cartIcon from "../Assets/img/Loan/carIcon.png";
import lifeIcon from "../Assets/img/LoanIcon/life-insurance.png";
import healthIcon from "../Assets/img/LoanIcon/healthcare.png";
import Options from "./options";
function InsuranceOptions(props) {
  const InsuranceType = [
    {
      name: "Life Insurance",
      icon: "fas fa-heartbeat",
      path: "life-insurance",
      img: lifeIcon,
      desc: "Ensure your loved ones' financial security with our comprehensive life insurance plans.",
      knowmore: "/life-insurance",
      applyNow: "/apply-insurance/Life",
    },
    {
      name: "Health Insurance",
      icon: "fas fa-user-md",
      path: "health-insurance",
      img: healthIcon,
      desc: "Health insurance covers medical expenses and provides financial protection for healthcare.",
      knowmore: "/health-insurance",
      applyNow: "/apply-insurance/Health",
    },
    {
      name: "Car Insurance",
      icon: "fas fa-car",
      path: "car-insurance",
      img: cartIcon,
      desc: "Protect your ride and drive with peace of mind – get comprehensive car insurance today!",
      knowmore: "/car-insurance",
      applyNow: "/apply-insurance/Car",
    },
  ];

  const filteredData = InsuranceType.filter(
    (item) => !item.name.includes(props.type)
  );

  return (
    <div
      className={`section-60 ${!props.applyInsurance && "bg-f8f8f8"} ${
        props.type && "d-none d-lg-block pt-0"
      }`}
    >
      <div className="container">
        <div class="section-title">
          {props.applyInsurance
            ? "Select the type of insurance"
            : "Explore Our Range of Insurance Options"}
        </div>
        {!props.type && (
          <div className="sub-title text-center pb-3">
            Comprehensive coverage tailored to safeguard your assets and provide
            peace of mind against unexpected risks.
          </div>
        )}
        <Options data={props.type ? filteredData : InsuranceType} />
      </div>
    </div>
  );
}
export default InsuranceOptions;
