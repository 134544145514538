import { useEffect } from "react";
import BreadCrumbs from "../Components/breadcrumbs";

function Refund() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Refund & Cancellation Policy",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="container">
        <div className="section-60 text-justify">
          <b>
            <center>CANCELLATION POLICY</center>
          </b>
          <br />
          <br />
          Please note that once the payment gets deducted, it will be treated as
          successful transactions and there is no way User can cancel such
          transactions except in case of Insurance policies where user will get
          time period of 30 days as free look period to cancel their policy.
          <br />
          <br />
          <br />
          <b>
            <center>REFUND POLICY</center>
          </b>
          <br />
          <br />
          1. Amount once paid through the payment gateway shall not be refunded
          other than in the following circumstances:
          <br />
          - Multiple times debiting of Customer’s Card/Bank Account due to
          technical error OR Customer's account being debited with excess amount
          in a single transaction due to technical error. In such cases, excess
          amount excluding Payment Gateway charges would be refunded to the
          Customer.
          <br />
          - Due to technical error, payment being charged on the Customer’s
          Card/Bank Account but the transaction is unsuccessful. RV Capital does
          not provide refund & cancellation, thus we require payment Gateway to
          receive EMI from our customers.
          <br />
          <br />
          2. The Customer will have to make an application for refund along with
          the transaction number and original payment receipt if any generated
          at the time of making payments.
          <br />
          <br />
          3. The application in the prescribed format should be sent to
          customercare@rvcapitalfinance.com.
          <br />
          <br />
          4. The application will be processed manually and after verification,
          if the claim is found valid, the amount received in excess will be
          refunded by RV Capital through electronic mode in favor of the
          borrower and confirmation sent to the mailing address given to us,
          within a period of 7 calendar days on receipt of such claim. It will
          take 3-7 days for the money to show in your bank account depending on
          your bank’s policy.
          <br />
          <br />
          5. The company assumes no responsibility and shall incur no liability
          if it is unable to affect any Payment Instruction(s) on the Payment
          Date owing to any one or more of the following circumstances:
          <br />
          a. If the Payment Instruction(s) issued by you is/are incomplete,
          inaccurate, and invalid and delayed.
          <br />
          b. If the Payment Account has insufficient funds/limits to cover for
          the amount as mentioned in the Payment Instruction(s).
          <br />
          c. If the funds available in the Payment Account are under any
          encumbrance or charge. d. If your Bank refuses or delays honoring the
          Payment Instruction(s).
          <br />
          e. Circumstances beyond the control of Company (including, but not
          limited to, fire, flood, natural disasters, bank strikes, power
          failure, systems failure like computer or telephone lines breakdown
          due to an unforeseeable cause or interference from an outside force)
          <br />
          f. In case the payment is not effected for any reason, you will be
          intimated about the failed payment by an e-mail.
          <br />
          <br />
          6. User agrees that the Company, in its sole discretion, for any or no
          reason, and without penalty, may suspend or terminate his/her account
          (or any part thereof)or use of the Services and remove and discard all
          or any part of his/her account, user profile, or his/her recipient
          profile, at any time. Company may also in its sole discretion and at
          any time discontinue providing access to the Services, or any part
          thereof, with or without notice. User agrees that any termination of
          his/her access to the Services or any account he/she may have or
          portion thereof may be effected without prior notice and also agrees
          that Company will not be liable to User or any third party for any
          such termination. Any suspected, fraudulent, abusive, or illegal
          activity may be referred to appropriate law enforcement authorities.
          These remedies are in addition to any other remedies Company may have
          at law or in equity. Upon termination for any reason, the user agrees
          to immediately stop using the Services.
          <br />
          <br />
          7. The company may elect to resolve any dispute, controversy, or claim
          arising out of or relating to this Agreement or Service provided in
          connection with this Agreement by binding arbitration in accordance
          with the provisions of the Indian Arbitration & Conciliation Act,
          1996. Any such dispute, controversy or claim shall be arbitrated on an
          individual basis and shall not be consolidated in any arbitration with
          any claim or controversy of any other party.
        </div>
      </div>
    </>
  );
}
export default Refund;
