import repayment from "../Assets/img/LoanIcon/time-is-money.png";
import interest from "../Assets/img/LoanIcon/chart-down.png";
import doc from "../Assets/img/LoanIcon/document.png";
import zero from "../Assets/img/LoanIcon/omicron.png";
import nocharge from "../Assets/img/LoanIcon/no-money.png";
import process from "../Assets/img/LoanIcon/processing.png";
import approve from "../Assets/img/LoanIcon/approved.png";
import prepay from "../Assets/img/LoanIcon/operation.png";
import penalty from "../Assets/img/LoanIcon/penalty.png";
import calc from "../Assets/img/LoanIcon/tax.png";
import receipt from "../Assets/img/LoanIcon/receipt.png";
import high from "../Assets/img/LoanIcon/budget.png";
import disbursal from "../Assets/img/LoanIcon/expenses.png";
import collateral from "../Assets/img/LoanIcon/assets.png";
import education from "../Assets/img/LoanIcon/education.png";
import guidance from "../Assets/img/LoanIcon/guidance.png";
import processing from "../Assets/img/LoanIcon/planning.png";
import foreclosure from "../Assets/img/LoanIcon/foreclosure.png";
import criteria from "../Assets/img/LoanIcon/criteria.png";

export const loanData = {
  type: "Instant",
  features: [
    {
      img: repayment,
      title: "Flexible repayment tenure",
      desc: "Choose from flexible repayment tenures tailored to your financial preferences with our loan options, providing the freedom to select a repayment schedule that suits your budget and lifestyle.",
    },
    {
      img: interest,
      title: "Attractive interest rates",
      desc: "Benefit from competitive interest rates with our loan offerings, ensuring affordability and savings. Take advantage of our attractive rates to finance your needs with confidence, making borrowing a financially sound decision.",
    },
    {
      img: doc,
      title: "Minimal documentation",
      desc: "Streamline your borrowing experience with our loan solutions featuring minimal documentation requirements. Enjoy hassle-free processing while fulfilling your financial needs swiftly and efficiently.",
    },
    {
      img: zero,
      title: "Zero foreclosure/pre-payment charges",
      desc: "Enjoy the freedom to repay your loan early without any additional charges. Our loan options feature zero foreclosure or pre-payment fees, allowing you to manage your finances efficiently and save on unnecessary expenses.",
    },
    {
      img: nocharge,
      title: "No hidden charges",
      desc: "Our loan packages come with transparent terms, ensuring there are no hidden charges. You can confidently plan your finances without worrying about unexpected fees, offering peace of mind throughout the repayment process.",
    },
    {
      img: approve,
      title: "Quick approvals",
      desc: "Our loan services offer rapid approvals, ensuring swift access to funds when you need them most. With efficient processing and minimal paperwork, you can secure the financial assistance you require without delay.",
    },
    {
      img: process,
      title: "Easy and hassle-free process",
      desc: "Experience a straightforward and stress-free loan application with our streamlined process, ensuring convenience and efficiency every step of the way. Say goodbye to complexities and delays, and get the funds you need swiftly.",
    },
  ],
  eligibility: [
    {
      title: "Age",
      desc: "Applicants should be at least 22 years old at the time of loan application and a maximum of 57 years old.",
    },
    {
      title: "Citizenship",
      desc: "Applicants must have Indian citizenship.",
    },
    {
      title: "Employment",
      desc: "Applicants must have a full-time job at Central Govt, PSU, MNC, Listed, Unlisted Public Ltd, Pvt Ltd, LLP, or companies listed internally.",
    },
    {
      title: "Work Experience",
      desc: "Applicants must have at least 1 year of job experience and at least 1 month of stable employment at the current job.",
    },
    {
      title: "Monthly Income",
      desc: "Monthly net take home salary of at least ₹30,000.",
    },
  ],
  documents: [
    {
      title: "KYC Documents",
      desc: "PAN Card/Aadhaar Card/Driving licence/Voter ID/Passport",
    },
    {
      title: "Current Address Proof",
      desc: "Passport/ Utility bills/ Rent Agreement",
    },
    {
      title: "Income Documents",
      desc: "Latest salary slips for the last 3 months",
    },
    {
      title: "Financial Documents",
      desc: "Bank Statement for the last 3 months",
    },
    {
      title: "Employment Proof",
      desc: "Official mail ID confirmation /Employee ID Card (in case official mail ID confirmation is not possible)",
    },
  ],
  interest: [
    {
      img: interest,
      title: "Personal Loan minimum interest rate",
      desc: "Our personal loans offer competitive interest rates, ensuring affordability for borrowers. Benefit from our minimum interest rate options, providing financial flexibility and helping you achieve your goals with ease.",
    },
    {
      img: receipt,
      title: "Loan processing fees",
      desc: "Our loans feature competitive processing fees, ensuring affordability and transparency throughout the borrowing process. With minimal charges, you can access the funds you need without incurring excessive costs, making our loans a financially sensible choice.",
    },
    {
      img: prepay,
      title: "Pre-payment charges",
      desc: "Our loans feature transparent pre-payment policies, ensuring clarity and flexibility. With minimal or no pre-payment charges, borrowers can settle their debts early without incurring excessive fees, empowering them to manage their finances efficiently.",
    },
    {
      img: zero,
      title: "Zero hidden costs",
      desc: "Our loan packages ensure transparency with zero hidden costs, providing peace of mind and clarity throughout the borrowing process. You can trust that the agreed-upon terms are straightforward and free from any unexpected fees.",
    },
    {
      img: penalty,
      title: "Late payment penalty",
      desc: "A penalty fee may be imposed for late loan payments, encouraging timely repayment and ensuring responsible financial behavior. It incentivizes borrowers to adhere to the agreed-upon schedule, minimizing disruptions to loan management processes.",
    },
    {
      img: calc,
      title: "Online interest calculator",
      desc: "Access our user-friendly online interest calculator to effortlessly estimate your loan repayments. Simply input your loan amount, interest rate, and desired term to instantly receive an accurate breakdown of your monthly payments, helping you plan your finances with ease.",
    },
  ],
};

export const businessLoanData = {
  type: "Business",
  features: [
    {
      img: high,
      title: "Higher Loan Disbursal Amount",
      desc: "Ensure your business stays financially robust and flexible with our Business Loan, offering access to capital of up to ₹50 Lakh. Say goodbye to compromise and confidently cover all your business expenses, from operational costs to working capital, without constraints.",
    },
    {
      img: disbursal,
      title: "Swift and Smooth Disbursal",
      desc: "Tailored to accommodate your demanding lifestyle and tight schedules, our Business Loan is crafted for convenience. With seamless online processes, we provide swift access to funds, guaranteeing a hassle-free experience whenever your business requires financial support.",
    },
    {
      img: collateral,
      title: "Collateral free Loans",
      desc: "Our Business Loan stands out with its distinctive feature: no collateral required. This unsecured option minimizes both time and risk, allowing you to obtain capital quickly and effortlessly. Say goodbye to the hassle of pledging business or personal assets, as accessing funds becomes straightforward and worry-free.",
    },
    {
      img: interest,
      title: "Interest Rates",
      desc: "Choosing our Business Loan ensures you benefit from a competitive interest rate. With numerous advantages to explore, including our cost-effective solution, you can trust our offering to provide financial stability and support for your business endeavors.",
    },
    {
      img: doc,
      title: "Simplified Documentation Process",
      desc: "Experience expedited loan processing by simply submitting essential Business Loan documents. Our streamlined documentation process not only accelerates your borrowing experience but also sets us apart from other financiers, giving you a distinct advantage when choosing us for your financial needs.",
    },
    {
      img: repayment,
      title: "Extended Loan Tenure",
      desc: "Ensure that your business's working capital remains intact and profits stay robust with our Business Loan. We understand the importance of managing loan repayments without straining your financial resources. That's why we offer flexible repayment tenures of up to 48 months, empowering you to plan your repayments according to your business's cash flow, thus alleviating any concerns about impacting your working capital or profits.",
    },
    {
      img: process,
      title: "Convenience of Online Process",
      desc: "Experience the convenience of applying for a Business Loan through our seamless online process, offering accessibility right from the comfort of your home or office. Benefit from instant approval and receive the funds directly into your account without any delays, making the entire borrowing experience hassle-free and efficient.",
    },
  ],
  eligibility: [
    {
      title: "Age",
      desc: "Applicants must be between 24 and 65 years of age (at loan maturity).",
    },
    {
      title: "Business Vintage",
      desc: "Applicants must have a business vintage of at least 2 years.",
    },
    {
      title: "Annual Turnover",
      desc: "The applicant’s business must have a minimum annual turnover of ₹6 Lakh.",
    },
  ],
  documents: [
    {
      title: "KYC",
      desc: [
        "Director/Proprietor/ Partner PAN Card/Aadhaar Card/Driving Licence/Voter ID/Passport",
        "Proprietor/Partner/Director PAN Card/Aadhaar Card/Driving Licence/Voter ID/Passport",
        "Personal Docs - All Individual/Partner/Director - PAN Card/Aadhaar Card/Driving Licence/Voter ID/Passport & Officially Valid Documents + Proof of Address (If not as per Officially Valid Documents)",
        "Business Docs - Business Registration Proof + Business Address Proof (if not as per Business Registration Proof)",
      ],
    },
    {
      title: "Business Address Proof",
      desc: "Utility bills/ Rent Agreement",
    },
    {
      title: "Financial Documents",
      desc: "Bank account statement of past 6 months.",
    },
  ],
  interest: [
    {
      img: interest,
      title: "Interest rate",
      desc: "Ensure that loan repayments fit comfortably within your budget with our Business Loan. Depending on factors such as your credit score, financial position, and experience, you could qualify for an impressively low interest rate starting from just 15%* per annum. This allows business owners to access additional capital in a cost-effective manner, empowering them to grow and succeed.",
    },
    {
      img: receipt,
      title: "Processing fees",
      desc: "Benefit from our Business Loan's straightforward fee structure, featuring a one-time nominal processing fee of up to 3%, plus applicable taxes. This fee is applied to the disbursed amount and serves as an administrative charge for managing the loan processing efficiently.",
    },
    {
      img: prepay,
      title: "Pre-payment charges",
      desc: "Maximize your savings and maintain low overall costs by taking advantage of prepayment options with our Business Loan. Enjoy the flexibility to prepay at your convenience without any additional fees. We believe in empowering you to manage your finances efficiently, which is why we impose zero prepayment charges if paid from your own sources. However, a 4% prepayment or foreclosure charge will be applicable if paid from other sources. This approach allows you to focus on your business growth while minimizing unnecessary expenses.",
    },
    {
      img: penalty,
      title: "Late payment penalties",
      desc: "Failing to meet loan EMIs results in penalties, with charges of 3% levied on the defaulted EMI amount every month. Additionally, in the event of a cheque bounce, a fee of ₹1,000 per bounce is applied, along with applicable taxes.",
    },
  ],
};

export const carLoanData = {
  type: "Car",
  features: [
    {
      img: high,
      title: "High Loan Amount",
      desc: "Turn your dream car into reality with our Car Loan. Secure up to ₹75 Lakh in sanction and take the leap towards owning the perfect pre-owned car for you.",
    },
    {
      img: interest,
      title: "Attractive Interest Rates",
      desc: "Benefit from our pocket-friendly repayment terms with our low-interest rates. Our competitive rates ensure an affordable purchase, allowing you to keep costs in check while enjoying exceptional value.",
    },
    {
      img: doc,
      title: "Minimal Documentation",
      desc: "Get behind the wheel faster with our Car Loan. Our streamlined process requires only essential documentation for a hassle-free application.",
    },
    {
      img: repayment,
      title: "Flexible Repayment Option",
      desc: "You can comfortably manage your finances over the next 6 years with a repayment plan extending up to 72 months. Say goodbye to EMI worries and stay within your budget throughout the loan tenure.",
    },
    {
      img: process,
      title: "Easy And Hassle-Free Process",
      desc: "Secure the car of your dreams effortlessly with our streamlined online loan application. Enjoy a hassle-free experience designed for speed and comfort, putting you behind the wheel in just a few clicks.",
    },
    {
      img: nocharge,
      title: "No Hidden Charges",
      desc: "We uphold utmost integrity with our esteemed customers, ensuring complete transparency in all loan transactions. Rest assured, there are no hidden fees. Our loan documents comprehensively outline all terms and conditions for your peace of mind.",
    },
  ],
  eligibility: [
    {
      title: "Age",
      desc: [
        "For salaried - Applicants must be between 24 (during loan application) and 60 years of age (at loan maturity)",
        "For self employed - Applicants must be between 24 (during loan application) and 65 years of age (at loan maturity)",
        "For firm / company - Proprietor / Partners / Directors as applicable, must be a minimum of 24 years of age",
      ],
    },
    {
      title: "Experience / Vintage",
      desc: [
        [
          "For salaried",
          "Up to ₹10 Lakh: 3 months in current employment with 12 months total employment stability.",
          "₹10 Lakh - 75 ₹Lakh: 6 months in current employment with 24 months total employment stability.",
        ],
        [
          "For self employed",
          "Up to ₹10 Lakh: 24 months in current business.",
          "₹10 Lakh - ₹75 Lakh: 36 months in current business.",
        ],
        [
          "For firm / company",
          "Should be in the same business for a minimum of 2 years",
        ],
      ],
    },
    {
      title: "Citizenship",
      desc: "Must have Indian citizenship",
    },
    {
      title: "Credit Score",
      desc: [
        "For salaried - The credit score of the applicant must be 700 and above",
        "For self employed - The credit score of the applicant must be 700 and above",
        "For firm / company - The credit score of the Proprietor / Partners / Directors must be 700 and above",
      ],
    },
  ],
  documents: [
    {
      title: "Identity Documents",
      desc: [
        [
          "For individuals",
          "Aadhaar Card",
          "PAN Card",
          "Driving Licence",
          "Passport",
          "Voter ID",
        ],
        [
          "For firm / company",
          "Firm/ GST Registration Certificate (If applicable) and Other Applicable Registration",
          "KYC of Partners /Aadhaar Card/Voter ID/Driving License/Passport",
        ],
      ],
    },
    {
      title: "Address Proof",
      desc: [
        ["For individuals", "Driving Licence", "Passport", "Voter ID"],
        [
          "For firm / company",
          "Electricity bill for owned premises",
          "Rent agreement for Rented premises",
          "Passport for Partner / Directors",
        ],
      ],
    },
    {
      title: "Income Documents",
      desc: [
        ["For salaried", "Latest salary slips for the last 3 month"],
        [
          "For self employed",
          "Income Tax Returns for the last 2 years",
          "Profit/Loss statement and balance sheet for the last 2 years",
          "GST Returns",
        ],
        [
          "For firm / company",
          "Income Tax Returns along with computation for the last 2 years",
          "Profit/Loss statement and balance sheet for the last 2 years",
          "GST Returns",
        ],
      ],
    },
    {
      title: "Bank Account Statement",
      desc: [
        "For salaried - Salary account statement for the last 3 months",
        "For self employed - Statement for the last 6 months",
        "For firm / company - Statement for the last 6 months",
      ],
    },
    {
      title: "Car Documents",
      desc: "If you have already finalised the car, include RC & Insurance",
    },
  ],
  interest: [
    {
      img: interest,
      title: "Interest Rate",
      desc: "Get behind the wheel affordably with our Car Loan. Benefit from competitive rates starting at 11%* p.a. and enjoy the best deals on your next vehicle purchase, ensuring a budget-friendly experience from start to finish.",
    },
    {
      img: process,
      title: "Loan Processing Fees",
      desc: "Our Pre-owned Car Loan incurs a minimal processing fee of up to 1% of the approved amount, alongside applicable taxes, covering administrative services for smooth loan processing.",
    },
    {
      img: foreclosure,
      title: "Foreclosure Charges",
      desc: "Opting for loan foreclosure can be financially savvy. We offer a 0% fee for payments from your own sources and a modest 4% fee for payments from other sources, alongside applicable taxes.",
    },
    {
      img: penalty,
      title: "Late Payment Penalty",
      desc: "Failure to make loan payments on time will result in a 3% penalty on the EMI amount, along with applicable taxes. Additionally, a ₹1000 charge per bounced cheque will be levied, plus applicable taxes.",
    },
    {
      img: high,
      title: "Loan to Value (LTV)",
      desc: "Experience the joy of buying a car without compromises. Our Pre-Owned Car Loan offers up to 100% of the car’s value, ensuring you find your perfect ride hassle-free.",
    },
  ],
};

export const educationLoanData = {
  type: "Education",
  features: [
    {
      img: education,
      title: "Affordable & Quality Education",
      desc: "Gain access to affordable and quality education with our flexible education loan options. We provide financial support tailored to your needs, ensuring you can pursue your academic aspirations without financial constraints. Say yes to your dreams with our hassle-free loan process and competitive interest rates.",
    },
    {
      img: repayment,
      title: "Flexible Repayment Options",
      desc: "Our education loan offers versatile repayment options tailored to your academic journey. Choose from flexible plans, including deferred payments while in school, graduated repayment schedules, or standard fixed installments. With these options, you can focus on your studies without financial stress, while also managing repayment comfortably after graduation.",
    },
    {
      img: interest,
      title: "Competitive Interest Rates",
      desc: "Our education loans offer competitive interest rates, ensuring affordability and accessibility for aspiring students. With rates designed to be competitive in the market, we aim to support individuals pursuing higher education by providing financial solutions that minimize the burden of interest costs. Our commitment to competitive rates reflects our dedication to empowering students to achieve their academic goals without undue financial strain.",
    },
    {
      img: collateral,
      title: "No Collateral Required",
      desc: "Secure your educational journey hassle-free with our education loan, offering flexible repayment options tailored to your needs. Plus, enjoy the benefit of no collateral required, making financing your education easier and more accessible.",
    },
    {
      img: process,
      title: "Quick Hassle-free Process",
      desc: "Experience a quick and hassle-free process when applying for our education loans. We understand the urgency of funding academic endeavors and strive to streamline the application process for your convenience. With minimal paperwork and efficient approval procedures, we ensure that you can focus on your studies without unnecessary delays or complications.",
    },
    {
      img: guidance,
      title: "Expert Financial Guidance",
      desc: "Benefit from expert financial guidance throughout your education loan journey. Our knowledgeable advisors are here to provide personalized assistance, helping you navigate the complexities of loan options, terms, and repayment strategies. With their expertise, you can make informed decisions to ensure your educational financing aligns with your long-term financial goals, empowering you to pursue your academic aspirations with confidence.",
    },
  ],
  eligibility: [
    {
      title: "Age",
      desc: "Applicants should be at least 18 years old at the time of loan application and a maximum of 45 years old.",
    },
    {
      title: "Citizenship",
      desc: "Applicants must have Indian citizenship.",
    },
    {
      title: "Degree",
      desc: "Graduation/post-graduation degree from recognised universities.",
    },
    {
      title: "Course",
      desc: "Executive & other courses from reputed universities.",
    },
  ],
  documents: [
    {
      title: "Basic applicant and Co-applicant documentation",
      desc: ["Identification Proof", "Passport", "PAN card"],
    },
    {
      title: "Applicant academic documents",
      desc: [
        "10th grade",
        "12th grade",
        "Available Sem Marksheet/ Transcript",
        "Degree",
        "Entrance Exam Score (GRE. IELTS, TOEFL, GMAT, Other]",
      ],
    },
    {
      title: "Applicant Financial Documents",
      desc: [
        "Last 3 months Salary Slip",
        "Last 2 years Form 16",
        "Last 3 Years ITR",
        "Balance sheet and P&L account",
        "Business Proof",
        "Bank statement – Last 6 months",
      ],
    },
  ],
  interest: [
    {
      img: prepay,
      title: "Pre-payment charges",
      desc: "Our loans feature transparent pre-payment policies, ensuring clarity and flexibility. With minimal or no pre-payment charges, borrowers can settle their debts early without incurring excessive fees, empowering them to manage their finances efficiently.",
    },
    {
      img: zero,
      title: "Zero hidden costs",
      desc: "Our loan packages ensure transparency with zero hidden costs, providing peace of mind and clarity throughout the borrowing process. You can trust that the agreed-upon terms are straightforward and free from any unexpected fees.",
    },
    {
      img: penalty,
      title: "Late payment penalty",
      desc: "A penalty fee may be imposed for late loan payments, encouraging timely repayment and ensuring responsible financial behavior. It incentivizes borrowers to adhere to the agreed-upon schedule, minimizing disruptions to loan management processes.",
    },
  ],
};

export const homeLoanData = {
  type: "Home",
  features: [
    {
      img: high,
      title: "Multi-Purpose Loans",
      desc: "Our multi-purpose home loans provide versatile financing solutions tailored to your needs. Whether it's buying, renovating, or refinancing your home, we offer flexible terms and competitive rates to support your housing aspirations effectively.",
    },
    {
      img: interest,
      title: "Attractive Home Loan Interest Rates",
      desc: "Benefit from attractive home loan interest rates tailored to your financial needs. Our competitive rates make owning your dream home more affordable. With flexible terms and personalized solutions, we strive to make home ownership a reality for you.",
    },
    {
      img: criteria,
      title: "Easy Eligibility Criteria",
      desc: "Our home loans feature straightforward eligibility criteria, making it easier for you to qualify. With simple requirements, including proof of income and identity, we aim to facilitate your home ownership journey with minimal hassle.",
    },
    {
      img: approve,
      title: "Instant Approval",
      desc: "Get instant approval for your home loan, ensuring a swift and seamless process. With our efficient system, you'll receive a decision promptly, allowing you to move forward with your home purchase without delay.",
    },
    {
      img: process,
      title: "Quick Processing",
      desc: "Experience swift processing for your home loan application. Our efficient procedures ensure a quick turnaround, getting you closer to owning your dream home without unnecessary delays. Apply today and expedite your journey towards homeownership.",
    },
    {
      img: repayment,
      title: "Loan Tenure up to 30 Years",
      desc: "Get the home of your dreams with our home loan, featuring a flexible tenure of up to 30 years. Enjoy the peace of mind and affordability as you spread your repayments over an extended period, making homeownership more accessible and manageable",
    },
  ],
  eligibility: [
    {
      title: "Age",
      desc: "Applicants must be between 21 and 65 years of age (at loan maturity).",
    },
    {
      title: "Varied Employment Profiles",
      desc: "Financial stability for various employment statuses. Whether you are a salaried employee, a self-employed professional, or an entrepreneur, our loan criteria are flexible enough to encompass different professional backgrounds.",
    },
    {
      title: "Stable Income Requirement",
      desc: "A consistent income stream is a cornerstone of our eligibility criteria.",
    },
    {
      title: "Credit Health",
      desc: "Maintaining a healthy credit score simplifies the path to securing a home loan, reinforcing the importance of financial prudence.",
    },
  ],
  documents: [
    {
      title: "Age Proof",
      desc: "Passport, Driving License, Life Insurance Policy, Birth Certificate, PAN Card, or School Leaving Certificate.",
    },
    {
      title: "Photo Identity Proof",
      desc: "Voter ID Card, Passport, Driving License, Aadhaar Card, or PAN Card.",
    },
    {
      title: "Address Proof",
      desc: "Utility Bill, Bank Statement, Property Registration Documents, Property Tax Receipt, or Voter ID Card.",
    },
    {
      title: "Income Proof",
      desc: [
        [
          "For Salaried",
          "Salary Slips for the last three months",
          "Salary Bank Account Statements for the last 12 months",
          "Appointment letter",
          "Yearly increment letter",
          "or a Certified true copy of Form 16",
        ],
        [
          "For Self Employed",
          "last two years' Income Tax Returns",
          "your Business Profile on a letterhead",
          "or your Business Registration Certificate for business commencement",
        ],
        [
          "For NRI",
          "Overseas salary account details for Indian NRE or NRO account statements for the last twelve months",
        ],
      ],
    },
    {
      title: "Existing Loan Details",
      desc: "Existing loan details should be provided through Bank Statements or Loan Statements.",
    },
  ],
  interest: [
    {
      img: interest,
      title: "Interest Rate",
      desc: "Our Home Loan comes with competitive interest rates starting at just 9.99%* p.a., making monthly repayments manageable and reducing the loan's total cost. Utilize our Home Loan EMI calculator to forecast monthly payments, aiding in budget planning and ensuring financial preparedness.",
    },
    {
      img: processing,
      title: "Processing Fee",
      desc: "To obtain a Home loan, a processing fee is required. We strive to keep costs minimal for you by charging a processing fee of up to 1%* of the loan amount. This transparency ensures affordability without hidden charges, making the Home loan process straightforward and accessible.",
    },
    {
      img: repayment,
      title: "Part-payment Charges",
      desc: "You won't incur any fees for making part-payments after repaying 6 EMIs. This gives you the freedom to reduce your loan amount and save on interest without extra costs. Enjoy the flexibility to manage your loan and save money hassle-free.",
    },
    {
      img: foreclosure,
      title: "Foreclosure Charges",
      desc: "Clear your loan early without facing hefty charges. We impose 0% foreclosure fees if paid from your own funds, and 4% on the remaining loan amount, plus taxes, if paid from external sources. We aim to offer fair options for settling your debt sooner.",
    },
    {
      img: receipt,
      title: "Default Charges",
      desc: "If you miss paying your Home Loan EMI on time, late payment charges at a rate of 24% per annum will be applicable. It's important to ensure timely payments to avoid incurring these additional fees during the loan tenure.",
    },
    {
      img: penalty,
      title: "Other Charges",
      desc: "Please review our tariff sheet document for additional information on our fees and charges. Terms and conditions apply.",
    },
  ],
};

export const loanAgainstPropertyData = {
  type: "AgainstProperty",
  features: [
    {
      img: doc,
      title: "Minimum Documentation",
      desc: "We streamline the Loan Against Property application process by requiring only essential documents, saving you time and hassle. Say goodbye to endless paperwork and hello to a quicker approval process with us.",
    },
    {
      img: process,
      title: "Quick and Easy Loan Processing",
      desc: "Experience a swift and hassle-free Loan Against Property journey with our streamlined online application process. Our dedicated representatives ensure prompt assistance for expedited processing and disbursal, guaranteeing a seamless experience for our valued customers.",
    },
    {
      img: collateral,
      title: "Loan on Different Kinds of Properties",
      desc: "We provide versatile loan options against various property types, catering to residential, commercial, industrial, and warehouse assets. Our flexible terms ensure tailored solutions to meet diverse financing needs efficiently.",
    },
    {
      img: high,
      title: "Higher Loan-To-Value (LTV) on Market Value",
      desc: "Unlock substantial funds with our Loan Against Property, leveraging a high Loan-To-Value (LTV) ratio based on your property's market value. Access a larger loan amount to address diverse financial needs with ease and confidence.",
    },
    {
      img: interest,
      title: "Competitive Interest Rates",
      desc: "Benefit from our Loan Against Property with enticing interest rates, enabling lower EMIs and preventing financial strain. Unlock the equity in your property while enjoying manageable repayment terms, ensuring a smoother financial journey.",
    },
    {
      img: repayment,
      title: "Longer Tenure",
      desc: "Access our Loan Against Property with a repayment period extending up to 180 months, providing ample time to manage repayments without straining your monthly budget. Enjoy the flexibility to tailor your repayment schedule according to your financial comfort.",
    },
    {
      img: processing,
      title: "Transparent Processing",
      desc: "We uphold complete transparency with our customers. Throughout the Loan Against Property process, we clearly communicate all applicable fees and charges, ensuring they are transparently listed in the loan documentation for your peace of mind.",
    },
    {
      img: zero,
      title: "Zero* Foreclosure Charges",
      desc: "Clear your loan early without hefty charges. No prepayment fees if paid from personal funds*. If paid from other sources, a foreclosure charge of up to 4% of the outstanding principal, plus taxes may apply.",
    },
  ],
  eligibility: [
    {
      title: "Eligible Borrowers",
      desc: [
        "For salaried - Salaried individuals working with LLP / Private Limited / Public Limited companies",
        "For self employed - Proprietor / Partner / Director, Self-employed professionals like Chartered Accountants, Company Secretaries, and doctors",
        "For firm / company - Partnership or Private Limited companies engaged in manufacturing, servicing, or trading",
      ],
    },
    {
      title: "Age",
      desc: "Applicant must be a minimum of 21 years at the time of loan application and should be a maximum of 60 years at loan maturity",
    },
    {
      title: "Experience / vintage",
      desc: [
        "For salaried - 3 years of total experience and 6 months with the current employer	",
        "For self employed - Should be in the same business/profession for the last 5 years	",
        "For firm / company - Business stability/vintage of minimum 5 years in the same business",
      ],
    },
    {
      title: "Citizenship",
      desc: "Must have Indian citizenship",
    },
  ],
  documents: [
    {
      title: "Identity Documents",
      desc: [
        "For individuals - PAN Card/Aadhaar Card/Driving Licence/Voter ID/Passport",
        "For ferm / company - GST Registration Certificate (If applicable) and Other Applicable Registrations, KYC of Partners / PAN Card/Aadhaar Card/Driving Licence/Voter ID/Passport",
      ],
    },
    {
      title: "Address Proof",
      desc: [
        "For individuals - The Electricity bill for owned premises, Rent agreement for Rented premises, Passport",
        "For ferm / company - The Electricity bill for owned premises, Rent agreement for Rented premises, Passport for Partner / Directors",
      ],
    },
    {
      title: "Income Documents",
      desc: [
        "For salaried - 3-month salary slips",
        "For salf employed - Income Tax Returns along with computation for the latest 2 years, Profit/Loss statement and balance sheet for the last 2 years",
        "For ferm / company - Income Tax Returns along with computation for the last 2 years, Profit/Loss statement and balance sheet for the last 2 years, GST Returns",
      ],
    },
    {
      title: "Bank Account Statement",
      desc: [
        "For salaried - Salary credit bank account statement for the last 6 months",
        "For salf employed - Salary credit bank account statement for the last 12 months",
        "For ferm / company - Salary credit bank account statement for the last 12 months",
      ],
    },
    {
      title: "Property Documents",
      desc: "If already finalized along with legal & technical clearance of the property",
    },
  ],
  interest: [
    {
      img: interest,
      title: "Interest Rate",
      desc: "Our Loan comes with competitive interest rates starting at just 9.99%* p.a., making monthly repayments manageable and reducing the loan's total cost. Utilize our Loan EMI calculator to forecast monthly payments, aiding in budget planning and ensuring financial preparedness.",
    },
    {
      img: processing,
      title: "Processing Fee",
      desc: "To obtain a loan, a processing fee is required. We strive to keep costs minimal for you by charging a processing fee of up to 1%* of the loan amount. This transparency ensures affordability without hidden charges, making the loan process straightforward and accessible.",
    },
    {
      img: repayment,
      title: "Part-payment Charges",
      desc: "You won't incur any fees for making part-payments after repaying 6 EMIs. This gives you the freedom to reduce your loan amount and save on interest without extra costs. Enjoy the flexibility to manage your loan and save money hassle-free.",
    },
    {
      img: foreclosure,
      title: "Foreclosure Charges",
      desc: "Clear your loan early without facing hefty charges. We impose 0% foreclosure fees if paid from your own funds, and 4% on the remaining loan amount, plus taxes, if paid from external sources. We aim to offer fair options for settling your debt sooner.",
    },
    {
      img: receipt,
      title: "Default Charges",
      desc: "If you miss paying your Loan EMI on time, late payment charges at a rate of 24% per annum will be applicable. It's important to ensure timely payments to avoid incurring these additional fees during the loan tenure.",
    },
    {
      img: penalty,
      title: "Other Charges",
      desc: "Please review our tariff sheet document for additional information on our fees and charges. Terms and conditions apply.",
    },
  ],
};

export const machinaryLoanData = {
  type: "Machinary",
  features: [
    {
      img: high,
      title: "Higher Loan Amounts",
      desc: "Get machinery loans from ₹5 lakh to ₹5 crore to buy top-quality equipment for your business. We provide financing options tailored to your needs, helping you invest in the best machinery for improved productivity and growth.",
    },
    {
      img: disbursal,
      title: "Quick Disbursal",
      desc: "Upgrade your machinery effortlessly with our quick fund disbursement and minimal paperwork. Access funds swiftly to enhance your equipment, ensuring your business stays ahead with top-notch machinery and improved efficiency.",
    },
    {
      img: interest,
      title: "Attractive Interest Rates",
      desc: "Upgrade and acquire equipment effortlessly with our Machinery Loan, featuring competitive interest rates. Whether you're enhancing or starting a business, our financing simplifies heavy machinery acquisition, ensuring seamless growth for your operations.",
    },
    {
      img: doc,
      title: "Simplified Documentation Process",
      desc: "Our machinery loan process is quick and easy with minimal paperwork. We prioritize your convenience, ensuring swift approval by streamlining documentation requirements. Get your loan application processed efficiently to acquire the machinery your business needs.",
    },
    {
      img: repayment,
      title: "Foreclosure and Part-payment",
      desc: "Easily pay off your machinery loan before the due date with our flexible foreclosure options. Whether you want to settle the entire loan amount early or make partial payments, we offer convenient solutions to suit your needs.",
    },
    {
      img: zero,
      title: "No Hidden Charges",
      desc: "We prioritize trust and transparency in our machinery loans, ensuring no hidden charges. Rest assured, our financing solutions are straightforward and clear, allowing you to focus on acquiring equipment for your business without unexpected costs.",
    },
    {
      img: process,
      title: "Easy Online Process",
      desc: "Start your machinery loan process easily by applying online from home or your office. No need to visit a bank; just fill out the form online, and we'll guide you through the rest, ensuring a hassle-free experience.",
    },
  ],
  eligibility: [
    {
      title: "Age",
      desc: "Applicant must be between 25 and 65 years of age",
    },
    {
      title: "Citizenship",
      desc: "Indian",
    },
    {
      title: "Business Vintage",
      desc: "Applicant must have a business vintage of at least 3 years",
    },
  ],
  documents: [
    {
      title: "KYC",
      desc: [
        "Firms KYC - Udyam Registration Certificate, GST Registration Certificate, Shop Act Certificate, Factory Ownership Proof or Factory Rent Agreement",
        "Proprietors KYC - PAN Card/Aadhaar Card/Driving Licence/Voter ID/Passport, Residence Ownership Proof or Rent agreement",
        "Co-applicant's KYC - PAN Card/Aadhaar Card/Driving Licence/Voter ID/Passport, Residence Ownership Proof or Rent agreement",
      ],
    },
    {
      title: "Financials",
      desc: "Latest 2 years Financials containing ITR, Computation Of Income, B/S,P&L, Audit Reports and Applicable Schedules",
    },
    {
      title: "Banking",
      desc: [
        "Latest 6 months bank statements of all active accounts(CA/CC/OD)",
        "All live loans sanction letters/RPS/ SOA's",
      ],
    },
    {
      title: "Other",
      desc: [
        "Latest Proforma Invoice/ Machine Quotation along with Complete scope of Supply",
        "Work Order Copies/Orders in Hand",
        "Company Profile",
        "Passport Size photo, Mobile Numbers and Email ID's of Applicant and Co-applicant",
      ],
    },
  ],
  interest: [
    {
      img: interest,
      title: "Interest Rate",
      desc: "Our Machinery Loan comes with competitive interest rates starting at just 9.99%* p.a., making monthly repayments manageable and reducing the loan's total cost. Utilize our Machinery Loan EMI calculator to forecast monthly payments, aiding in budget planning and ensuring financial preparedness.",
    },
    {
      img: processing,
      title: "Processing Fee",
      desc: "To obtain a machinery loan, a processing fee is required. We strive to keep costs minimal for you by charging a processing fee of up to 1%* of the loan amount. This transparency ensures affordability without hidden charges, making the machinery loan process straightforward and accessible.",
    },
    {
      img: repayment,
      title: "Part-payment Charges",
      desc: "You won't incur any fees for making part-payments after repaying 6 EMIs. This gives you the freedom to reduce your loan amount and save on interest without extra costs. Enjoy the flexibility to manage your loan and save money hassle-free.",
    },
    {
      img: foreclosure,
      title: "Foreclosure Charges",
      desc: "Clear your loan early without facing hefty charges. We impose 0% foreclosure fees if paid from your own funds, and 4% on the remaining loan amount, plus taxes, if paid from external sources. We aim to offer fair options for settling your debt sooner.",
    },
    {
      img: receipt,
      title: "Default Charges",
      desc: "If you miss paying your Machinery Loan EMI on time, late payment charges at a rate of 24% per annum will be applicable. It's important to ensure timely payments to avoid incurring these additional fees during the loan tenure.",
    },
    {
      img: penalty,
      title: "Other Charges",
      desc: "Please review our tariff sheet document for additional information on our fees and charges. Terms and conditions apply.",
    },
  ],
};

export const professionalLoanData = {
  type: "Professional",
  features: [
    {
      img: education,
      title: "Multi-purpose Professional Loans",
      desc: "Our multi-purpose professional loan is tailored to cover all your diverse expenses, whether it's operational costs for your practice or investments in professional development. With no restrictions, you can depend on it completely to support your various financial needs.",
    },
    {
      img: high,
      title: "Higher loan amount",
      desc: "Secure up to ₹50 lakh loan approval for your professional commitments without compromising on financial stability. Our Loans for Professionals cater to your specific needs, ensuring adequate funding based on your individual profile and requirements.",
    },
    {
      img: interest,
      title: "Pocket-friendly interest rates",
      desc: "With a competitive interest rate, our Professional Loan minimizes interest expenses, encouraging borrowers to confidently access funds for their professional needs. Enjoy financial freedom without concerns about high interest payments, empowering you to pursue your career goals effectively.",
    },
    {
      img: process,
      title: "Easy processing",
      desc: "Opt for our online Professional Loan for a seamless borrowing experience. Meet simple eligibility criteria and submit necessary documents digitally for quick qualification. Enjoy hassle-free loan processing, empowering you to focus on your professional goals without unnecessary stress.",
    },
    {
      img: approve,
      title: "Quick approvals",
      desc: "Accessing funds through our Professional Loan is effortless, with swift approvals to match. Enjoy a streamlined application process and rapid decision-making, ensuring you get the financial support you need promptly and hassle-free.",
    },
    {
      img: collateral,
      title: "No collateral loan",
      desc: "Our Professional Loan doesn't require you to put up any assets as security. It's a collateral-free option designed to support professionals without the need for pledging valuable assets, offering financial flexibility and peace of mind.",
    },
    {
      img: repayment,
      title: "Flexible repayment tenure",
      desc: "Our Professional Loan offers flexibility in repayment terms, easing the burden of debt. Select a tenure ranging from 12 to 60 months, ensuring manageable monthly payments that align with your financial situation, providing peace of mind and financial stability.",
    },
    {
      img: nocharge,
      title: "No hidden charges",
      desc: "Rest assured, all our professional loan transactions are conducted with absolute integrity and transparency. No hidden fees lurk in our Professional Loan. Every detail is explicitly stated in the loan documents for your complete understanding and confidence.",
    },
    {
      img: zero,
      title: "Zero charges on part pre-payment",
      desc: "Utilize your surplus funds to prepay or completely clear your Professional Loan without incurring any additional charges or penalties when paid from personal funds. Enjoy the flexibility to manage your loan effectively and accelerate your path to financial freedom.",
    },
  ],
  eligibility: [
    {
      title: "age",
      desc: "Applicant’s age must be at least 24 years while applying for a loan and 65 years at the time of loan maturity.",
    },
    {
      title: "Work Experience",
      desc: "Applicant must be a practising professional for at least 1-year post qualification.",
    },
    {
      title: "Income",
      desc: "Applicant must have a minimum annual professional receipt of ₹3 Lakh.",
    },
  ],
  documents: [
    {
      title: "KYC",
      desc: [
        [
          "For Self-Employed Professionals -",
          "PAN Card/Aadhaar Card/Driving Licence/Voter ID/Passport",
          "Residence Address Proof: Electricity Bill / Rent Agreement / Passport",
          "Business Address Proof: Electricity Bill / Rent Agreement",
          "For Doctors: Doctor’s Prescription Letter",
          "For CA/ CS: Firm / Member Card",
        ],
        [
          "For Firm / Company -",
          "Business Address Proof and Other Applicable Registrations",
          "Proprietor/Partner/Director: PAN Card/Aadhaar Card/Driving Licence/Voter ID/Passport",
          "Residence Address Proof: Electricity Bill/Rent Agreement/Passport",
        ],
      ],
    },
    {
      title: "Financial Documents",
      desc: "Bank account statement from the last 6 months",
    },
    {
      title: "Professional Qualifications",
      desc: "Certificate of Practice and Degree Certificate",
    },
  ],
  interest: [
    {
      img: interest,
      title: "Interest rate",
      desc: "Securing a favorable deal is effortless with us. Our Professional Loan offers highly competitive interest rates, ensuring a cost-effective solution for your financial requirements. Trust us to provide the financing you need with ease and efficiency.",
    },
    {
      img: receipt,
      title: "Processing fees",
      desc: "A nominal processing fee of up to 2% of the sanctioned amount, plus applicable taxes, is charged for our Professional Loan. This fee covers the processing of your application, ensuring a smooth and efficient loan approval process.",
    },
    {
      img: prepay,
      title: "Pre-payment charges",
      desc: "With the our Professional Loan, no part-prepayment or foreclosure fees apply if paid from personal funds. However, a 4% charge is applicable if paid from other sources, providing flexibility to settle your debt partially or entirely before the loan term concludes.",
    },
    {
      img: penalty,
      title: "Late payment penalties",
      desc: "A 3% penal interest is applied monthly on defaulted EMI amounts. Additionally, for each bounced cheque, a fee of ₹500, plus applicable taxes, is charged. These measures ensure accountability and prompt payment adherence.",
    },
  ],
};
