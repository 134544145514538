import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";

function BajajPersonalLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Eligibility & Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Bajaj Personal Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    type: "BajajPersonal",
    features: [
      "High value and collateral free loan",
      "Instant loan approval",
      "Minimal documentation",
      "Swift disbursal",
      "Convenient repayment",
      "Nil hidden charges",
      "Flexi hybrid feature"
    ],
    eligibility: [{
      title: "Nationality",
      desc: "Indian"
    },{
      title: "Age",
      desc: "21 years to 80 years*"
    },{
      title: "Employed with",
      desc: "Public, private or MNC"
    },{
      title: "CIBIL score",
      desc: "685 or higher"
    },{
      title: "Monthly salary",
      desc: "Starting ₹25001, based on the city you live in"
    }],
    documents: [{
      title: "Proof of Identity",
      desc: "PAN Card, Aadhar card, Voter ID, Passport or any other government issued photo identity proof"
    },{
      title: "Proof of Address",
      desc: "Aadhar card, Voter ID, Passport, Ration card, Utility bills or any other government issued proof of address"
    },{
      title: "Income Proof",
      desc: "Salary slips for the last 3 months and bank account statements for the previous 3 months"
    }],
    interest: [],
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default BajajPersonalLoan;
