import { useEffect, useState } from "react";
import Footer from "../Components/footer";
import Menu from "../Components/menu";
import TopHeader from "../Components/topHeader";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { getCustomerIncompleteLoans } from "../api/customer";
import { getLocalStorage } from "../Common/cookie";
import { Link } from "react-router-dom";
import cibil from "../Assets/img/Banner/scoreGIF.gif";
import loan from "../Assets/img/Loan/instantIcon.png";

function Layout(props) {
  const [id, setId] = useState(null);
  const [date, setDate] = useState(null);
  const [link, setLink] = useState("");
  const [visible, setVisible] = useState(false);
  const toggleVisibility = () => {
    if (visible === false) {
      setVisible(true);
      // const timer = setTimeout(() => {
      //   setVisible(false);
      // }, 3000);
      // return () => clearTimeout(timer);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    const asyncFn = async () => {
      if (props.logindata) {
        const checkCookie = getLocalStorage("rv-user");
        const response = await getCustomerIncompleteLoans({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response?.status === 200) {
          if (response.data?.id) {
            const modalShown = sessionStorage.getItem("modalShown");
            setId(response.data.id);
            setDate(response.data.updated_at);
            await checkNext(response.data.id, response.data.status);
            if (!modalShown) {
              sessionStorage.setItem("modalShown", "true");
              setVisible(true);
              const timer = setTimeout(() => {
                setVisible(false);
              }, 3000);
              return () => clearTimeout(timer);
            }
          }
        }
      }
    };
    asyncFn();
  }, [props.logindata]);

  const checkNext = (id, status) => {
    if (status === "CounterOffer") {
      setLink(`/apply-now/Instant/offered?id=${id}`);
    } else if (status === "ResponseByClient") {
      setLink(`/apply-now/Instant/kyc?id=${id}`);
    } else if (status === "CustomerKYC") {
      setLink(`/apply-now/Instant/address?id=${id}`);
    } else if (status === "CustomerAddress") {
      setLink(`/apply-now/Instant/bank?id=${id}`);
    } else if (status === "CustomerBank") {
      setLink(`/apply-now/Instant/review?id=${id}`);
    } else if (status === "LoanReference") {
      setLink(`/apply-now/Instant/docs?id=${id}`);
    }
  };
  return (
    <>
      <header class="header-area">
        <TopHeader />
        <Menu
          logindata={props.logindata}
          setLogindata={props.setLogindata}
          authRequire={props?.authRequire ? props.authRequire : false}
        />
      </header>
      {/* {props.logindata && (
        <div className={`reminder ${visible ? "visible" : "hidden"}`}>
          <div className="reminder-toggle" onClick={toggleVisibility}>
            {visible ? (
              <i class="fas fa-chevron-right"></i>
            ) : (
              <i class="fas fa-chevron-left"></i>
            )}
          </div>
          <div className="reminder-content">
            <Link to="/">
              <div className="free-cibil d-flex">
                <div className="d-flex align-items-center">
                  <img
                    src={cibil}
                    alt="alt"
                    style={{ minWidth: "50px", height: "40px", width: "50px" }}
                  />
                </div>
                <div className="d-flex align-items-center ml-2">
                  <h6 className="mb-0">
                    <b>
                      Get Credit Score for{" "}
                      <span style={{ color: "red" }}>FREE!!!</span> 😍
                    </b>
                  </h6>
                </div>
              </div>
            </Link>
            {id && (
              <Link to={link}>
                <div>
                  <h6 className="mt-30 text-left">
                    <b>Recent Activity</b>
                  </h6>
                  <div className="loan-card d-flex">
                    <div className="d-flex align-items-center">
                      <img
                        src={loan}
                        alt="alt"
                        style={{
                          minWidth: "50px",
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center ml-2">
                      <div>
                        <h6 className="mb-0">
                          <b>Personal Loan</b>
                        </h6>
                        <div
                          className="mt-1"
                          style={{ fontSize: "13px", color: "#000" }}
                        >
                          Exciting Offers are waiting for you. Let's countinue
                          from where you left.
                          <br />
                          Last Checked on:{" "}
                          <b>{new Date(date).toDateString()}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )}
          </div>
        </div>
      )} */}
      {props.children}
      <Footer />
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={2500}
        pauseOnFocusLoss={false}
        hideProgressBar
        draggable
        closeOnClick
        transition={Slide}
      />
    </>
  );
}

export default Layout;
