import { useEffect } from "react";
import BreadCrumbs from "../Components/breadcrumbs";

function Disclaimer() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Disclaimer",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="container">
        <div className="section-60 text-justify">
          Please carefully review the terms and conditions outlined in the legal
          disclaimers ("Legal Disclaimers"). These Legal Disclaimers form a
          legally binding agreement between you and RV Capital, establishing the
          terms and conditions governing the use of
          https://rvcapitalfinance.com/ (the "Website") for accessing RV
          Capital’s profile, information, products, and services, and
          facilitating communication with various users availing its services
          ("Services"). By accessing the Website, any of its pages, and/or
          utilizing the information provided on or via the Website, you agree to
          abide by the Legal Disclaimers and the terms contained within them. In
          case of any conflict between the terms and conditions of specific
          products and services and the Legal Disclaimers, the conditions
          specific to such products and services shall take precedence. If you
          disagree with any of the Legal Disclaimers mentioned above, please
          refrain from accessing the information on any of RV Capital’s pages.
          The Website is offered to you under the condition of your acceptance
          without modification of the terms, conditions, and notices contained
          herein. Your usage of the Website signifies your acceptance of all
          such terms, conditions, and notices stated in the Legal Disclaimers.
          <br />
          <br />
          The Content, including information, materials, data, news items,
          products (such as text, content, photographs, graphics, video and
          audio content), financial data, news, research, recommendations,
          opinions, or other financial information ("Content") available on this
          Website is provided solely for general informational purposes and
          should not be relied upon as a basis for making business or commercial
          decisions. It is recommended that you exercise due caution and/or seek
          independent advice before engaging in any investment or financial
          commitments based on the Content provided on this Website. RV Capital
          maintains this Website to disseminate information about RV Capital,
          its group companies, subsidiaries, affiliates, suppliers, agents, or
          licensors, its products and services, and for general communication
          with you, which should not be construed in any manner as advice to
          you.
          <br />
          <br />
          The Content featured on this Website, including text, graphics, links,
          or other terms, is offered on an "as is," "as available" basis and is
          protected by copyright. You are not permitted to distribute text or
          graphics to others without the express prior written consent of RV
          Capital. Furthermore, you are prohibited from copying, downloading,
          publishing, distributing, or reproducing any of the Content found on
          this Website in any form without the express prior written permission
          of RV Capital.
          <br />
          <br />
          The Content provided on this Website should not be interpreted as an
          offer, solicitation, invitation, advice, or recommendation to avail of
          the schemes offered by RV Capital, its group companies, subsidiary
          companies, or any of its affiliates. Should you rely on the Website,
          its products and/or services, or any material accessible through this
          Website, you do so at your own risk. You acknowledge and agree that
          there may be delays, omissions, interruptions, inaccuracies, and/or
          other issues with the information, products, and services published on
          this Website.
          <br />
          <br />
          The use of the products or services described on this Website may not
          be permitted in certain countries. If you have any doubts, it's
          advisable to check with your local regulator or authority before
          seeking further information about such products/services. RV Capital
          reserves the right to offer products and services at its discretion,
          subject to the individual contractual terms and conditions, and may
          withdraw or amend them at any time without prior notice. Please note
          that the full range of products or services may not be available in
          all locations.
          <br />
          <br />
          While RV Capital strives to ensure the accuracy and reliability of the
          information provided on the Website, there may be inaccuracies or
          typographical errors. RV Capital, its group companies, subsidiaries,
          affiliates, suppliers, agents, or licensors do not warrant the
          accuracy, completeness, correctness, non-infringement,
          merchantability, or fitness for a particular purpose of the
          information available through the service. Additionally, they do not
          guarantee that the information on the Website will be error-free or
          continuously available, or that the service will be free of viruses or
          other harmful components.
          <br />
          <br />
          Opinions, statements, or advice provided by various professionals,
          experts, or analysts on this Website are not endorsed or represented
          by RV Capital. Users are advised to independently verify and evaluate
          the accuracy, completeness, reliability, and usefulness of any
          opinions, services, or other information provided. The information on
          this Website should not be construed as legal, accounting, tax,
          financial, investment, or other professional advice, and users should
          seek appropriate professional advice before acting on any information
          provided.
          <br />
          <br />
          RV Capital reserves the right to update, edit, alter, or remove any
          information on this Website without notice and shall not be held
          responsible for any actions resulting from such changes. Users are
          responsible for staying informed about any updates or changes to the
          information on the Website. While RV Capital endeavors to ensure the
          reliability of the information provided, it does not warrant the
          completeness or accuracy of such information.
          <br />
          <br />
          RV Capital, its group companies, subsidiaries, affiliates, suppliers,
          agents, or licensors shall not be liable for any damages, including
          consequential, special, incidental, indirect, punitive, exemplary, or
          other damages of any kind, arising from the use of this Website or the
          information contained herein. Users should seek professional advice
          before acting on any information provided on this Website, and
          decisions regarding financial products or opportunities shall be the
          sole responsibility of the user.
          <br />
          <br />
          While using the Website, you may download any downloadable material
          strictly for non-commercial and personal use, provided you do not
          violate or infringe upon any rights, including copyright and other
          proprietary notices contained on the materials. Any material
          downloaded or obtained through the Website is at your own discretion
          and risk. RV Capital shall not be liable for any damage to your
          computer system or loss of data resulting from the download of such
          material, nor for any loss or damage of any kind, including loss of
          property.
          <br />
          <br />
          You are prohibited from distributing, modifying, transmitting,
          reusing, reporting, or using the contents of the Website for public or
          commercial purposes without prior written permission from RV Capital.
          <br />
          <br />
          Your access and use of the Website are subject to the Terms and
          Conditions and Privacy Policy available on the Website, as well as all
          applicable laws, rules, regulations, notifications, and circulars. By
          accessing and browsing the Website, you accept the Terms and
          Conditions and agree to abide by RV Capital's Privacy Policy.
          <br />
          <br />
          The Content, trademarks, service marks, trade names, icons, and logos
          contained on the Website (collectively referred to as "Marks"),
          including terms like "RV Capital" and the RV Capital logos, are owned
          by RV Capital. All content and images used on the Website are owned or
          licensed by RV Capital, and unauthorized use is prohibited. RV Capital
          and its group companies, subsidiaries, or affiliates reserve all
          rights to the intellectual property, including trademarks and
          copyrights, vested in the names of products and services. Nothing
          herein grants any license, assignment, or transfer of RV Capital's
          rights in its intellectual property.
          <br />
          <br />
          RV Capital retains all rights, including copyrights, trademarks,
          patents, and any other intellectual property rights, in relation to
          all information provided on or by the Website. RV Capital reserves all
          rights not expressly granted in and to the Website and the Content.
          <br />
          <br />
          RV Capital, its group companies, subsidiaries, or affiliates reserve
          the right to cancel any listing at any time, with or without cause,
          and to prohibit or restrict access to the services at any time, with
          or without cause. If you rely on this service or any material
          available through it, you do so at your own risk.
          <br />
          <br />
          Any material, information, and ideas transmitted to this Website shall
          become the property of RV Capital, its group companies, subsidiaries,
          or affiliates. All transmissions shall be treated as non-confidential
          and non-proprietary, and RV Capital and its affiliates shall be under
          no obligation with respect to such information and shall be free to
          reproduce, use, disclose, and distribute the transmissions to others
          without limitation. Additionally, RV Capital and its affiliates shall
          be free to use any ideas, concepts, know-how, or techniques contained
          in such information for any purpose whatsoever, including developing
          or marketing services incorporating such information.
          <br />
          <br />
          You acknowledge and are hereby notified that you are prohibited from
          posting or transmitting any unlawful, threatening, libelous,
          defamatory, inflammatory, pornographic, or profane material to or from
          this Website, or any material that could result in civil or criminal
          liability under law. You are solely responsible for the content of any
          comments you make, and RV Capital reserves the right to remove such
          material at its sole discretion and without prior notice to you.
          <br />
          <br />
          RV Capital, its group companies, subsidiaries, or affiliates may
          update these terms of use by revising this posting. You agree that if
          any portion of these terms and conditions is found to be
          unenforceable, the remainder shall remain in full force and effect. By
          using this Website, you agree to be bound by any such revisions and
          should periodically visit this page to review the current terms of
          use.
          <br />
          <br />
          RV Capital may collect information from Website visitors, including
          the visitor's operating system, browser, domain name, and specific web
          pages accessed or visited.
          <br />
          <br />
          When visitors access the Website, RV Capital may store information on
          their computers in the form of a "cookie" or similar file. Most web
          browsers allow users to control such files by erasing, blocking, or
          receiving notifications when stored. Visitors are encouraged to refer
          to their browser's instructions for more information.
          <br />
          <br />
          This Website may contain links to subsidiary and affiliate company
          websites of RV Capital, as well as external websites controlled or
          offered by third parties (Non-Affiliates of RV Capital). RV Capital
          does not endorse, verify, or monitor the content, products, or
          services offered on linked websites. RV Capital is not responsible for
          the owners or operators of external websites or any products or
          services they provide, and disclaims any liability in relation to
          them.
          <br />
          <br />
          RV Capital expressly disclaims any implied warranties under the laws
          of any jurisdiction. Disputes and legal matters shall be subject to
          the jurisdiction of the Pune Courts in India and referred to
          arbitration as provided in the Terms and Conditions.
          <br />
          <br />
          RV Capital reserves the right to disclose information as necessary to
          comply with applicable laws, regulations, legal processes, or
          governmental requests, or to edit, refuse to post, or remove any
          information or product or service, in whole or in part, at its sole
          discretion and without prior notice.
          <br />
          <br />
          No compensation will be provided for the use of your submissions. RV
          Capital is not obligated to post or use any submission provided and
          may remove any submission at any time at its sole discretion.
          <br />
          <br />
          By submitting your submission, you warrant and represent that you own
          or control all rights to it, including those necessary for providing,
          posting, uploading, inputting, or submitting the submissions.
          <br />
          <br />
          Any Software made available for download from the Website, excluding
          software provided by end-users through a Communication Service,
          ("Software"), is the end work of the software provider. Your usage of
          the Software is subject to the terms of the end-user license agreement
          ("License Agreement"), if any, that accompanies or is included with
          the Software. You are not permitted to install or use any Software
          that is accompanied by or includes a License Agreement unless you
          first agree to the terms of the License Agreement.
          <br />
          <br />
          RV Capital reserves the right, at its sole discretion, to terminate
          your access to any or all of the Website and its related services, or
          any portion thereof, at any time, without prior notice.
          <br />
          <br />
          RV Capital, its group companies, suppliers, service partners, agents,
          employees, licensors, consultants, officers, and directors bear no
          liability and shall not be held liable for any loss or damage arising
          directly or indirectly (including special, incidental, or
          consequential damages, punitive damages, exemplary loss, damage, or
          expenses, lost revenues or profits, loss of business, or loss of data)
          resulting from your use of this Website or any linked site, or the
          inability to use by any party, regardless of the cause. This includes
          any loss, damage, or expense arising from, but not limited to, any
          defect, error, omission, interruption, imperfection, fault, mistake,
          or inaccuracy with this Website, its contents, or associated services,
          or due to the unavailability of the Website or any part thereof, or
          any contents or associated services, even if the entities are advised
          of the possibility of such damages, losses, or expenses.
        </div>
      </div>
    </>
  );
}
export default Disclaimer;
