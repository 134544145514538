import instantIcon from "../Assets/img/Loan/instantIcon.png";
import cartIcon from "../Assets/img/Loan/carIcon.png";
import hometIcon from "../Assets/img/Loan/homeIcon.png";
import eduIcon from "../Assets/img/Loan/eduIcon.png";
import machineIcon from "../Assets/img/Loan/machineIcon.png";
import businessIcon from "../Assets/img/Loan/businessIcon.png";
import morgageIcon from "../Assets/img/Loan/morgage.png";
import proffessionalIcon from "../Assets/img/Loan/professional.png";
import Options from "./options";

function LoanOptions(props) {
  const loanType = [
    // {
    //   name: "Instant Loan",
    //   icon: "fas fa-hand-holding-usd",
    //   path: "instant-loan",
    //   img: instantIcon,
    //   desc: "Get instant loans with fast approval and minimal documentation.",
    //   knowmore: "/instant-loan",
    //   applyNow: "/apply-now/Instant",
    // },
    {
      name: "Business Loan",
      icon: "fas fa-business-time",
      path: "business-loan",
      img: businessIcon,
      desc: "Secure business financing with competitive rates and flexible repayment options.",
      knowmore: "/business-loan",
      applyNow: "/apply-now/Business",
    },
    {
      name: "Education Loan",
      icon: "fas fa-user-graduate",
      path: "education-loan",
      img: eduIcon,
      desc: "Obtain education funding with low interest rates and convenient repayment plans.",
      knowmore: "/education-loan",
      applyNow: "/apply-now/Education",
    },
    {
      name: "Home Loan",
      icon: "fas fa-home",
      path: "home-loan",
      img: hometIcon,
      desc: "Access home financing with competitive rates and personalized terms to realize your dream home.",
      knowmore: "/home-loan",
      applyNow: "/apply-now/Home",
    },
    {
      name: "Car Loan",
      icon: "fas fa-car",
      path: "car-loan",
      img: cartIcon,
      desc: "Drive away with your dream car through hassle-free financing options with low interest rates.",
      knowmore: "/car-loan",
      applyNow: "/apply-now/Car",
    },
    {
      name: "Machinary Loan",
      icon: "fas fa-tools",
      path: "machinary-loan",
      img: machineIcon,
      desc: "Acquire machinery financing with tailored solutions to fuel business growth.",
      knowmore: "/machinary-loan",
      applyNow: "/apply-now/Machinary",
    },
  ];

  const extra = [
    {
      name: "Loan Against Property",
      icon: "fas fa-tools",
      path: "against-property-loan",
      img: morgageIcon,
      desc: "Unlock liquidity by leveraging your property with a convenient loan against property.",
      knowmore: "/against-property-loan",
      applyNow: "/apply-now/AgainstProperty",
    },
    {
      name: "Proffessional Loan",
      icon: "fas fa-tools",
      path: "professional-loan",
      img: proffessionalIcon,
      desc: "Secure professional financing tailored to your career needs with competitive terms and swift approval.",
      knowmore: "/professional-loan",
      applyNow: "/apply-now/Professional",
    },
    {
      name: "Student Loan",
      icon: "fas fa-tools",
      path: "student-loan",
      img: eduIcon,
      desc: "Finance your education journey with student loans offering flexible terms and low interest rates.",
      knowmore: "/student-loan",
      applyNow: "/apply-now/Student",
    },
  ];

  const filteredData = [...loanType, ...extra].filter(
    (item) => !item.name.includes(props.type)
  );
  return (
    <div
      className={`section-60 ${!props.applyLoan && "bg-f8f8f8"} ${
        props.type && "d-none d-lg-block pt-0"
      }`}
    >
      <div className="container">
        <div class="section-title">
          {props.applyLoan
            ? "Select the type of loan"
            : "Explore Our Range of Loan Options"}
        </div>
        {!props.type && (
          <div className="sub-title text-center pb-3">
            Embark on your financial journey with our comprehensive selection of
            loan offerings, crafted to address various needs and circumstances.
            From personal to business loans, find the perfect solution tailored
            to you.
          </div>
        )}
        {props.type ? (
          <Options data={filteredData} />
        ) : (
          <Options
            data={props.applyLoan ? [...loanType, ...extra] : loanType}
          />
        )}
      </div>
    </div>
  );
}
export default LoanOptions;
