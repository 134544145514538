import About from "../Components/about";
import BreadCrumbs from "../Components/breadcrumbs";
import CCOptions from "../Components/ccOptions";
import InsuranceOptions from "../Components/insuranceOptions";
import LoanFromBankComponent from "../Components/loanFromBank";
import LoanOptions from "../Components/loanOptions";
import Mission from "../Components/mission";
import Partner from "../Components/partner";
import Testimonials from "../Components/testimonials";
import WhyChooseUs from "../Components/whyChooseUs";
import { useEffect } from "react";

function AboutPage() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About Us",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="bg-f8f8f8">
        <About nolink />
        <Mission />
      </div>
      <WhyChooseUs />
      <div className="section-60 pb-0">
        <div className="container">
          <div class="section-title">Explore Instant Personal Loan Options</div>
          <div className="sub-title text-center" style={{marginBottom: "-30px"}}>
            An instant loan is a type of personal loan offered by various banks
            that provides quick access to funds without extensive paperwork or
            long approval processes. Typically, instant loans are designed for
            urgent financial needs and can be availed within minutes or hours,
            depending on the bank’s processing time.
          </div>
          <LoanFromBankComponent />
        </div>
      </div>
      <LoanOptions />
      <CCOptions />
      <InsuranceOptions />
      <Testimonials />
      <Partner />
    </>
  );
}
export default AboutPage;
