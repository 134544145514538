import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";

function BirlaPersonalLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Features & Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Aditya Birla Personal Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    type: "AdityaBirlaPersonal",
    features: [
      "Flexible repayment options.",
      "Flexible tenure options up to 7 years.",
      "Maximum loan amount of Rs 50 lakh.",
      "Repayment options: ECS/NACH.",
      "No security or collateral required to avail of the loan.",
      "Applicant can club income of co-applicant to enhance eligibility.",
      "Interest rates remain unchanged throughout the loan tenure.",
    ],
    eligibility: [],
    documents: [
      "Last three months'salary slips from the date of application.",
      "Last three months bank statement.",
      "KYC documents as requested.",
    ],
    interest: [],
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default BirlaPersonalLoan;
