import axios from "axios";

//get customer
export const getLocation = (obj) => {
  return axios
    .get("https://api.postalpincode.in/pincode/" + obj.pincode)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
