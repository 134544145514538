import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";

function IndusindPersonalLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Eligibility & Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "IndusInd Personal Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    type: "IndusIndPersonal",
    features: [],
    eligibility: [
      {
        title: "Salaried Employees",
        desc: [
          "Minimum age to apply for personal loan should be 21 years.",
          "Maximum age at personal loan maturity should be 60 years or retirement age whichever is earlier.",
          "Minimum net monthly income should be Rs.25000 for personal loan online application.",
          "Should have completed minimum 2 years in employment and minimum 1 year in the current organisation.",
          "Should have completed minimum 1 year of stay at the current residence, if rented.",
        ],
      },
      {
        title: "Self Employed Professionals",
        desc: [
          "Minimum age to apply for personal loan should be 25 years",
          "Maximum age at loan maturity should be 65 years",
          "Minimum annual net income post tax should be Rs.4.8 lakh",
          "Should have 4 years of post qualification experience.",
        ],
      },
      {
        title: "Self Employed Individuals",
        desc: [
          "Minimum age to apply for personal loan should be 25 years",
          "Maximum age at loan maturity should be 65 years",
          "Minimum annual net income post tax should be Rs.4.8 lakh",
          "Should have 5 years of post qualification experience",
        ],
      },
    ],
    documents: [
      {
        title: "Application",
        desc: "Application form with photograph",
      },
      {
        title: "Proof of Identity",
        desc: [
          [
            "Photocopy of any one of the following:",
            "Driving license",
            "Passport",
            "Voters ID",
            "Aadhaar",
            "PAN",
          ],
        ],
      },
      {
        title: "KYC",
        desc: "Valid identity/Residence/Signature proof",
      },
      {
        title: "Income proof",
        desc: ["Form 16/ITR for last 3 years", "Latest 3 months salary slip"],
      },
      {
        title: "Banking",
        desc: [
          [
            "Salaried Employees:",
            "Latest 3 months’ Salary Credit Bank Statement",
          ],
          [
            "Self Employed",
            "Latest 6 months Practice Current Account and latest 3 months’ Primary Savings Bank Statement",
          ],
        ],
      },
    ],
    interest: [],
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default IndusindPersonalLoan;
