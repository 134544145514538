import BreadCrumbs from "../Components/breadcrumbs";
import ApplyNowForm from "../Components/applyNowForm";
import { useEffect } from "react";
import { RWebShare } from "react-web-share";

function ApplyNowIiflBusiness(props) {
  const { socket, logindata, setLogindata } = props;
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Apply Now",
    },
    {
      name: "IIFL Business Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div class="container">
        <div class="section-60">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12">
              <div className="py-3">
              <div className="d-flex justify-content-between">
                  <div class="section-title text-left">
                    Get IIFL Business Loan
                  </div>
                  <div>
                    <RWebShare
                      data={{
                        //   text: "Like humans, flamingos make friends for life",
                        url: window.location.href,
                        title: "RV Capital Finance",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <button
                        style={{
                          border: "2px solid #002d5b",
                          borderRadius: "8px",
                        }}
                      >
                        🔗
                      </button>
                    </RWebShare>
                  </div>
                </div>
                <ApplyNowForm
                  type="IIFLBusiness"
                  applyFor="InstantBankLoan"
                  logindata={logindata}
                  setLogindata={setLogindata}
                  link="https://idolapp.iifl.com/diy/0954ef3c-5c20-11ee-ac13-6045bd72d65b/b13027cc-56ce-4e94-b463-cc94db0daf1e"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ApplyNowIiflBusiness;
