import { Link } from "react-router-dom";

function InstantLoanOptions(props) {
  return (
    <div className="container section-60">
      <div class="row gx-2 justify-content-center">
        {props.data.map((l) => {
          return (
            <div class="col-lg-4 col-md-6 col-12 mt-30">
              <div className="card single-card p-3 h-100">
                <div className="d-flex justify-content-between flex-column h-100 loan-type">
                  <div>
                    <div className="d-flex">
                      <div className="w-100">
                        <div>
                          <b style={{ fontSize: "16px" }}>
                            {l.name}
                            <br />
                            {props.type} Loan
                          </b>
                        </div>
                        <div>
                          <Link to={l.applyNow}>
                            <button className="btn-default mt-3">
                              <b>Apply Now</b>
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <img
                          src={l.img}
                          alt="icon"
                          width="210"
                          style={{ borderRadius: "4px" }}
                        />
                      </div>
                    </div>
                    {/* <p className="mt-2 mr-2">{l.desc}</p> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InstantLoanOptions;
