import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/cc2.png";
import img2 from "../Assets/img/Loan/cc1.png";
import { HdfcCC } from "../Components/ccDetailsData";
import CCDetails from "../Components/ccDetails";

function HDFCCC() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Overview", "Types", "Documents"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "HDFC Bank Credit Card",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...HdfcCC,
    img1: img1,
    img2: img2,
    desc: "The HDFC Bank Credit Card range offers a diverse selection catering to varying needs and lifestyles. From entry-level cards with basic rewards to premium and super-premium cards offering exclusive privileges like airport lounge access, concierge services, luxury hotel stays, and travel benefits, HDFC has options for every type of spender. These cards often come with features like cashback on specific categories, milestone rewards, and fuel surcharge waivers, making them versatile financial tools. With robust security measures and excellent customer service, HDFC credit cards are trusted by millions for their convenience and value-added benefits in daily transactions, travel, and entertainment expenses.",
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <CCDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default HDFCCC;
