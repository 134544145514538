import { useEffect } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import CCOptions from "../Components/ccOptions";

function ApplyNowCC() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Apply Now",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <CCOptions />
    </>
  );
}
export default ApplyNowCC;
