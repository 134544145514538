import { jwtDecode } from "jwt-decode";

export function setLocalStorage(name, value) {
  localStorage.setItem(name, JSON.stringify(value));
}

export function getLocalStorage(name) {
  const data = localStorage.getItem(name);
  if (data) {
    const jsonParsed = JSON.parse(data);

    const decodedToken = jwtDecode(jsonParsed.accessToken);

    // Check if the token is expired
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
    if (decodedToken.exp < currentTime) {
      removeLocalStorage(name);
      return "";
    }
    return jsonParsed;
  } else {
    return "";
  }
}

export function removeLocalStorage(name) {
  localStorage.removeItem(name);
}
