import chooseImg from "../Assets/img/why-choose-img.jpg";
function WhyChooseUs() {
  const choose = [
    {
      icon: "fas fa-hand-holding-heart",
      title: "Service with love",
      desc: "Delivering exceptional service with genuine care and compassion.",
    },
    {
      icon: "fas fa-thumbs-up",
      title: "Quick approval",
      desc: "Efficient and rapid approval process for seamless transactions.",
    },
    {
      icon: "fas fa-users",
      title: "Client focused",
      desc: "Tailoring services to meet the unique needs and preferences of each client.",
    },
    {
      icon: "fas fa-chart-line",
      title: "Growing your business",
      desc: "Strategically expanding your business to achieve sustained growth and success.",
    },
  ];
  return (
    <div class="why-choose-us-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-5 col-12">
            <img
              className="h-100 w-100"
              style={{ objectFit: "cover" }}
              src={chooseImg}
              alt="image"
            />
          </div>

          <div class="col-lg-7 col-12 why-choose-us-content ">
            <div className=" p-60">
              <div class="section-title text-white text-left">
                Why Choose Us
              </div>
              <div className="content">
                <ul class="features-list">
                  {choose.map((c) => {
                    return (
                      <li>
                        <div class="icon">
                          <i class={c.icon}></i>
                        </div>
                        <span>{c.title}</span>
                        <p className="text-white">{c.desc}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WhyChooseUs;
