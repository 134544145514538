import { useEffect, useState } from "react";

import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";
import { loanData } from "../Components/loanDetailsData";
import img1 from "../Assets/img/Loan/personal1.png";
import img2 from "../Assets/img/Loan/personal2.png";

function InstantLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = [
    "Overview",
    "Features",
    "Eligibility & Documents",
    "Interest rates & Charges",
    "EMI Calculator",
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Instant Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    ...loanData,
    desc: "An instant loan, also known as a payday loan or cash advance, is a short-term borrowing option typically offered by non-bank financial institutions or online lenders. These loans are characterized by their quick approval process, often within minutes or hours, and minimal documentation requirements. Borrowers can access relatively small amounts of money, usually up to a few thousand dollars, to cover immediate financial needs. However, they often come with high interest rates and fees, making them an expensive form of borrowing. Instant loans are intended for emergency situations and are typically repaid in full, along with interest, by the borrower's next payday.",
    img1: img1,
    img2: img2,
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default InstantLoan;
