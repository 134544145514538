import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import { getLocalStorage } from "../Common/cookie";
import { useNavigate } from "react-router-dom";

function LoanDetails() {
    const navigate = useNavigate()
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Loan Details",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        // get loan data with id
      } else {
        navigate("/");
      }
    };
    asyncFn();
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="container section-60">
        Loan details with emi pay option
      </div>
    </>
  );
}
export default LoanDetails;
