function Mission() {
  const data = [
    {
      title: "Our History",
      desc: "RV Capital Finance was founded with a vision to provide specialized financial solutions for RV enthusiasts. With many years of industry experience, we have established ourselves as a trusted partner, offering flexible loan options and exceptional customer service.",
    },
    {
      title: "Our Mission",
      desc: "At RV Capital Finance, our mission is to empower individuals to pursue their RV lifestyle dreams by providing accessible and innovative financial solutions. We strive to enhance the RV experience by delivering exceptional service, tailored loan options, and fostering a community of passionate adventurers.",
    },
    {
      title: "Who we are",
      desc: "RV Capital Finance is a leading provider of tailored financing solutions for RV enthusiasts, offering competitive rates and personalized service. With a commitment to customer satisfaction and over a decade of industry expertise, we strive to make the dream of RV ownership a reality for our clients.",
    },
  ];
  return (
    <div className="section-60 pt-0 container">
      <div class="about-inner-area mt-0">
        <div class="row justify-content-center m-0">
          {data.map((d) => {
            return (
              <div class="col-lg-4 col-md-6 col-12">
                <div class="about-text-box">
                  <h3>{d.title}</h3>
                  <p className="text-justify">{d.desc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default Mission;
