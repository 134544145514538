import { useEffect } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import LoanOptions from "../Components/loanOptions";

function ApplyNowTypes() {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Apply Now",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanOptions applyLoan />
    </>
  );
}
export default ApplyNowTypes;
