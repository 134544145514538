import { Tab, Nav } from "react-bootstrap";
import EMICalc from "./emiCalc";
import { Link } from "react-router-dom";

export function List(props) {
  return (
    <div className="container why-choose-us-content bg-transparent">
      <div className="content w-100 p-0" style={{ maxWidth: "unset" }}>
        <ul class="features-list ml-0">
          {props.listdata.map((c) => {
            return (
              <li className="pl-0 pt-0 mb-30">
                <div className="d-flex">
                  {c.img && (
                    <div style={{ minWidth: "65px" }}>
                      <div
                        class="icon d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#fff",
                          border: "2px solid #002d5b",
                        }}
                      >
                        {" "}
                        {/* bg-blue-color */}
                        <img src={c.img} height={33} alt="" />
                      </div>
                    </div>
                  )}
                  <div>
                    <span style={{ color: "#002e5b", paddingRight: "15px" }}>
                      {c.title}
                    </span>
                    <p style={{ textAlign: "justify", paddingRight: "15px" }}>
                      {c.desc}
                    </p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export function TableList(props) {
  return (
    <div className="container why-choose-us-content bg-transparent">
      <div className="content w-100 p-0" style={{ maxWidth: "unset" }}>
        <table className="eligibilityTable inside-border">
          {props.listdata.map((c) => {
            if (c.title && c.desc) {
              return (
                <tr>
                  <td>{c.title}</td>
                  <td>
                    {typeof c.desc === "object" ? (
                      <ul className="mb-0 pl-3">
                        {c.desc.map((d) => {
                          {
                            return typeof d === "object" ? (
                              <ul>
                                {d.map((da, index) => {
                                  return index === 0 ? (
                                    <p
                                      style={{
                                        marginLeft: "-50px",
                                        marginBottom: "0",
                                      }}
                                    >
                                      {da}
                                    </p>
                                  ) : (
                                    <li
                                      className="m-0 p-0"
                                      style={{ color: "#666" }}
                                    >
                                      {da}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <p style={{ marginLeft: "-15px" }}>{d}</p>
                            );
                          }
                        })}
                      </ul>
                    ) : (
                      <p>{c.desc}</p>
                    )}
                  </td>
                </tr>
              );
            } else {
              return (
                <tr>
                  <td>{c}</td>
                </tr>
              );
            }
          })}
        </table>
      </div>
    </div>
  );
}

function LoanDetails(props) {
  const { tabs, data, activeTab, handleTabChange } = props;
  function isArrayOfObjects(arr) {
    return (
      Array.isArray(arr) &&
      arr.every((item) => typeof item === "object" && item !== null)
    );
  }

  return (
    <div className="section-60 container-fluid">
      <Nav
        variant="tabs"
        defaultActiveKey={activeTab}
        className="loan-tab-header px-3 mb-30"
      >
        {tabs.map((t, index) => {
          return (
            <Nav.Item>
              <Nav.Link
                as={Link}
                to={`#tab${index + 1}`}
                eventKey={`tab${index + 1}`}
                onClick={() => handleTabChange(`tab${index + 1}`)}
              >
                {t}
              </Nav.Link>
            </Nav.Item>
          );
        })}
        <Nav.Item>
          <Nav.Link as={Link} to={`/apply-now/${data.type}`}>
            Apply Now
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane
          eventKey="tab1"
          className={activeTab === "tab1" ? "active" : ""}
        >
          {data.img1 && data.img2 && data.desc && (
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                  <div class="about-image">
                    <img src={data.img1} alt="image" />
                    <img src={data.img2} alt="image" />
                  </div>
                </div>

                <div class="col-lg-6 col-md-12">
                  <div className="py-3">
                    <div className="sub-title text-justify">{data.desc}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {data.features && data.features.length > 0 && (
            <>
              <div class="section-title mt-45">Features</div>
              {isArrayOfObjects(data.features) ? (
                <List listdata={data.features} />
              ) : (
                <TableList listdata={data.features} />
              )}
            </>
          )}
          {data.eligibility && data.eligibility.length > 0 && (
            <>
              <div class="section-title">Eligibility</div>
              <TableList listdata={data.eligibility} />
            </>
          )}
          {data.documents && data.documents.length > 0 && (
            <>
              <div class="section-title mt-30">Documents</div>
              <TableList listdata={data.documents} />
            </>
          )}
          {data.interest && data.interest.length > 0 && (
            <>
              <div class="section-title mt-30">Interest rates & Charges</div>
              {isArrayOfObjects(data.interest) ? (
                <List listdata={data.interest} />
              ) : (
                <TableList listdata={data.interest} />
              )}
            </>
          )}
        </Tab.Pane>
        <Tab.Pane
          eventKey="tab2"
          className={activeTab === "tab2" ? "active" : ""}
        >
          <List listdata={data.features} />
        </Tab.Pane>
        <Tab.Pane
          eventKey="tab3"
          className={activeTab === "tab3" ? "active" : ""}
        >
          <div class="section-title">Eligibility</div>
          <TableList listdata={data.eligibility} />
          <div class="section-title mt-30">Documents</div>
          <TableList listdata={data.documents} />
        </Tab.Pane>
        <Tab.Pane
          eventKey="tab4"
          className={activeTab === "tab4" ? "active" : ""}
        >
          <List listdata={data.interest} />
        </Tab.Pane>
        <Tab.Pane
          eventKey="tab5"
          className={activeTab === "tab5" ? "active" : ""}
        >
          <div className="container">
            <EMICalc noExtra />
          </div>
        </Tab.Pane>
      </Tab.Content>
    </div>
  );
}
export default LoanDetails;
