import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import LoanDetails from "../Components/loanDetails";

function BirlaUdyogBusinessLoan() {
  const [activeTab, setActiveTab] = useState(
    window.location.hash ? window.location.hash.replace("#", "") : "tab1"
  );
  const tabs = ["Eligibility"];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Aditya Birla Udyog Business Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = {
    type: "BirlaUdyogBusiness",
    features: [],
    eligibility: [
      {
        title: "Age",
        desc: "21 to 65 years",
      },
      {
        title: "Business Vintage",
        desc: "1 year and above",
      },
      {
        title: "Business Turnover",
        desc: "> Rs. 5 lakhs for last 6 months",
      },
      {
        title: "Credit Score",
        desc: "650+",
      },
    ],
    documents: [],
    interest: [],
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <LoanDetails
        tabs={tabs}
        data={data}
        activeTab={activeTab}
        handleTabChange={(tab) => {
          handleTabChange(tab);
        }}
      />
    </>
  );
}
export default BirlaUdyogBusinessLoan;
